import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  Table,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { encode } from "base64-arraybuffer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [mailTo, setMailTo] = useState("");

  const [embarques, setEmbarques] = useState([]);
  const [selectedEmbarque, setSelectedEmbarque] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_general, setTotalGeneral] = useState(0);
  const [tipoCambio, setTipoCambio] = useState(19);
  const [articulosEmb, setArticulosEmb] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   idArticulosEmabrque: "",
    //   articulos: "",
    //   articulosName: "",
    //   cantidad: 0,
    //   piezasPorCaja: 0,
    //   cajas: 0,
    //   precio: 0,
    //   precioMX: 0,
    //   tipoCambio: 0,
    //   total:0
    // },
  ]);
  const [expandedTables, setExpandedTables] = useState({});
  

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEmbarques = res.data;
        setEmbarques(allEmbarques);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalArticulos =
        inputFields.length + inputFields.length + inputFields.length;
      try {
        await axios
          .post(
            URL_COMPRAS,
            {
              fecha,
              embarques: selectedEmbarque,
              observaciones,
              total_cantidad,
              user: user.id,
              areas: selectedArea,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .patch(
                    `${URL_INVENTARIOS}Compra`,
                    {
                      articulos: a.articulos,
                      cantidad: parseFloat(a.cantidad),
                      areas: selectedArea,
                      tamanoCaja: "0",
                      pesoCaja: 1,
                      embarques: selectedEmbarque,
                      precioMX: a.precio * tipoCambio,
                      colores: a.idColor,
                      tallas: a.idTalla,
                      articulosProporcion: a.articulosProporcion,
                      ubicacion: a.ubicacion ? a.ubicacion : "Almacen",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `EMBARQUES ${selectedEmbarque}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              } else {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `EMBARQUES ${selectedEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            // Termina primer map
            inputFields.map((w) => {
              axios
                .patch(
                  `${URL_ARTICULOS_EMBARQUES}EditEmbarcado/${w.idArticulosEmabrque}`,
                  {
                    cantidad: w.cantidad,
                    pesoCaja: 1,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `EMBARQUES ${selectedEmbarque}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });
            // Termina segundo map
            inputFields.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    fecha,
                    cantidad: parseFloat(w.cantidad),
                    embarques: selectedEmbarque,
                    compras: data.data._id,
                    articulosEmbarques: w.idArticulosEmabrque,
                    articulos: w.articulos,
                    areas: selectedArea,
                    precio: w.precio,
                    tipoCambio: tipoCambio,
                    precioMX: w.precio * tipoCambio,
                    colores: w.idColor,
                    tallas: w.idTalla,
                    articulosProporcion: w.articulosProporcion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `EMBARQUES ${selectedEmbarque}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                      

                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };


  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / i.piezasPorCaja;
        let totalParcial = parseFloat(event.target.value) * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputUbicacion = (articulos, event) => {
    console.log(articulos);
    console.log(event.target.value);

    const newInputFields = inputFields.map((i) => {
      if (articulos === i.articulos) {
        i.ubicacion = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };


  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  function jalaEmbarque(e) {
    setSelectedEmbarque(e);

    axios
      .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosEmb = res.data;
        const arrayArt = allArticulosEmb.map((a) => {
          return {
            id: uuidv4(),
            idArticulosEmabrque: a._id,
            articulos: a.articulos[0]._id,
            articulosName: a.articulos[0].codigo,
            articulosNombre: a.articulos[0].codigo + " " + a.articulos[0].nombre,
            cantidad: a.cantidad,
            tallas: a.tallas[0]._id,
            tallaNombre: a.tallas[0].name,
            piezasPorCaja: a.articulos[0].piezasPorCaja,
            colores: a.colores,
            cajas: parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja),
            precio: a.articulosOC[0].precio,
            total: a.cantidad * a.articulosOC[0].precio,
            idColor: a.colores[0]._id,
            colorName: a.colores[0].name,
            idTalla: a.tallas[0]._id,
            tallasName: a.tallas[0].name,
            articulosProporcion: a.articulosProporcion[0]._id,
          };
        });
        setInputFields(arrayArt);

        let cant = arrayArt.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);

        let importes = arrayArt.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Entrada</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Embarques</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEmbarque}
                  required
                  onChange={(e) => jalaEmbarque(e.target.value)}
                >
                  <option value="0">Selecciona un Embarque</option>
                  {embarques
                    .sort((a, b) => (a.idEmbarques < b.idEmbarques ? 1 : -1))
                    .map((a) => {
                      if (a.is_active == "Si" && a.recibido == "No") {
                        return (
                          <option value={a._id}>
                            {a.idEmbarques}
                            {" / "} {a.contenedor}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un almacen</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Tipo Cambio
                </Label>
                <Input
                  type="number"
                  placeholder="TipoCambio"
                  value={tipoCambio}
                  required
                  onChange={(e) => {
                    setTipoCambio(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

             {Object.entries(
                          inputFields
                            .filter((field) => field.articulosNombre && field.articulos)
                            .reduce((acc, field) => {
                              const key = `${field.articulosNombre}`;
                              if (!acc[key]) {
                                acc[key] = {
                                  articuloInfo: {
                                    articulosNombre: field.articulosNombre,
                                    articulos: field.articulos,
                                    availableTallas: Array.from(
                                      new Set(
                                        inputFields
                                          .filter(
                                            (f) =>
                                              f.articulosNombre === field.articulosNombre
                                          )
                                          .map((f) => f.tallaNombre)
                                          .filter(Boolean)
                                          .sort((a, b) => {
                                            const tallaOrder = [
                                              "EECH",
                                              "ECH",
                                              "CH",
                                              "S",
                                              "M",
                                              "G",
                                              "L",
                                              "EG",
                                              "XL",
                                              "2EG",
                                              "XXL",
                                              "3EG",
                                              "4EG",
                                              "5EG",
                                              "6EG",
                                              "1",
                                              "2",
                                              "3",
                                              "4",
                                              "5",
                                              "6",
                                              "7",
                                              "8",
                                              "9",
                                              "10",
                                              "11",
                                              "12",
                                              "13",
                                              "14",
                                              "15",
                                              "16",
                                              "17",
                                              "18",
                                              "19",
                                              "20",
                                              "21",
                                              "22",
                                              "23",
                                              "24",
                                              "25",
                                              "26",
                                              "27",
                                              "28",
                                              "29",
                                              "30",
                                              "31",
                                              "32",
                                              "33",
                                              "34",
                                              "35",
                                              "36",
                                              "37",
                                              "38",
                                              "39",
                                              "40",
                                              "41",
                                              "42",
                                              "43",
                                              "44",
                                              "45",
                                              "46",
                                              "47",
                                              "48",
                                              "49",
                                              "50",
                                              "3/4",
                                              "5/6",
                                              "7/8",
                                              "9/10",
                                              "11/12",
                                              "10/12",
                                              "13/14",
                                              "14/16",
                                              "15/16",
                                            ];
                                            return (
                                              tallaOrder.indexOf(a) - tallaOrder.indexOf(b)
                                            );
                                          })
                                      )
                                    ),
                                  },
                                  colorGroups: [],
                                };
                              }
            
                              const existingColorIndex = acc[key].colorGroups.findIndex(
                                (item) => item.idColor === field.idColor
                              );
            
                              if (existingColorIndex === -1) {
                                acc[key].colorGroups.push({
                                  id: field.id,
                                  articulos: field.articulos,
                                  articulosNombre: field.articulosNombre,
                                  idColor: field.idColor,
                                  colorNombre: field.colorNombre,
                                  colores: field.colores,
                                  precioOriginal: field.precioOriginal,
                                  precio: field.precio,
                                  ubicacion: field.ubicacion,
                                  quantities: {
                                    [field.tallaNombre]: {
                                      cantidad: field.cantidad,
                                      id: field.id,
                                    },
                                  },
                                  total: field.cantidad * field.precio,
                                });
                              } else {
                                acc[key].colorGroups[existingColorIndex].quantities[
                                  field.tallaNombre
                                ] = {
                                  cantidad: field.cantidad,
                                  id: field.id,
                                };
                                acc[key].colorGroups[existingColorIndex].total +=
                                  field.cantidad * field.precio;
                              }
                              return acc;
                            }, {})
                        ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
                          // Calculate totals for this article
                          const articleTotals = colorGroups.reduce(
                            (totals, group) => {
                              const quantitySum = Object.values(group.quantities).reduce(
                                (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                                0
                              );
                              const priceSum = Object.values(group.quantities).reduce(
                                (sum, q) =>
                                  sum + (parseFloat(q.cantidad) || 0) * group.precio,
                                0
                              );
            
                              return {
                                items: totals.items + quantitySum,
                                price: totals.price + priceSum,
                                singlePrice: group.precio,
                              };
                            },
                            { items: 0, price: 0 }
                          );
            
                          return (
                            <div key={articuloNombre} className="mb-8">
                              <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                                <div className="d-flex align-items-center">
                                  <Button
                                    size="sm"
                                    className="btn mr-3"
                                    color="info"
                                    onClick={() =>
                                      setExpandedTables((prev) => ({
                                        ...prev,
                                        [articuloNombre]: !prev[articuloNombre],
                                      }))
                                    }
                                  >
                                    <i
                                      className={`fas fa-chevron-${
                                        expandedTables[articuloNombre] ? "up" : "down"
                                      }`}
                                    ></i>
                                  </Button>
                                  <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                                  {expandedTables && (
                                    <div style={{ marginRight: "20px" }}>
                                      <Label
                                        className="mr-sm-2"
                                        style={{ fontSize: "18px", color: "black" }}
                                      >
                                        Ubicacion
                                      </Label>
                                      <Input
                                        name="idColor"
                                        type="string"
                                        value={colorGroups[0].ubicacion}
                                        onChange={(event) =>
                                          handleChangeInputUbicacion(
                                            colorGroups[0].articulos,
                                            event
                                          )
                                        }
                                        style={{ fontSize: "18px", minWidth: "150px" }}
                                      />
                                    </div>
                                  )}
                                </div>
                                {!expandedTables[articuloNombre] && (
                                  <div className="d-flex align-items-center">
                                    <span
                                      className="mr-4 text-dark"
                                      style={{ fontSize: "18px" }}
                                    >
                                      <strong style={{ fontSize: "18px" }}>Piezas:</strong>{" "}
                                      {articleTotals.items}
                                    </span>
                                    <span
                                      className="mr-4 text-dark"
                                      style={{ fontSize: "18px" }}
                                    >
                                      <strong style={{ fontSize: "18px" }}>Precio:</strong>{" "}
                                      $
                                      {articleTotals.singlePrice.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </span>
                                    <span
                                      className="text-dark"
                                      style={{ fontSize: "18px" }}
                                    >
                                      <strong style={{ fontSize: "18px" }}>Total:</strong> $
                                      {articleTotals.price.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </span>
                                    {!expandedTables && (
                                      <div style={{ marginRight: "20px" }}>
                                        <Label
                                          className="mr-sm-2"
                                          style={{ fontSize: "18px", color: "black" }}
                                        >
                                          Ubicacion
                                        </Label>
                                        <Input
                                          name="idColor"
                                          type="string"
                                          value={colorGroups[0].ubicacion}
                                          onChange={(event) =>
                                            handleChangeInputUbicacion(
                                              colorGroups[0].articulos,
                                              event
                                            )
                                          }
                                          style={{ fontSize: "18px", minWidth: "150px" }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
            
                              {expandedTables[articuloNombre] && (
                                <Table borderless>
                                  <thead>
                                    <tr>
                                      <th>Articulo</th>
                                      <th>Color</th>
                                      {articuloInfo.availableTallas.map((talla) => (
                                        <th key={talla} style={{ maxWidth: "35px" }}>
                                          {talla}
                                        </th>
                                      ))}
                                      <th style={{ maxWidth: "60px" }}>Precio USD</th>
                                      <th style={{ maxWidth: "60px" }}>Total</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {colorGroups.map((group) => (
                                      <tr key={`${group.id}-${group.idColor}`}>
                                        {/* Your existing row content */}
                                        <td style={{ maxWidth: "60px" }}>
                                          <Input
                                            name="articulo"
                                            type="string"
                                            placeholder="Articulo"
                                            value={group.articulosNombre}
                                            disabled
                                          />
                                        </td>
                                        <td style={{ maxWidth: "35px" }}>
                                          <Input
                                            name="idColor"
                                            type="select"
                                            value={group.idColor}
                                            disabled
                                          >
                                            <option value="">Selecciona</option>
                                            {group.colores?.map((a) => (
                                              <option key={a._id} value={a._id}>
                                                {a.name}
                                              </option>
                                            ))}
                                          </Input>
                                        </td>
                                        {articuloInfo.availableTallas.map((talla) => (
                                          <td key={talla} style={{ maxWidth: "40px" }}>
                                            <Input
                                              name="cantidad"
                                              type="number"
                                              min="0"
                                              step="any"
                                              value={group.quantities[talla]?.cantidad || 0}
                                              onChange={(event) =>
                                                handleChangeInputCantidad(
                                                  group.quantities[talla]?.id || group.id,
                                                  event
                                                )
                                              }
                                            />
                                          </td>
                                        ))}
            
            
                                        <td style={{ maxWidth: "60px" }}>
                                          <Input
                                            style={
                                              group.precioOriginal - 50 > group.precio
                                                ? { backgroundColor: "#ed8c8c" }
                                                : null
                                            }
                                            name="precio"
                                            type="number"
                                            min="0"
                                            step="any"
                                            placeholder="Precio"
                                            value={group.precio}
                                            required
                                            onChange={(event) =>
                                              handleChangeInputCantidad(group.id, event)
                                            }
                                          />
                                        </td>
                                        <td style={{ maxWidth: "80px" }}>
                                          <Input
                                            name="total"
                                            type="number"
                                            min="0"
                                            step="any"
                                            placeholder="total"
                                            value={group.total}
                                            disabled
                                          />
                                        </td>
                                       
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              )}
                            </div>
                          );
                        })}
            

          
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasCreate;
