import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Autorizado from "../../Autorizado";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

import { v4 as uuidv4 } from "uuid";

function ListadoOrdenesCompra() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;

  const [articulos, setArticulos] = useState([]);

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [selectedFechaCompInicio, setSelectedFechaCompInicio] = useState("");
  const [selectedFechaCompFin, setSelectedFechaCompFin] = useState("");

  const [fotos, setFotos] = useState([]);

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_cantidad, setTotalCantidad] = useState("");
  const [numero_orden, setNumeroOrden] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosOrden, setArticulosOrden] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);

  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   articulos: "",
    //   cantidad: 0,
    //   precio: 0,
    //   total: 0,
    //   idColor: "",
    //   idTalla: "",
    //   colores: [],
    //   tallas: [],
    // },
  ]);
  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      precio: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [status, setStatus] = useState("");
  const [autorizado, setAutorizado] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [expandedTables, setExpandedTables] = useState({});

  const ITEMS_PER_PAGE = 50;

   useEffect(() => {
      ActualizaTotales();
    }, [inputFields, ActualizaTotales]);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allOrdenes = response.data;
        // Array para pagination
        let arrayTabla = allOrdenes
          .sort((a, b) => (a.idOrdenCompra > b.idOrdenCompra ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idOrdenCompra,
              activo: a.is_active,
              fecha: a.fecha,
              plazo: a.plazo,
              fecha_compromiso: a.fecha_compromiso,
              observaciones: a.observaciones,
              total_general: a.total_general,
              proveedores: a.proveedores[0].nombre_comercial,
              idProveedores: a.proveedores[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
              autorizado: a.autorizado,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizado == 0 || autorizado == a.autorizado) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.autorizado,
          a.numero,
          a.fecha,
          a.proveedores,
          a.observaciones,
          a.total_cantidad,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Status",
          "Autorizado",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Ordenes-Compra.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizado == 0 || autorizado == a.autorizado) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Status: a.status,
          Autorizado: a.autorizado,
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedor: a.proveedor,
          Observaciones: a.observaciones,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesCompra";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesCompra",
        sheetFilter: [
          "Status",
          "Autorizado",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Status",
          "Autorizado",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizado == 0 || autorizado == a.autorizado) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.autorizado,
          a.numero,
          a.fecha,
          a.proveedores,
          a.observaciones,
          a.total_cantidad,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Status",
          "Autorizado",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Compra",
          email: mailTo,
          fileName: "ListadoOrdenesCompra.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Produccion.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    plazo,
    fecha_compromiso,
    idProveedores,
    total_cantidad,
    total_general,
    observaciones,
    numero_orden,
    idEdit
  ) {
    setFecha(fecha);
    setPlazo(plazo);
    setFechaCompromiso(fecha_compromiso);
    setSelectedProveedorEdit(idProveedores);
    setTotalCantidad(total_cantidad);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroOrden(numero_orden);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    plazo,
    fecha_compromiso,
    idProveedores,
    proveedores,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              tallasColores: a.tallasColores,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              pendiente_embarcar: a.pendiente_embarcar,
              idArticulo: a.articulos[0]._id,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              // foto: ""
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            total: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].idArticulo = item.idArticulo;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          groups[val].total += item.total;
          return groups;
        }, []);

        let dataAgrupado = Object.values(agrupado);

        // dataFinal.map(async (z) => {
        //       const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.idArticulo}`;
        //     //  let yyy =
        //      await axios
        //         .get(URL_GET_MEDIA, {
        //           headers: {
        //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //           },
        //         })
        //         .then((data) => {
        //           // return data.data.secure_url;
        //           // console.log(www);
        //           z.foto = data.data.secure_url
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //         });
        //       // return   z.foto = yyy
        //       return z
        //     });

        //     setTimeout(() => {

        const data = dataAgrupado
          .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
          .map((a) => {
            return [
              // a.foto,
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" + new Intl.NumberFormat("en-US").format(a.total),
            ];
          });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 140, 15, 38, 18);
        doc.text(`Orden de Compra # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(total_cantidad)}`,
          20,
          40
        );
        doc.autoTable({
          // columnStyles: {
          //   0: {
          //     fontSize: .0001
          //   },
          // },
          head: [["Articulo", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          // bodyStyles:{
          //   rowHeight : 22
          // },
          startY: 45,

          // didDrawCell: function(data) {
          //   if (data.column.index === 0 && data.cell.section === 'body') {
          //      var td = data.cell.raw;
          //      var img = td
          //      var dim = data.cell.height - data.cell.padding('vertical');
          //      var textPos = data.cell;
          //      doc.addImage(img, textPos.x,  textPos.y, 20, 20);
          //   }
          // },
          foot: [
            [
              // "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`OC-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
        // }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function ExcelOC(numero, idPDFOC) {
    await axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              Articulo: a.articulos[0].codigo,
              TallasColores: a.tallasColores,
              Cantidad: a.cantidad,
              Precio: a.precio,
              Total: a.total,
              // articulosOrden: a._id,
              // articulos: a.articulos[0]._id,
              // cantidad: a.cantidad,
              // cantidadOC: a.cantidad,
              // costo: a.precio,
              // costoTotal: a.total,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        var option = {};

        option.fileName = `OC-${numero}`;

        option.datas = [
          {
            sheetData: dataFinal,
            sheetName: `OC-${numero}`,
            sheetFilter: [
              "Articulo",
              "Cantidad",
              "Precio",
              "Total",
              // "articulosOrden",
              // "articulos",
              // "cantidad",
              // "cantidadOC",
              // "costo",
              // "costoTotal",
            ],
            sheetHeader: [
              "Articulo",
              "Cantidad",
              "Precio",
              "Total",
              // "articulosOrden",
              // "articulos",
              // "cantidad",
              // "cantidadOC",
              // "costo",
              // "costoTotal",
            ],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editOrdenes(event) {
    event.preventDefault();
    const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_ORDENES_COMPRA_EDIT,
          {
            fecha,
            plazo,
            fecha_compromiso,
            proveedores: selectedProveedorEdit,
            total_cantidad,
            total_general,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Orden deCompra",
            detalle: `${numero_orden} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizado) {
      computedComments = computedComments.filter((e) =>
        e.autorizado.includes(autorizado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaCompInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso >= selectedFechaCompInicio
      );
    }

    if (selectedFechaCompFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso <= selectedFechaCompFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    status,
    autorizado,
    selectedFechaInicio,
    selectedFechaFin,
    selectedProveedor,
  ]);

  function EditArticulos(idOC, numero_orden) {
    // setInputFields([
    //   {
    //     id: uuidv4(),
    //     articulos: "",
    //     cantidad: 0,
    //     precio: 0,
    //     total: 0,
    //     idColor: "",
    //     idTalla: "",
    //     colores: [],
    //     tallas: [],
    //   },
    // ]);
    setIdEdit(idOC);
    setNumeroOrden(numero_orden);
    axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              nombreArticulo: a.articulos[0].codigo,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              pendiente_embarcar: a.pendiente_embarcar,
              embarcado: a.embarcado,
              colorNombre: a.colores[0].name,
              idColor: a.colores[0]._id,
              tallaNombre: a.tallas[0].name,
              idTalla: a.tallas[0]._id,
              colores: a.articulos[0].colores,
              tallas: a.articulos[0].tallas,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosOrden(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        let PR =
          parseFloat(i.pendiente_embarcar) -
          parseFloat(i.cantidad) +
          parseFloat(event.target.value);
        i.pendiente_embarcar = PR;
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        handleChangeInputExistentesArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputExistentesArticulo = (id, idArticulo) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        precio: "",
        total: 0,
        idColor: "",
        idTalla: "",
        colores: [],
        tallas: [],
      },
    ]);
  };
  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function agregarProporcion(
    idArticulo,
    articulosNombre,
    precio,
    cajas,
    colores,
    tallas
  ) {
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;

        const newInputFields = allArticulos.map((i) => {
          return {
            id: uuidv4(),
            articulos: idArticulo,
            articulosNombre: articulosNombre,
            precio: precio,
            cantidad: cajas * i.cantidad,
            idTalla: i.tallas[0]._id,
            tallaNombre: i.tallas[0].name,
            idColor: i.colores[0]._id,
            colorNombre: i.colores[0].name,
            total: cajas * i.cantidad * precio,
            colores: colores,
            tallas: tallas,
            idArticulosProporcion: i._id,
          };
        });

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          const tallaOrder = [
            "EECH",
            "ECH",
            "CH",
            "M",
            "G",
            "EG",
            "2EG",
            "3EG",
            "4EG",
            "5EG",
            "6EG",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "5/6",
            "7/8",
            "10/12",
            "14/16",
          ];

          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Calculate total quantities and amounts
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );
          const totalGeneral = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.total),
            0
          );

          // Update state with totals
          setTotalCantidad(totalCantidad);
          setTotalGeneral(totalGeneral);

          return sortedInputFields;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cantidadExistentes = articulosOrden.map((c) => parseFloat(c.cantidad));
    let cantidadNuevos = values.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosOrden.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let FOB = a.costoUSD;
        let colores = a.colores;
        let tallas = a.tallas;
        let nombre = a.codigo + " " + a.nombre;
        handleChangeInputArticulo(id, idArticulo, FOB, colores, tallas, nombre);
        handlechangeIputArticulos(id, idArticulo, FOB, colores, tallas, nombre);
      }
    });
  }

  const handlechangeIputArticulos = (
    id,
    idArticulo,
    FOB,
    colores,
    tallas,
    nombre
  ) => {
    const newInputArticulo = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = FOB;
        i.colores = colores;
        i.tallas = tallas;
        i.nombreArticulo = nombre;
      }
      return i;
    });
    setInputArticulos(newInputArticulo);
  };

  const handleChangeInputArticuloCajas = (id, event) => {
    const newInputArticulo = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return null;
    });
    setInputArticulos(newInputArticulo);
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    FOB,
    colores,
    tallas,
    nombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.precio = FOB;
        i.colores = colores;
        i.tallas = tallas;
        i.articulosNombre = nombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidadExistentes = articulosOrden.map((c) => parseFloat(c.cantidad));
    let cantidadNuevos = inputFields.map((c) => parseFloat(c.cantidad));
    let cantidad = cantidadExistentes.concat(cantidadNuevos);
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importesExistentes = articulosOrden.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    toggleProgreso();

    let totalArticulos = inputFields.length + articulosOrden.length;

    const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_ORDENES_COMPRA_EDIT,
          {
            total_general: total_general_edit,
            total_cantidad: total_cantidad_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos !== "" && a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_OC,
                  {
                    ordenesCompra: idEdit,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    embarcado: 0,
                    pendiente_embarcar: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                    colores: a.idColor,
                    tallas: a.idTalla,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Orden de Compra",
                          detalle: `${numero_orden}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Orden de Compra",
                      detalle: `${numero_orden}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          articulosOrden.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_OC}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                  pendiente_embarcar: parseFloat(a.pendiente_embarcar),
                  colores: a.idColor,
                  tallas: a.idTalla,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Orden de Compra",
                        detalle: `${numero_orden}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function excelInfoArt() {
    axios
      .get(`${URL_ARTICULOS_OC}FindAllInfoArt`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfoArt = response.data;

        const dataExcel = allInfoArt.map((a) => {
          return {
            Articulo: a.nombre,
            Cantidad: a.cantidad,
            Embarcado: a.embarcado,
            PendienteEmbarcar: a.pendiente_embarcar,
          };
        });
        const dataExportExcel = Object.values(dataExcel);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        var option = {};

        option.fileName = "Info Articulos OC";

        option.datas = [
          {
            sheetData: dataExcelLimpia,
            sheetName: "Info Articulos OC",
            sheetFilter: [
              "Articulo",
              "Cantidad",
              "Embarcado",
              "PendienteEmbarcar",
            ],
            sheetHeader: [
              "Articulo",
              "Cantidad",
              "Embarcado",
              "PendienteEmbarcar",
            ],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_compras ? (
                <>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    href="/OrdenesCompraCreate"
                  >
                    Nueva Orden
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    disabled
                  >
                    Nueva Orden
                  </Button>
                  {/* <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Orden Masiva
                </Button> */}
                </>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelInfoArt}
                >
                  Excel Info Art <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Produccion</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Produccion</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            {/* 
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompInicio}
                required
                onChange={(e) => {
                  setSelectedFechaCompInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompFin}
                required
                onChange={(e) => {
                  setSelectedFechaCompFin(e.target.value);
                }}
              />
            </Col> */}

            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={status}
                required
                onChange={(e) => {
                  setStatus(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Abierto">Abierto</option>
                <option value="Cerrado">Cerrado</option>
              </Input>
            </Col>
            <Col md={2}>
              <Label>Autorizado</Label>
              <Input
                bsSize="sm"
                type="select"
                value={autorizado}
                required
                onChange={(e) => {
                  setAutorizado(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.proveedores}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.total_cantidad
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.total_general
                          )}{" "}
                      </td>
                      <td>
                        {user.menu_compras ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.plazo,
                                  a.fecha_compromiso,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.total_cantidad,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="primary"
                              onClick={(e) => ExcelOC(a.numero, a._id)}
                            >
                              <i class="far fa-file-excel"></i>
                            </Button>
                            <Autorizado
                              idStatus={a._id}
                              autorizado={a.autorizado}
                              URL_AUTORIZADO={
                                process.env.REACT_APP_URL_ORDENES_COMPRA
                              }
                            />

                            {/* <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ORDENES_COMPRA}
                            /> 
                             <BajaAltaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ORDENES_COMPRA}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_OC}/baja`}
                            />*/}
                            <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={
                                process.env.REACT_APP_URL_ORDENES_COMPRA
                              }
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_OC}/baja`}
                            />
                          </div>
                        ) : undefined}
                      </td>
                      <td>
                        {a.status == "Abierto" && a.activo == "Si" ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.plazo,
                                  a.fecha_compromiso,
                                  a.idProveedores,
                                  a.total_cantidad,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditArticulos(a._id, a.numero)}
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Orden de Compra {numero_orden}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Proveedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona un Proveedor</option>
                {proveedores
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Plazo</Label>
              <Input
                type="text"
                placeholder="Plazo"
                value={plazo}
                required
                onChange={(e) => {
                  setPlazo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Compromiso</Label>
              <Input
                type="date"
                placeholder="Fecha Compromiso"
                value={fecha_compromiso}
                required
                onChange={(e) => {
                  setFechaCompromiso(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={4}>
              <h4 id="logoutBoton">
              TOTALES {total_cantidad} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editOrdenes}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos OC {numero_orden}</h4>
          <h4>
            TOTALES {total_cantidad_edit} pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>

          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Selecciona</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Articulo</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cajas</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Precio USD</Label>
            </Col>
          </Row>
          {inputArticulos.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    // bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {articulos
                      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.codigo} {a.nombre}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Input
                    name="articulosNombre"
                    type="string"
                    placeholder="Articulo"
                    value={inputFields.articulosNombre}
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    name="cajas"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cajas"
                    value={inputFields.cajas}
                    required
                    onChange={(event) =>
                      handleChangeInputProporcion(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={inputField.precio}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={() =>
                      agregarProporcion(
                        inputField.articulos,
                        inputField.nombreArticulo,
                        inputField.precio,
                        inputField.cajas,
                        inputField.colores,
                        inputField.tallas
                      )
                    }
                    tabindex="-1"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </div>
          ))}

          {/* Group articulosOrden by articleName */}
          {Object.entries(
  [...articulosOrden, ...inputFields] // Combine both arrays
    .filter((item) => item.nombreArticulo || item.articulosNombre)
    .reduce((acc, item) => {
      // Use either nombreArticulo or articulosNombre as the key
      const key = `${item.nombreArticulo || item.articulosNombre}`;
      
      if (!acc[key]) {
        acc[key] = {
          articuloInfo: {
            nombreArticulo: item.nombreArticulo || item.articulosNombre,
            articulos: item.articulos,
            availableTallas: Array.from(
              new Set(
                [...articulosOrden, ...inputFields] // Search in both arrays
                  .filter(
                    (f) =>
                      f.nombreArticulo === key ||
                      f.articulosNombre === key
                  )
                  .map((f) => {
                    // Handle different structures between articulosOrden and inputFields
                    if (f.tallas) {
                      return f.tallas.find(t => t._id === f.idTalla)?.name;
                    }
                    return f.tallaNombre;
                  })
                  .filter(Boolean)
                  .sort((a, b) => {
                    const tallaOrder = [
                      "EECH", "ECH", "CH", "S", "M", "G", "L", "EG", "XL", "2EG", "XXL", "3EG", "4EG",
                      "5EG", "6EG", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13",
                      "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27",
                      "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41",
                      "42", "43", "44", "45", "46", "47", "48", "49", "50", "3/4", "5/6", "7/8", "9/10",
                      "11/12", "10/12", "13/14", "14/16", "15/16",
                    ];
                    return tallaOrder.indexOf(a) - tallaOrder.indexOf(b);
                  })
              )
            ),
          },
          colorGroups: [],
        };
      }

      // Get the relevant size/talla name based on the item type
      let tallaNombre;
      if (item.tallas && item.idTalla) {
        // For articulosOrden items
        tallaNombre = item.tallas.find(t => t._id === item.idTalla)?.name;
      } else {
        // For inputFields items
        tallaNombre = item.tallaNombre;
      }

      const existingColorIndex = acc[key].colorGroups.findIndex(
        (group) => group.idColor === item.idColor
      );

      if (existingColorIndex === -1) {
        // Create a new color group
        const colorNombre = item.colorNombre || 
          (item.colores?.find(c => c._id === item.idColor)?.name);
          
        acc[key].colorGroups.push({
          id: item.id,
          articulos: item.articulos,
          nombreArticulo: item.nombreArticulo || item.articulosNombre,
          idColor: item.idColor,
          colorNombre: colorNombre,
          colores: item.colores,
          tallas: item.tallas,
          precio: item.precio,
          embarcado: item.embarcado || 0,
          pendiente_embarcar: item.pendiente_embarcar || 0,
          // Flag to identify if this is from inputFields or articulosOrden (hidden from user)
          isNewItem: !!item.articulosNombre, 
          quantities: {
            [tallaNombre]: {
              cantidad: item.cantidad,
              id: item.id,
              embarcado: item.embarcado || 0,
              pendiente_embarcar: item.pendiente_embarcar || 0,
              isNewItem: !!item.articulosNombre
            },
          },
          total: item.cantidad * item.precio,
        });
      } else {
        // Add to existing color group
        if (tallaNombre) {
          acc[key].colorGroups[existingColorIndex].quantities[tallaNombre] = {
            cantidad: item.cantidad,
            id: item.id,
            embarcado: item.embarcado || 0,
            pendiente_embarcar: item.pendiente_embarcar || 0,
            isNewItem: !!item.articulosNombre
          };
          acc[key].colorGroups[existingColorIndex].total +=
            item.cantidad * item.precio;
        }
      }
      
      return acc;
    }, {})
).map(([nombreArticulo, { articuloInfo, colorGroups }]) => {
  // Calculate totals for this article
  const articleTotals = colorGroups.reduce(
    (totals, group) => {
      const quantitySum = Object.values(group.quantities).reduce(
        (sum, q) => sum + (parseFloat(q.cantidad) || 0),
        0
      );
      
      const embarcadoSum = Object.values(group.quantities).reduce(
        (sum, q) => sum + (parseFloat(q.embarcado) || 0),
        0
      );
      
      const pendienteSum = Object.values(group.quantities).reduce(
        (sum, q) => sum + (parseFloat(q.pendiente_embarcar) || 0),
        0
      );
      
      const priceSum = Object.values(group.quantities).reduce(
        (sum, q) => sum + (parseFloat(q.cantidad) || 0) * group.precio,
        0
      );

      return {
        items: totals.items + quantitySum,
        embarcado: totals.embarcado + embarcadoSum,
        pendiente: totals.pendiente + pendienteSum,
        price: totals.price + priceSum,
        singlePrice: group.precio,
      };
    },
    { items: 0, embarcado: 0, pendiente: 0, price: 0, singlePrice: 0 }
  );

  return (
    <div key={nombreArticulo} className="mb-8">
      <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            className="btn mr-3"
            color="info"
            onClick={() =>
              setExpandedTables((prev) => ({
                ...prev,
                [nombreArticulo]: !prev[nombreArticulo],
              }))
            }
          >
            <i
              className={`fas fa-chevron-${
                expandedTables[nombreArticulo] ? "up" : "down"
              }`}
            ></i>
          </Button>
          <h4 className="negrita mb-0 mr-4">{nombreArticulo}</h4>
        </div>
        {!expandedTables[nombreArticulo] && (
          <div className="d-flex align-items-center">
            <span className="mr-4 text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Piezas:</strong>{" "}
              {articleTotals.items}
            </span>
            <span className="mr-4 text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Embarcado:</strong>{" "}
              {articleTotals.embarcado}
            </span>
            <span className="mr-4 text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Pendiente:</strong>{" "}
              {articleTotals.pendiente}
            </span>
            <span className="mr-4 text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Precio:</strong>{" "}
              ${articleTotals.singlePrice.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Total:</strong> $
              {articleTotals.price.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        )}
      </div>

      {expandedTables[nombreArticulo] && (
        <Table borderless>
          <thead>
            <tr>
              <th>Articulo</th>
              <th>Color</th>
              {articuloInfo.availableTallas.map((talla) => (
                <th key={talla} style={{ maxWidth: "35px" }}>
                  {talla}
                </th>
              ))}
              <th>Embarcado</th>
              <th>Pendiente</th>
              <th style={{ maxWidth: "60px" }}>Precio USD</th>
              <th style={{ maxWidth: "60px" }}>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {colorGroups.map((group) => (
              <tr key={`${group.id}-${group.idColor}`}>
                <td style={{ maxWidth: "60px" }}>
                  <Input
                    name="articulos"
                    type="select"
                    value={group.articulos}
                    required
                    disabled={!group.isNewItem}
                    onChange={(event) => {
                      if (group.isNewItem) {
                        BuscaArticulo(group.id, event);
                      }
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {articulos
                      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                      .map((a) => (
                        <option key={a._id} value={a._id}>{a.codigo}</option>
                      ))}
                  </Input>
                </td>
                <td style={{ maxWidth: "35px" }}>
                  <Input
                    name="idColor"
                    type="select"
                    value={group.idColor}
                    onChange={(event) =>
                      group.isNewItem 
                        ? handleChangeInput(group.id, event)
                        : handleChangeInputExistentes(group.id, event)
                    }
                    disabled={group.embarcado > 0}
                  >
                    <option value="">Selecciona</option>
                    {group.colores?.map((a) => (
                      <option key={a._id} value={a._id}>
                        {a.name}
                      </option>
                    ))}
                  </Input>
                </td>

                {articuloInfo.availableTallas.map((talla) => {
                  const tallaData = group.quantities[talla];
                  const isSelectedTalla = !!tallaData;
                  
                  return (
                    <td key={talla} style={{ maxWidth: "40px" }}>
                      <Input
                        name="cantidad"
                        type="number"
                        min="0"
                        step="any"
                        value={tallaData?.cantidad || 0}
                        onChange={(event) => {
                          if (tallaData) {
                            // Update existing talla quantity
                            if (tallaData.isNewItem) {
                              handleChangeInput(tallaData.id, event);
                            } else {
                              handleChangeInputExistentesCantidad(tallaData.id, event);
                            }
                          } else if (group.isNewItem) {
                            // For new items, we need to select the talla first
                            // Look up the talla in the available tallas
                            const matchTalla = group.tallas?.find(t => t.name === talla);
                            if (matchTalla) {
                              // Create synthetic event for talla selection
                              const tallaEvent = {
                                target: {
                                  name: "idTalla",
                                  value: matchTalla._id
                                }
                              };
                              handleChangeInput(group.id, tallaEvent);
                              
                              // Then handle quantity change
                              setTimeout(() => {
                                handleChangeInput(group.id, event);
                              }, 0);
                            }
                          }
                        }}
                        disabled={!tallaData && !group.isNewItem}
                      />
                    </td>
                  );
                })}

                <td style={{ maxWidth: "60px" }}>
                  <Input
                    name="embarcado"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="embarcado"
                    value={group.embarcado}
                    required
                    disabled
                  />
                </td>
                <td style={{ maxWidth: "60px" }}>
                  <Input
                    name="pendiente_embarcar"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="pendiente_embarcar"
                    value={group.pendiente_embarcar}
                    required
                    disabled
                  />
                </td>
                <td style={{ maxWidth: "60px" }}>
                  <Input
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={group.precio}
                    required
                    onChange={(event) =>
                      group.isNewItem 
                        ? handleChangeInput(group.id, event)
                        : handleChangeInputExistentes(group.id, event)
                    }
                    disabled={group.embarcado > 0}
                  />
                </td>
                <td style={{ maxWidth: "80px" }}>
                  <Input
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="total"
                    value={group.total}
                    disabled
                  />
                </td>
                <td>
                  {group.isNewItem && (
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={() => handleRemoveFields(group.id)}
                      tabIndex="-1"
                    >
                      <i className="fas fa-minus"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
})}



          <Row>
            <Col md={6}>
              <h4 id="logoutBoton">
                TOTALES {total_cantidad_edit} pzas. /{" "}
                {"$" +
                  new Intl.NumberFormat("en-US").format(total_general_edit)}
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={saveOrdenCompra}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesCompra;
