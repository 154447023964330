import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function NotasCreditoCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SERIES_FISCAL = process.env.REACT_APP_URL_SERIES_FISCAL;
  const URL_TIMBRAR = process.env.REACT_APP_URL_TIMBRAR;
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_NOTAS_CREDITO = process.env.REACT_APP_URL_NOTAS_CREDITO;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  
  const [timbresDisponibles, setTimbresDisponibles] = useState(0);
  
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);

  const [facturas, setFacturas] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFactura, setSelectedFactura] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [formaPagoSAT, setFormaPagoSAT] = useState("01");
  const [formaPagoSATCliente, setFormaPagoSATCliente] = useState("")


  const [cpEmpresa, setCpEmpresa] = useState("");
  const [rfcEmpresa, setRfcEmpresa] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [regFiscalEmpresa, setRegFiscalEmpresa] = useState("");


  const [serie, setSerie] = useState("")

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_CLIENTES}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_SERIES_FISCAL}Timbres`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allTimbres = res.data;
          setTimbresDisponibles(allTimbres[0].folio);
        })
        .catch((err) => {
          console.log(err);
        });
     
      axios
      .get(`${URL_EMPRESAS}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allEmpresas = res.data;
          setEmpresas(allEmpresas);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(`${URL_COLABORADORES}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allColaboradores = res.data;
            setColaboradores(allColaboradores);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);

  const saveNota = async (event) => {
    event.preventDefault();
    if(timbresDisponibles > 0){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará la Nota de Crédito!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
    if (
      result.isConfirmed &&
      total > 0
    ) {
      setValidaBoton(false);
      toggleProgreso();
     
      let totalArticulos =
        inputFields.length 
      try {
        await axios
          .post(
            URL_NOTAS_CREDITO,
            {
              fecha,
              clientes: selectedCliente,
              colaboradores: selectedColaborador,
              empresas: selectedEmpresa,
              facturas: selectedFactura,
              observaciones,

              timbre:"No",
              cfdiType:"E",

              serie,
              expeditionPlace: cpEmpresa,
              
              rfcEmpresa,
              nombreEmpresa,
              regFiscalEmpresa,
              cfdiUse:"G02",

              paymentForm: formaPagoSATCliente,
              
              subTotal,
              iva,
              total,
              currency: "MXN",

            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            // inputFields.map((w) => {
            //   axios
            //     .patch(
            //       `${URL_FACTURAS}NotaCredito/${w.facturas}`,
            //       {
            //         abonos: total,
            //       },
            //       {
            //         headers: {
            //           Authorization: `Bearer: ${localStorage.getItem(
            //             "app_token"
            //           )}`,
            //         },
            //       }
            //     )
            //     .then(() => {
            //       totalArticulos = totalArticulos - 1;
            //       if (totalArticulos == 0) {

                    axios.post(`${URL_TIMBRAR}NotaCredito`,{
                      notasCredito: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    ).then(()=>{

                      axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Nota Credito",
                          detalle: `Fecha Nota Credito ${fecha} ${data.data.idNotaCredito}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });

                    }).catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
            //       }
            //     })
            //     .catch((error) => {
            //       Swal.fire({
            //         icon: "error",
            //         title: "Oops...",
            //         text: "Something went wrong!",
            //         footer: `${error.response.data}`,
            //       });
            //       console.log(error);
            //       setValidaBoton(true);
            //     });
            // });

          
          })
          // Catch primer then
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data.message}`,
            });
            console.log(error);
            setValidaBoton(true);
            setModalProgreso(false)
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
      setValidaBoton(true);
    }
  })
}else{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
    footer: `No cuenta con timbres disponibles`,
  });
  setValidaBoton(true);
  setModalProgreso(false)
}
  };




  function BuscaCliente(event) {
    setSelectedCliente(event.target.value)

    setSubTotal(0)
    setIva(0)
    setTotal(0)

    setInputFields(
    [{
      id: uuidv4(),
      facturas: "",
      idFactura: "",
      total: 0,
      abonos: 0,
      saldo: 0,
      impPagar: 0,
    }]
    );


    clientes.map((a)=>{
      if(a._id == event.target.value){
      setSelectedColaborador(a.contacto[0]._id)
      if(a.metodoPagoSAT != "PPD"){
        setFormaPagoSATCliente(a.formaPagoSAT)
        }else{
          setFormaPagoSATCliente("99")
        }
    if(event.target.value != ""  && selectedEmpresa != ""){
      axios
      .get(`${URL_FACTURAS}CompPago/${event.target.value}/${selectedEmpresa}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allFacturas = res.data;
          let arrayTabla = allFacturas.map((a)=>{
            return {
              id: uuidv4(),
              facturas: a._id,
              idFactura: a.idFactura,
              total: a.total,
              abonos: a.abonos,
              saldo: a.saldo,
              impPagar: a.saldo,
            }
          })
          let dataFinal = Object.values(arrayTabla);
          setFacturas(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  })
  }

  function BuscaEmpresa(event) {
    setSelectedEmpresa(event.target.value)

    empresas.map((a)=>{
      if(a._id == event.target.value){
        setCpEmpresa(a.cp)
        setRfcEmpresa(a.rfc)
        setNombreEmpresa(a.razonSocial)
        setRegFiscalEmpresa(a.regimenFiscal)
      }
    })
    setSubTotal(0)
    setIva(0)
    setTotal(0)
    setInputFields(
    [{
      id: uuidv4(),
      facturas: "",
      idFactura: "",
      total: 0,
      abonos: 0,
      saldo: 0,
      impPagar: 0,
    }]
    );
    if(event.target.value != "" && selectedCliente != ""){
      axios
      .get(`${URL_FACTURAS}CompPago/${selectedCliente}/${event.target.value}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allFacturas = res.data;
          let arrayTabla = allFacturas.map((a)=>{
            return {
              id: uuidv4(),
              facturas: a._id,
              idFactura: a.idFactura,
              total: a.total,
              abonos: a.abonos,
              saldo: a.saldo,
              impPagar: a.saldo,
            }
          })
          let dataFinal = Object.values(arrayTabla);
          setFacturas(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // function BuscaColaborador(event) {
  //   setSelectedColaborador(event.target.value)
    
  //   setSubTotal(0)
  //   setIva(0)
  //   setTotal(0)
    
  //   setInputFields(
  //   [{
  //     id: uuidv4(),
  //     facturas: "",
  //     idFactura: "",
  //     total: 0,
  //     abonos: 0,
  //     saldo: 0,
  //     impPagar: 0,
  //   }]
  //   );
  //   if(event.target.value != "" && selectedCliente != "" && selectedEmpresa != ""){
  //     axios
  //     .get(`${URL_FACTURAS}CompPago/${selectedCliente}/${event.target.value}/${selectedEmpresa}`, { 
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         const allFacturas = res.data;
  //         let arrayTabla = allFacturas.map((a)=>{
  //           return {
  //             id: uuidv4(),
  //             facturas: a._id,
  //             idFactura: a.idFactura,
  //             total: a.total,
  //             abonos: a.abonos,
  //             saldo: a.saldo,
  //             impPagar: a.saldo,
  //           }
  //         })
  //         let dataFinal = Object.values(arrayTabla);
  //         setFacturas(dataFinal);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }

  function BuscaFactura(id, event) {
    if (
      inputFields.filter((e) => e.facturas == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El articulo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.facturas = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      setSelectedFactura(event.target.value);
      facturas.map((a) => {
        if (a.facturas == event.target.value) {
          let facturas = a.facturas;
          let idFactura = a.idFactura;
          let total = a.total
          let abonos = a.abonos
          let saldo = a.saldo
          handleChangeInputArticulo(id, facturas, idFactura, total, abonos, saldo);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, facturas, idFactura, total, abonos, saldo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.facturas = facturas;
        i.idFactura = idFactura;
        i.abonos = abonos
        i.saldo = saldo
        i.total = total
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  function ActualizaTotales() {

    let din = inputFields.map((c) => parseFloat(c.impPagar));
    let TD = din.reduce((t, total, index) => t + total, 0);
    setSubTotal(TD);

    let i = TD * 0.16;
    setIva(i);
    let t = TD + i;
    setTotal(t);

  }
  
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.saldo >= event.target.value){
          i.impPagar = parseFloat(event.target.value);
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo!",
            showConfirmButton: false,
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.notasCreditoFiscal ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Nota de Credito</h3>
          <Form onSubmit={saveNota}>
          <Row>
            <Col md={2}>
                <Label>Timbres Disponibles</Label>
                <Input
                  type="text"
                  value={timbresDisponibles}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label>Empresa</Label>
                    <Input
                      name="empresa"
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(event) => {
                        BuscaEmpresa(event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {empresas
                        .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                      .map((a) => {
                        if(a.listoFacturar == "Si" && a.is_active == "Si"){
                          return (
                            <option value={a._id}>{a.name}</option>
                          );}
                      })}
                    </Input>
                  </Col>

                  <Col md={2}>
                <Label>Cliente</Label>
                    <Input
                      type="select"
                      value={selectedCliente}
                      required
                      onChange={(event) => {
                        BuscaCliente(event);
                      }}
                    >
                      <option value="">Selecciona un Cliente</option>
                      {clientes
                        .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>

                  {/* <Col md={2}>
                <Label>Vendedor</Label>
                    <Input
                      type="select"
                      value={selectedColaborador}
                      required
                      onChange={(event) => {
                        BuscaColaborador(event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {colaboradores
                        .sort((a, b) =>
                        a.nombre > b.nombre ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre} {a.apellido} </option>
                          );
                      })}
                    </Input>
                  </Col> */}

                  <Col md={2}>
                <Label>Serie</Label>
                    <Input
                      type="select"
                      value={serie}
                      required
                      onChange={(event) => {
                        setSerie(event.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="MN">MN</option>
                      <option value="MNE">MNE</option>
                      <option value="BN">BN</option>
                      <option value="BNE">BNE</option>
                      <option value="TN">TN</option>
                    </Input>
                  </Col>

                  <Col md={2}>
                <Label>Forma Pago Fiscal</Label>
                <Input
                  type="select"
                  value={formaPagoSATCliente}
                  required
                  onChange={(e) => {
                    setFormaPagoSATCliente(e.target.value);
                  }}
                >
              <option value="">Selecciona</option>
              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col>
             </Row>

             <Row>
             {/* <Col md={2}>
                <Label>Forma Pago</Label>
                <Input
                  type="select"
                  value={formaPagoSAT}
                  required
                  disabled
                >
              <option value="">Selecciona</option>
              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col> */}
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Factura</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Abonos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Saldo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Importe</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="facturas"
                      type="select"
                      value={inputField.facturas}
                      required
                      onChange={(event) => {
                        BuscaFactura(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {facturas
                      .sort((a, b) => (a.idFactura > b.idFactura ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a.facturas}>
                            {a.idFactura}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="abonos"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.abonos}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="saldo"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.saldo}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="impPagar"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.impPagar}
                      onChange={(e) => {
                        handleChangeInput(inputField.id, e);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <br />
            <Row>
            <Col md={2}>
            <Label className="mr-sm-2">Sub Total</Label>
            <Input
              type="number"
              value={subTotal}
              disabled
            />
            </Col><Col md={2}>
            <Label className="mr-sm-2">IVA</Label>
            <Input
              type="number"
              value={iva}
              disabled
            />
            </Col>
            <Col md={2}>
            <Label className="mr-sm-2">Total</Label>
            <Input
              type="number"
              value={total}
              disabled
            />
            </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoNotasCredito"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default NotasCreditoCreate;
