import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
// import * as XLSX from "xlsx";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCompraCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [value, setValue] = useState("");

  const [fecha, setFecha] = useState(hoy);
  const [plazo, setPlazo] = useState(0);
  const [fecha_compromiso, setFechaCompromiso] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [expandedTables, setExpandedTables] = useState({});

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   articulos: "",
    //   cantidad: 0,
    //   precio: 0,
    //   total: 0,
    //   articulosNombre: "",
    //   idColor: "",
    //   colorNombre: "",
    //   idTalla: "",
    //   tallaNombre: "",
    //   colores:[],
    //   tallas:[],
    //   idArticulosProporcion: "",
    // },
  ]);

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      precio: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    try {
      await axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha,
            proveedores: selectedProveedor,
            plazo,
            fecha_compromiso,
            total_general,
            total_cantidad,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (
              a.articulos != "" &&
              a.cantidad != 0 &&
              a.precio != 0 &&
              a.total != 0
            ) {
              axios
                .post(
                  URL_ARTICULOS_OC,
                  {
                    ordenesCompra: data.data._id,
                    articulos: a.articulos,
                    colores: a.idColor,
                    tallas: a.idTalla,
                    cantidad: parseFloat(a.cantidad),
                    embarcado: 0,
                    pendiente_embarcar: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                    articulosProporcion: a.idArticulosProporcion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Orden de Compra",
                          detalle: `${selectedProveedor} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        precio: 0,
        total: 0,
        articulosNombre: "",
        idColor: "",
        colorNombre: "",
        idTalla: "",
        tallaNombre: "",
        idArticulosProporcion: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticuloProporcion(id, selectedArticulo) {
    articulos.map((a) => {
      if (a._id == selectedArticulo._id) {
        let idArticulo = selectedArticulo._id;
        let articulosNombre =
          selectedArticulo.codigo + " " + selectedArticulo.nombre;
        let FOB = a.costoUSD;
        let colores = a.colores;
        let tallas = a.tallas;
        handleChangeInputArticuloProporcion(
          id,
          idArticulo,
          FOB,
          articulosNombre,
          colores,
          tallas
        );
      }
    });
  }

  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    FOB,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = FOB;
        i.articulosNombre = articulosNombre;
        i.cajas = 0;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    articulos.map((a) => {
      if (a._id == selectedArticulo._id) {
        let idArticulo = selectedArticulo._id;
        let articulosNombre =
          selectedArticulo.codigo + " " + selectedArticulo.nombre;
        let FOB = a.costoUSD;
        handleChangeInputArticulo(id, idArticulo, FOB, articulosNombre);
      }
    });
  }

  const handleChangeInputArticulo = (id, idArticulo, FOB, articulosNombre) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.precio = FOB;
        i.articulosNombre = articulosNombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function agregarProporcion(
    idArticulo,
    articulosNombre,
    precio,
    cajas,
    colores,
    tallas
  ) {
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;

        const newInputFields = allArticulos.map((i) => {
          return {
            id: uuidv4(),
            articulos: idArticulo,
            articulosNombre: articulosNombre,
            precio: precio,
            cantidad: cajas * i.cantidad,
            idTalla: i.tallas[0]._id,
            tallaNombre: i.tallas[0].name,
            idColor: i.colores[0]._id,
            colorNombre: i.colores[0].name,
            total: cajas * i.cantidad * precio,
            colores: colores,
            tallas: tallas,
            idArticulosProporcion: i._id,
          };
        });

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          const tallaOrder = [
            "EECH",
            "ECH",
            "CH",
            "M",
            "G",
            "EG",
            "2EG",
            "3EG",
            "4EG",
            "5EG",
            "6EG",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "5/6",
            "7/8",
            "10/12",
            "14/16",
          ];

          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Calculate total quantities and amounts
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );
          const totalGeneral = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.total),
            0
          );

          // Update state with totals
          setTotalCantidad(totalCantidad);
          setTotalGeneral(totalGeneral);

          return sortedInputFields;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo + " " + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Orden de Compra</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_cantidad} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio USD</Label>
              </Col>
            </Row>

            {inputArticulos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticuloProporcion(
                          inputField.id,
                          selectedArticulo
                        );
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      name="articulosNombre"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cajas"
                      value={inputField.cajas}
                      required
                      onChange={(event) =>
                        handleChangeInputProporcion(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={() =>
                        agregarProporcion(
                          inputField.articulos,
                          inputField.articulosNombre,
                          inputField.precio,
                          inputField.cajas,
                          inputField.colores,
                          inputField.tallas
                        )
                      }
                      tabindex="-1"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

            <br />
            <hr />

    

            {Object.entries(
              inputFields
                .filter((field) => field.articulosNombre && field.articulos)
                .reduce((acc, field) => {
                  const key = `${field.articulosNombre}`;
                  if (!acc[key]) {
                    acc[key] = {
                      articuloInfo: {
                        articulosNombre: field.articulosNombre,
                        articulos: field.articulos,
                        availableTallas: Array.from(
                          new Set(
                            inputFields
                              .filter(
                                (f) =>
                                  f.articulosNombre === field.articulosNombre
                              )
                              .map((f) => f.tallaNombre)
                              .filter(Boolean)
                              .sort((a, b) => {
                                const tallaOrder = [
                                  "EECH",
                                  "ECH",
                                  "CH",
                                  "S",
                                  "M",
                                  "G",
                                  "L",
                                  "EG",
                                  "XL",
                                  "2EG",
                                  "XXL",
                                  "3EG",
                                  "4EG",
                                  "5EG",
                                  "6EG",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                  "10",
                                  "11",
                                  "12",
                                  "13",
                                  "14",
                                  "15",
                                  "16",
                                  "17",
                                  "18",
                                  "19",
                                  "20",
                                  "21",
                                  "22",
                                  "23",
                                  "24",
                                  "25",
                                  "26",
                                  "27",
                                  "28",
                                  "29",
                                  "30",
                                  "31",
                                  "32",
                                  "33",
                                  "34",
                                  "35",
                                  "36",
                                  "37",
                                  "38",
                                  "39",
                                  "40",
                                  "41",
                                  "42",
                                  "43",
                                  "44",
                                  "45",
                                  "46",
                                  "47",
                                  "48",
                                  "49",
                                  "50",
                                  "3/4",
                                  "5/6",
                                  "7/8",
                                  "9/10",
                                  "11/12",
                                  "10/12",
                                  "13/14",
                                  "14/16",
                                  "15/16",
                                ];
                                return (
                                  tallaOrder.indexOf(a) - tallaOrder.indexOf(b)
                                );
                              })
                          )
                        ),
                      },
                      colorGroups: [],
                    };
                  }

                  const existingColorIndex = acc[key].colorGroups.findIndex(
                    (item) => item.idColor === field.idColor
                  );

                  if (existingColorIndex === -1) {
                    acc[key].colorGroups.push({
                      id: field.id,
                      articulos: field.articulos,
                      articulosNombre: field.articulosNombre,
                      idColor: field.idColor,
                      colorNombre: field.colorNombre,
                      colores: field.colores,
                      precioOriginal: field.precioOriginal,
                      precio: field.precio,
                      quantities: {
                        [field.tallaNombre]: {
                          cantidad: field.cantidad,
                          id: field.id,
                        },
                      },
                      total: field.cantidad * field.precio,
                    });
                  } else {
                    acc[key].colorGroups[existingColorIndex].quantities[
                      field.tallaNombre
                    ] = {
                      cantidad: field.cantidad,
                      id: field.id,
                    };
                    acc[key].colorGroups[existingColorIndex].total +=
                      field.cantidad * field.precio;
                  }
                  return acc;
                }, {})
            ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
              // Calculate totals for this article
              const articleTotals = colorGroups.reduce(
                (totals, group) => {
                  const quantitySum = Object.values(group.quantities).reduce(
                    (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                    0
                  );
                  const priceSum = Object.values(group.quantities).reduce(
                    (sum, q) =>
                      sum + (parseFloat(q.cantidad) || 0) * group.precio,
                    0
                  );

                  return {
                    items: totals.items + quantitySum,
                    price: totals.price + priceSum,
                    singlePrice: group.precio,
                  };
                },
                { items: 0, price: 0 }
              );

              return (
                <div key={articuloNombre} className="mb-8">
                  <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                    <div className="d-flex align-items-center">
                      <Button
                        size="sm"
                        className="btn mr-3"
                        color="info"
                        onClick={() =>
                          setExpandedTables((prev) => ({
                            ...prev,
                            [articuloNombre]: !prev[articuloNombre],
                          }))
                        }
                      >
                        <i
                          className={`fas fa-chevron-${
                            expandedTables[articuloNombre] ? "up" : "down"
                          }`}
                        ></i>
                      </Button>
                      <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                    </div>
                    {!expandedTables[articuloNombre] && (
                      <div className="d-flex align-items-center">
                        <span
                          className="mr-4 text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Piezas:</strong>{" "}
                          {articleTotals.items}
                        </span>
                        <span
                          className="mr-4 text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Precio:</strong>{" "}
                          $
                          {articleTotals.singlePrice.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                        <span
                          className="text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Total:</strong> $
                          {articleTotals.price.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    )}
                  </div>

                  {expandedTables[articuloNombre] && (
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Articulo</th>
                          <th>Color</th>
                          {articuloInfo.availableTallas.map((talla) => (
                            <th key={talla} style={{ maxWidth: "35px" }}>
                              {talla}
                            </th>
                          ))}
                          <th style={{ maxWidth: "60px" }}>Precio USD</th>
                          <th style={{ maxWidth: "60px" }}>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {colorGroups.map((group) => (
                          <tr key={`${group.id}-${group.idColor}`}>
                            {/* Your existing row content */}
                            <td style={{ maxWidth: "60px" }}>
                              <Input
                                name="articulo"
                                type="string"
                                placeholder="Articulo"
                                value={group.articulosNombre}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "35px" }}>
                              <Input
                                name="idColor"
                                type="select"
                                value={group.idColor}
                                disabled
                              >
                                <option value="">Selecciona</option>
                                {group.colores?.map((a) => (
                                  <option key={a._id} value={a._id}>
                                    {a.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            {articuloInfo.availableTallas.map((talla) => (
                              <td key={talla} style={{ maxWidth: "40px" }}>
                                <Input
                                  name="cantidad"
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={group.quantities[talla]?.cantidad || 0}
                                  onChange={(event) =>
                                    handleChangeInput(
                                      group.quantities[talla]?.id || group.id,
                                      event
                                    )
                                  }
                                />
                              </td>
                            ))}


                            <td style={{ maxWidth: "60px" }}>
                              <Input
                                style={
                                  group.precioOriginal - 50 > group.precio
                                    ? { backgroundColor: "#ed8c8c" }
                                    : null
                                }
                                name="precio"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="Precio"
                                value={group.precio}
                                required
                                onChange={(event) =>
                                  handleChangeInput(group.id, event)
                                }
                              />
                            </td>
                            <td style={{ maxWidth: "80px" }}>
                              <Input
                                name="total"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="total"
                                value={group.total}
                                disabled
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled={inputFields.length === 1}
                                onClick={() => handleRemoveFields(group.id)}
                                tabindex="-1"
                              >
                                <i className="fas fa-minus"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              );
            })}

            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoOrdenesCompra"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCompraCreate;
