import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import AreasCreate from "./AreasCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";

function ListadoAreas() {
  const { user } = useContext(AuthContext);
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [areas, setAreas] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [esTienda, setEsTienda] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editArea(event) {
    event.preventDefault();
    const URL_AREAS_EDIT = `${process.env.REACT_APP_URL_AREAS}/${idEdit}`;
    axios
      .patch(
        URL_AREAS_EDIT,
        {
          name,
          esTienda,
          direccion,
          telefono,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Area',
            detalle: name,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name, esTienda, direccion, telefono) {
        setName(name);
        setEsTienda(esTienda);
        setDireccion(direccion);
        setTelefono(telefono);
        setIdEdit(idEdit);
        toggle();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menu_areas ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Almacenes</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {areas
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(a._id, a.name, a.esTienda, a.direccion, a.telefono)}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    {/* <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_AREAS
                                      }
                                    /> */}
                              
                                   
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <AreasCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Almacen</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
           <Label>Direccin</Label>
            <Input
              type="text"
              placeholder="Direccion"
              value={direccion}
              required
              onChange={(e) => {
                setDireccion(e.target.value);
              }}
            />
            <Label>Telefono</Label>
            <Input
              type="text"
              placeholder="Telefono"
              value={telefono}
              required
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            />
            <Label>Es Tienda?</Label>
            <Input
              type="select"
              value={esTienda}
              onChange={(e) => {
                setEsTienda(e.target.value);
              }}
            >
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArea}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoAreas;
