import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function EmbarquesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_GASTOS_EMBARQUES = process.env.REACT_APP_URL_GASTOS_EMBARQUES;
  const URL_CONCEPTOS_GASTOS_EMBARQUES =
    process.env.REACT_APP_URL_CONCEPTOS_GASTOS_EMBARQUES;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fechaEmbarque, setFechaEmbarque] = useState(hoy);
  const [fechaEstimada, setFechaEstimada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulosOCProporcion, setArticulosOCProporcion] = useState([]);
  const [OC, setOC] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [contenedor, setContenedor] = useState("");
  const [totalCaptura, setTotalCaptura] = useState(1);
  const [proveedores, setProveedores] = useState([]);
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [agrupado, setAgrupado] = useState([]);

  const [conceptosGastos, setConceptosGastos] = useState("");

  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);

  const classes = useStyles();
  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      cantidadOC: 0,
      costo: 0,
      costoTotal: 0,
      proveedorOrden: "",
      OC: "",
    },
  ]);

  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   articulosOrden: "",
    //   articulos: "",
    //   cantidad: 0,
    //   piezasPorCaja: 0,
    //   cajas: 0,
    //   cantidadOC: 0,
    //   costo: 0,
    //   costoTotal: 0,
    //   proveedorOrden: "",
    //   OC:"",
    //   idColor: "",
    //   idTalla: "",
    // },
  ]);

  const [expandedTables, setExpandedTables] = useState({});

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOC = res.data;
        setOC(allOC);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CONCEPTOS_GASTOS_EMBARQUES}Fijos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveEmbarqueNuevo = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará el Embarque!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
      if (
        result.isConfirmed &&
        fechaEmbarque != "" &&
        fechaEstimada != "" &&
        contenedor != "" &&
        articulosOC != "" &&
        observaciones != ""
      ) {
        setValidaBoton(false);
        toggleProgreso();

        let totalArticulos =
          inputFields.length + inputFields.length + conceptosGastos.length;
        let TCosto = inputFields.map((c) => parseFloat(c.costoTotal));
        let TTC = TCosto.reduce((t, total, index) => t + total, 0);
        try {
          await axios
            .post(
              URL_EMBARQUES,
              {
                fechaEmbarque,
                fechaEstimada,
                observaciones,
                contenedor,
                total_cantidad,
                recibido: "No",
                total_costo: parseFloat(TTC).toFixed(2),
                saldo: parseFloat(TTC).toFixed(2),
                creado: user.id,
                total_gasto: 0,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((data) => {
              agrupado.map((b) => {
                axios
                  .post(
                    `${URL_NOTAS_CXP}`,
                    {
                      // proveedores: "647a1dc3b5b71896bef71f98",
                      proveedores: b.proveedorOrden,
                      embarques: data.data._id,
                      total_cantidad: b.cantidad,
                      total_costo: parseFloat(b.costoTotal).toFixed(2),
                      saldo: parseFloat(b.costoTotal).toFixed(2),
                      calidad: "No",
                      fecha: fechaEmbarque,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data2) => {
                    inputFields.map((a) => {
                      if (
                        a.articulos != "" &&
                        a.cantidad != 0 &&
                        a.proveedorOrden == b.proveedorOrden
                      ) {
                        axios
                          .post(
                            `${URL_ARTICULOS_EMBARQUES}`,
                            {
                              articulosOC: a.articulosOrden,
                              articulos: a.articulos,
                              articulosProporcion: a.idArticulosProporcion,
                              cantidad: a.cantidad,
                              colores: a.idColor,
                              tallas: a.idTalla,
                              recibido: 0,
                              pendiente_recibir: a.cantidad,
                              embarques: data.data._id,
                              notasCxP: data2.data._id,
                              costo: a.costo,
                              total_costo: a.costoTotal,
                              // proveedores: "647a1dc3b5b71896bef71f98",
                              proveedores: b.proveedorOrden,
                              pesoCaja: 0,
                              faltante: 0,
                              danado: 0,
                              impuestos: 0,
                              revisarFaltante: 10,
                              revisarDanado: 10,
                              pedirFaltante: 0,
                              pedirDanado: 10,
                              faltanteTotal: 0,
                              danadoTotal: 0,
                              descuentoFaltante: 0,
                              descuentoDanado: 0,
                              descuentoTotal: 0,
                              familias: "NA",
                              sup: "NA",
                              observaciones: "NA",
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            totalArticulos = totalArticulos - 1;
                            if (totalArticulos == 0) {
                              axios
                                .post(
                                  URL_LOGS,
                                  {
                                    tipo: "Crear Embarque",
                                    detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                                    user: user.id,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                )
                                .then(() => {
                                  Swal.fire(
                                    "Good job!",
                                    "Creado con exito",
                                    "success"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 1000);
                                })
                                .catch((error) => {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    footer: `${error.response.data}`,
                                  });
                                  console.log(error);
                                  setValidaBoton(true);
                                });
                            }
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      } else {
                        totalArticulos = totalArticulos - 1;
                        if (totalArticulos == 0) {
                          axios
                            .post(
                              URL_LOGS,
                              {
                                tipo: "Crear Embarque",
                                detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              Swal.fire(
                                "Good job!",
                                "Creado con exito",
                                "success"
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                footer: `${error.response.data}`,
                              });
                              console.log(error);
                              setValidaBoton(true);
                            });
                        }
                      }
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });

                // Acaba el primer map
              });

              inputFields.map((w) => {
                axios
                  .patch(
                    `${URL_ARTICULOS_OC}editEmbarcado/${w.articulosOrden}`,
                    {
                      embarcado: w.cantidad,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Embarque",
                            detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              });

              // Acaba el segundo map

              conceptosGastos.map((w) => {
                axios
                  .post(
                    URL_GASTOS_EMBARQUES,
                    {
                      conceptosGastosEmbarques: w._id,
                      estimado: w.estimado,
                      real: w.estimado,
                      embarques: data.data._id,
                      aplicado: "No",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Embarque",
                            detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              });
            })
            // Catch primer then
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data.message}`,
              });
              console.log(error);
              setValidaBoton(true);
              setModalProgreso(false);
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `Faltan Datos`,
        });
        setValidaBoton(true);
      }
    });
  };

  function BuscaProveedorProporcion(id, event) {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.proveedorOrden = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  }

  function BuscaOCProporcion(id, event) {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.OC = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);

    axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${event.target.value}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC([...articulosOC, ...allArticulosOC]);

        let arrayTabla = allArticulosOC
          .map((a) => {
            return {
              id: a._id,
              articulosCodigo: a.articulos[0].codigo,
              articulosNombre: a.articulos[0].nombre,
              idArticulo: a.articulos[0]._id,
              idOrdenCompra: a.ordenesCompra[0]._id,
              cantidad: a.cantidad,
              precio: a.precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].idArticulo = item.idArticulo;
          groups[val].articulosCodigo = item.articulosCodigo;
          groups[val].articulosNombre = item.articulosNombre;
          groups[val].idOrdenCompra = item.idOrdenCompra;
          groups[val].id = item.id;
          groups[val].precio = item.precio;
          return groups;
        }, []);

        let dataAgrupado = Object.values(agrupado);

        setArticulosOCProporcion(dataAgrupado);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function BuscaOrdenProporcion(id, event) {
    articulosOCProporcion.map((a) => {
      if (a.id == event.target.value) {
        let articulosOrden = a.id;
        let cantidadOC = a.cantidad;
        let costo = a.precio;
        let articulo = a.idArticulo;
        handleChangeInputOCProporcion(
          id,
          articulosOrden,
          cantidadOC,
          costo,
          articulo
        );
      }
    });
  }

  const handleChangeInputOCProporcion = (
    id,
    articulosOrden,
    cantidadOC,
    costo,
    articulo
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden;
        i.cantidadOC = cantidadOC;
        i.costo = costo;
        i.costoTotal = parseFloat(costo) * parseFloat(i.cantidad);
        i.articulos = articulo;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["proveedorOrden"];
      groups[val] = groups[val] || {
        proveedorOrden: item.proveedorOrden,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      return groups;
    }, []);
    let temp = Object.values(agrupado);
    setAgrupado(temp);
  }

  // Verificar cantidad

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function AgregarProporcion(idProveedor, idOrden, idArticulo) {
    let nuevosCampos = [];

    articulosOC.forEach((a) => {
      if (a.articulos[0]._id == idArticulo) {
        let articulosOrden = a._id;
        let cantidadOC = a.cantidad;
        let costo = a.precio;
        let articulo = a.articulos[0]._id;
        let color = a.colores[0]._id;
        let talla = a.tallas[0]._id;
        let idArticulosProporcion = a.articulosProporcion[0]._id;
        let tallaNombre = a.tallas[0].name;

        nuevosCampos.push({
          id: uuidv4(),
          articulosOrden: articulosOrden,
          articulosNombre: a.articulos[0].codigo + " " + a.articulos[0].nombre,
          ocNombre: a.ordenesCompra[0].idOrdenCompra,
          proveedorNombre: a.ordenesCompra[0].proveedores[0].nombre_comercial,
          cantidadOC: cantidadOC,
          costo: costo,
          costoTotal: parseFloat(costo) * parseFloat(cantidadOC),
          cantidad: cantidadOC,
          articulos: a.articulos[0]._id,
          artArr: a.articulos[0],
          proveedorOrden: idProveedor,
          OC: idOrden,
          idColor: color,
          idTalla: talla,
          tallaNombre: tallaNombre,
          idArticulosProporcion: idArticulosProporcion,
        });
      }
    });

    setInputFields((prevFields) => {
      const updatedInputFields = [...prevFields, ...nuevosCampos];

      const totalCantidad = updatedInputFields.reduce(
        (acc, curr) => acc + parseFloat(curr.cantidad),
        0
      );
      setTotalCantidad(totalCantidad);

      let agrupado = updatedInputFields.reduce(function (groups, item) {
        const val = item["proveedorOrden"];
        groups[val] = groups[val] || {
          proveedorOrden: item.proveedorOrden,
          costoTotal: 0,
          cantidad: 0,
        };
        groups[val].proveedorOrden = item.proveedorOrden;
        groups[val].costoTotal += item.costoTotal;
        groups[val].cantidad += item.cantidad;
        return groups;
      }, []);
      let temp = Object.values(agrupado);
      setAgrupado(temp);

      return updatedInputFields;
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Embarque</h3>
          <Form onSubmit={saveEmbarqueNuevo}>
            <Row>
              <Col md={2}>
                <Label>Fecha Embarque</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEmbarque}
                  required
                  onChange={(e) => {
                    setFechaEmbarque(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label># Contenedor</Label>
                <Input
                  type="text"
                  placeholder="contenedor"
                  value={contenedor}
                  required
                  onChange={(e) => {
                    setContenedor(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Estimada de Arribo</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEstimada}
                  required
                  onChange={(e) => {
                    setFechaEstimada(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">OC</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad OC</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">FOB</Label>
              </Col>
            </Row>

            {inputArticulos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="proveedor"
                      type="select"
                      value={inputField.proveedorOrden}
                      required
                      onChange={(event) => {
                        BuscaProveedorProporcion(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Proveedor</option>
                      {proveedores
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="OC"
                      type="select"
                      value={inputField.OC}
                      required
                      onChange={(event) => {
                        BuscaOCProporcion(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona </option>
                      {OC.sort((a, b) =>
                        a.idOrdenCompra > b.idOrdenCompra ? 1 : -1
                      ).map((a) => {
                        if (
                          a.autorizado == "Si" &&
                          a.status == "Abierto" &&
                          a.proveedores[0]._id == inputField.proveedorOrden
                        ) {
                          return (
                            <option value={a._id}>{a.idOrdenCompra}</option>
                          );
                        }
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="orden"
                      type="select"
                      value={inputField.articulosOrden}
                      required
                      onChange={(event) => {
                        BuscaOrdenProporcion(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona una OC</option>
                      {articulosOCProporcion
                        .sort((a, b) =>
                          a.articulosCodigo > b.articulosCodigo ? 1 : -1
                        )
                        .map((a) => {
                          if (a.idOrdenCompra == inputField.OC)
                            return (
                              <option value={a.id}>
                                {a.articulosCodigo} Cant {a.cantidad}
                              </option>
                            );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadOC"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantidadOC}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="FOB"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.costo}
                      disabled
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={() =>
                        AgregarProporcion(
                          inputField.proveedorOrden,
                          inputField.OC,
                          inputField.articulos
                        )
                      }
                      tabindex="-1"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

            <hr />

            {Object.entries(
              inputFields
                .filter((field) => field.articulosNombre && field.articulos)
                .reduce((acc, field) => {
                  const key = `${field.articulosNombre}`;
                  if (!acc[key]) {
                    acc[key] = {
                      articuloInfo: {
                        articulosNombre: field.articulosNombre,
                        articulos: field.articulos,
                        availableTallas: Array.from(
                          new Set(
                            inputFields
                              .filter(
                                (f) =>
                                  f.articulosNombre === field.articulosNombre
                              )
                              .map((f) => f.tallaNombre)
                              .filter(Boolean)
                              .sort((a, b) => {
                                const tallaOrder = [
                                  "EECH",
                                  "ECH",
                                  "CH",
                                  "S",
                                  "M",
                                  "G",
                                  "L",
                                  "EG",
                                  "XL",
                                  "2EG",
                                  "XXL",
                                  "3EG",
                                  "4EG",
                                  "5EG",
                                  "6EG",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                  "10",
                                  "11",
                                  "12",
                                  "13",
                                  "14",
                                  "15",
                                  "16",
                                  "17",
                                  "18",
                                  "19",
                                  "20",
                                  "21",
                                  "22",
                                  "23",
                                  "24",
                                  "25",
                                  "26",
                                  "27",
                                  "28",
                                  "29",
                                  "30",
                                  "31",
                                  "32",
                                  "33",
                                  "34",
                                  "35",
                                  "36",
                                  "37",
                                  "38",
                                  "39",
                                  "40",
                                  "41",
                                  "42",
                                  "43",
                                  "44",
                                  "45",
                                  "46",
                                  "47",
                                  "48",
                                  "49",
                                  "50",
                                  "3/4",
                                  "5/6",
                                  "7/8",
                                  "9/10",
                                  "11/12",
                                  "10/12",
                                  "13/14",
                                  "14/16",
                                  "15/16",
                                ];
                                return (
                                  tallaOrder.indexOf(a) - tallaOrder.indexOf(b)
                                );
                              })
                          )
                        ),
                      },
                      colorGroups: [],
                    };
                  }

                  const existingColorIndex = acc[key].colorGroups.findIndex(
                    (item) => item.idColor === field.idColor
                  );

                  if (existingColorIndex === -1) {
                    acc[key].colorGroups.push({
                      id: field.id,
                      articulos: field.articulos,
                      articulosNombre: field.articulosNombre,
                      articulosOrden: field.articulosOrden,
                      idColor: field.idColor,
                      ocNombre: field.ocNombre,
                      proveedorNombre: field.proveedorNombre,
                      idTalla: field.idTalla,
                      proveedorOrden: field.proveedorOrden,
                      colorNombre: field.colorNombre,
                      colores: field.artArr.colores,
                      FOB: field.costo,
                      cantidadOc: field.cantidadOC,
                      OC: field.OC,
                      quantities: {
                        [field.tallaNombre]: {
                          cantidad: field.cantidad,
                          id: field.id,
                          cantidadOC: field.cantidadOC,
                        },
                      },
                      total: field.cantidad,
                    });
                  } else {
                    acc[key].colorGroups[existingColorIndex].quantities[
                      field.tallaNombre
                    ] = {
                      cantidad: field.cantidad,
                      cantidadOC: field.cantidadOC,
                      id: field.id,
                    };
                  }
                  return acc;
                }, {})
            ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
              // Calculate totals for each color group (OC)
              colorGroups.forEach((group) => {
                group.totalOC = Object.values(group.quantities).reduce(
                  (sum, q) => sum + (parseFloat(q.cantidadOC) || 0),
                  0
                );
              });

              // Calculate totals for each color group
              colorGroups.forEach((group) => {
                group.total = Object.values(group.quantities).reduce(
                  (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                  0
                );
              });

              // Calculate totals price based on the costo * cantidad

              colorGroups.forEach((group) => {
                group.totalFOB = Object.values(group.quantities).reduce(
                  (sum, q) => sum + (parseFloat(q.cantidad) || 0) * group.FOB,
                  0
                );
              });


              // Calculate totals for this article
              const articleTotals = colorGroups.reduce(
                (totals, group) => {
                  const quantitySum = Object.values(group.quantities).reduce(
                    (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                    0
                  );
              


                  return {
                    items: totals.items + quantitySum,
                  };
                },
                { items: 0 }
              );

              return (
                <div key={articuloNombre} className="mb-8">
                  <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                    <div className="d-flex align-items-center">
                      <Button
                        size="sm"
                        className="btn mr-3"
                        color="info"
                        onClick={() =>
                          setExpandedTables((prev) => ({
                            ...prev,
                            [articuloNombre]: !prev[articuloNombre],
                          }))
                        }
                      >
                        <i
                          className={`fas fa-chevron-${
                            expandedTables[articuloNombre] ? "up" : "down"
                          }`}
                        ></i>
                      </Button>
                      <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                    </div>
                    {!expandedTables[articuloNombre] && (
                      <div className="d-flex align-items-center">
                        <span
                          className="mr-4 text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Piezas:</strong>{" "}
                          {articleTotals.items}
                        </span>
                      </div>
                    )}
                  </div>

                  {expandedTables[articuloNombre] && (
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Proveedor</th>
                          <th>OC</th>
                          <th>Articulo</th>
                          <th>Color</th>
                          <th style={{maxWidth: "40px"}}>Cantidad OC</th>
                          {articuloInfo.availableTallas.map((talla) => (
                            <th key={talla} style={{ maxWidth: "35px" }}>
                              {talla}
                            </th>
                          ))}
                          <th style={{maxWidth: "40px"}}>Cantidad Total</th>
                          <th>FOB</th>
                          <th style={{maxWidth: "45px"}}>Total FOB</th>
                        </tr>
                      </thead>
                      <tbody>
                        {colorGroups.map((group) => (
                          <tr key={`${group.id}-${group.idColor}`}>
                            <td style={{ maxWidth: "50px" }}>
                              <Input
                                name="articulo"
                                type="string"
                                placeholder="Articulo"
                                value={group.proveedorNombre}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "45px" }}>
                              <Input
                                name="articulo"
                                type="string"
                                placeholder="Articulo"
                                value={group.ocNombre}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "60px" }}>
                              <Input
                                name="articulo"
                                type="string"
                                placeholder="Articulo"
                                value={group.articulosNombre}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "35px" }}>
                              <Input
                                name="idColor"
                                type="select"
                                value={group.idColor}
                                disabled
                              >
                                <option value="">Selecciona</option>
                                {colores.map((a) => (
                                  <option key={a._id} value={a._id}>
                                    {a.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            <td style={{ maxWidth: "40px" }}>
                              <Input
                                name="cantidadOC"
                                type="number"
                                min="0"
                                step="any"
                                value={group.totalOC}
                                disabled
                              />
                            </td>
                            {articuloInfo.availableTallas.map((talla) => (
                              <td key={talla} style={{ maxWidth: "40px" }}>
                                <Input
                                  name="cantidad"
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={group.quantities[talla]?.cantidad || 0}
                                  onChange={(event) =>
                                    handleChangeInputCantidad(
                                      group.quantities[talla]?.id || group.id,
                                      event
                                    )
                                  }
                                />
                              </td>
                            ))}
                            <td style={{ maxWidth: "40px" }}>
                              <Input
                                name="total"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="total"
                                value={group.total}
                                disabled
                              />
                            </td>                    
                            <td style={{ maxWidth: "40px" }}>
                              <Input
                                name="FOB"
                                type="string"
                                min="0"
                                step="any"
                                value={Intl.NumberFormat("en-US").format(group.FOB)}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "40px" }}>
                              <Input
                                name="totalFOB"
                                type="string"
                                min="0"
                                step="any"
                                value={new Intl.NumberFormat("en-US").format(group.totalFOB)}
                                disabled
                              />
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              );
            })}

            {/* Tabla Articulos */}

          
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoEmbarques"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EmbarquesCreate;
