import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;

  const [articulos, setArticulos] = useState("");
  const [activo, setActivo] = useState("");
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [piezasPorCaja, setPiezasPorCaja] = useState("");
  const [costoUSD, setCostoUSD] = useState("");
  const [costoMX, setCostoMX] = useState("");
  const [utilidadP, SetUtilidadP] = useState();
  const [utilidadM, setUtilidadM] = useState();
  const [lista1, setLista1] = useState("");
  const [lista2, setLista2] = useState("");
  const [lista3, setLista3] = useState("");
  const [lista4, setLista4] = useState("");
  const [venta, setVenta] = useState("");
  const [codigoSAT, setCodigoSAT] = useState("");
  const [codigoUnidad, setCodigoUnidad] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [ano, setAno] = useState("");
  const [EAN, setEAN] = useState("");
  const [selectedCodigo, setSelectedCodigo] = useState(0);
  const [selectedNombre, setSelectedNombre] = useState(0);
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [selectedMarca, setSelectedMarca] = useState(0);
  const [selectedFamilia, setSelectedFamilia] = useState(0);
  const [selectedColeccion, setSelectedColeccion] = useState(0);
  const [selectedProveedor, setSelectedProveedor] = useState(0);
  const [selectedAno, setSelectedAno] = useState(0);
  const [precioEsp, setPrecioEsp] = useState("");

  const [selectedLineaEdit, setSelectedLineaEdit] = useState("");
  const [selectedMarcaEdit, setSelectedMarcaEdit] = useState("");
  const [selectedFamiliaEdit, setSelectedFamiliaEdit] = useState("");
  const [selectedColeccionEdit, setSelectedColeccionEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProporcion, setModalProporcion] = useState(false);
  const toggleProporcion = () => setModalProporcion(!modalProporcion);
  const [amounts, setAmounts] = useState([]);
  const [arrayColores, setArrayColores] = useState([]);
  const [arrayColoresNew, setArrayColoresNew] = useState([]);
  const [arrayTallasNew, setArrayTallasNew] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  const [arrayTallas, setArrayTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedTalla, setSelectedTalla] = useState("");

  const [inputFields, setInputFields] = useState([]);

  const [file, setFile] = useState(null);
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [validaBoton, setValidaBoton] = useState(true);

  const [commentsTotal, setCommentsTotal] = useState([]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [lastChanged, setLastChanged] = useState(null);

  const ITEMS_PER_PAGE = 50;

  // Single useEffect that handles both cases
  useEffect(() => {
    // Check which value changed and update accordingly
    if (lastChanged === "utilidadP") {
      // Calculate based on utilidadP
      const newUtilidadM = parseFloat(costoMX) * (utilidadP / 100);
      setUtilidadM(newUtilidadM);

      // Calculate venta price
      const newVenta = parseFloat(costoMX) + newUtilidadM;
      setVenta(newVenta.toFixed(2));
      setLista1(newVenta.toFixed(2));
      setLista2(newVenta.toFixed(2));
      setLista3(newVenta.toFixed(2));
      setLista4(newVenta.toFixed(2));
    } else if (lastChanged === "utilidadM") {
      // Calculate based on utilidadM
      const newUtilidadP = ((utilidadM / costoMX) * 100).toFixed(2);
      SetUtilidadP(newUtilidadP);

      // Calculate venta price
      const newVenta = parseFloat(costoMX) + utilidadM;
      setVenta(newVenta.toFixed(2));
      setLista1(newVenta.toFixed(2));
      setLista2(newVenta.toFixed(2));
      setLista3(newVenta.toFixed(2));
      setLista4(newVenta.toFixed(2));
    }
  }, [costoMX, utilidadP, utilidadM, lastChanged]);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              codigo: a.codigo,
              nombre: a.nombre,
              piezasPorCaja: a.piezasPorCaja,
              costoUSD: a.costoUSD,
              costoMX: a.costoMX,
              venta: a.venta,
              utilidadP: a.utilidadP,
              utilidadM: a.utilidadM,
              lista1: a.lista1,
              lista2: a.lista2,
              lista3: a.lista3,
              lista4: a.lista4,
              observaciones: a.observaciones,
              linea: a.lineas[0].name,
              marca: a.marcas[0].name,
              familia: a.familias[0].name,
              coleccion: a.colecciones[0].name,
              proveedor: a.proveedores[0].nombre_comercial,
              idLinea: a.lineas[0]._id,
              idMarca: a.marcas[0]._id,
              idFamilia: a.familias[0]._id,
              idColeccion: a.colecciones[0]._id,
              idProveedor: a.proveedores[0]._id,
              ano: a.ano,
              EAN: a.EAN,
              precioEsp: a.precioEsp,
              colores: a.colores,
              tallas: a.tallas,
              codigoSAT: a.codigoSAT,
              codigoUnidad: a.codigoUnidad,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setArticulos(allArticulos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedLinea === 0 || selectedLinea === a.idLinea) &&
        (selectedMarca === 0 || selectedMarca === a.idMarca) &&
        (selectedFamilia === 0 || selectedFamilia === a.idFamilia) &&
        (selectedColeccion === 0 || selectedColeccion === a.idColeccion) &&
        (selectedProveedor === 0 || selectedProveedor === a.idProveedor) &&
        (selectedAno === 0 || selectedAno === a.ano) &&
        (activo === 0 || activo === a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.costoMX),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.venta),
          a.linea,
          a.marca,
          a.familia,
          a.coleccion,
          a.proveedor,
          a.ano,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Modelos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Proveedor",
          "Año",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Modelos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedLinea === 0 || selectedLinea === a.idLinea) &&
        (selectedMarca === 0 || selectedMarca === a.idMarca) &&
        (selectedFamilia === 0 || selectedFamilia === a.idFamilia) &&
        (selectedColeccion === 0 || selectedColeccion === a.idColeccion) &&
        (selectedProveedor === 0 || selectedProveedor === a.idProveedor) &&
        (selectedAno === 0 || selectedAno === a.ano) &&
        a.activo === "Si"
      ) {
        return {
          ID: a._id,
          Codigo: a.codigo,
          Nombre: a.nombre,
          PiezasPorCaja: a.piezasPorCaja,
          FOBUSD: a.costoUSD,
          CostoMX: a.costoMX,
          Venta: a.venta,
          Linea: a.linea,
          Marca: a.marca,
          Familia: a.familia,
          Coleccion: a.coleccion,
          Proveedor: a.proveedor,
          Año: a.ano,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoModelos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoModelos",
        sheetFilter: [
          "ID",
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Proveedor",
          "Año",
        ],
        sheetHeader: [
          "ID",
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Proveedor",
          "Año",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedLinea === 0 || selectedLinea === a.idLinea) &&
        (selectedMarca === 0 || selectedMarca === a.idMarca) &&
        (selectedFamilia === 0 || selectedFamilia === a.idFamilia) &&
        (selectedColeccion === 0 || selectedColeccion === a.idColeccion) &&
        (selectedProveedor === 0 || selectedProveedor === a.idProveedor) &&
        (selectedAno === 0 || selectedAno === a.ano) &&
        (activo === 0 || activo === a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.costoMX),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.venta),
          a.linea,
          a.marca,
          a.familia,
          a.coleccion,
          a.proveedor,
          a.ano,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Modelos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Proveedor",
          "Año",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Modelos",
          email: mailTo,
          fileName: "ListadoModelos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Modelos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEditArticulo(idEdit);
    toggleFoto();
  }

  function jalaInfo(
    codigo,
    nombre,
    piezasPorCaja,
    costoUSD,
    costoMX,
    venta,
    utilidadP,
    utilidadM,
    lista1,
    lista2,
    lista3,
    lista4,
    observaciones,
    idLinea,
    idMarca,
    idFamilia,
    idColeccion,
    idProveedor,
    ano,
    EAN,
    precioEsp,
    codigoSAT,
    codigoUnidad,
    idEdit
  ) {
    setCodigo(codigo);
    setNombre(nombre);
    setPiezasPorCaja(piezasPorCaja);
    setCostoUSD(costoUSD);
    setCostoMX(costoMX);
    setVenta(venta);
    SetUtilidadP(utilidadP);
    setUtilidadM(utilidadM);
    setLista1(lista1);
    setLista2(lista2);
    setLista3(lista3);
    setLista4(lista4);
    setObservaciones(observaciones);
    setSelectedLineaEdit(idLinea);
    setSelectedMarcaEdit(idMarca);
    setSelectedFamiliaEdit(idFamilia);
    setSelectedColeccionEdit(idColeccion);
    setSelectedProveedorEdit(idProveedor);
    setAno(ano);
    setEAN(EAN);
    setPrecioEsp(precioEsp);
    setIdEditArticulo(idEdit);
    setCodigoSAT(codigoSAT);
    setCodigoUnidad(codigoUnidad);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {
          axios.patch(
            URL_ARTICULOS_EDIT,
            {
              codigo,
              nombre,
              piezasPorCaja,
              costoUSD,
              costoMX,
              utilidadP,
              utilidadM,
              lista1,
              lista2,
              lista3,
              lista4,
              venta,
              lineas: selectedLineaEdit,
              marcas: selectedMarcaEdit,
              familias: selectedFamiliaEdit,
              colecciones: selectedColeccionEdit,
              proveedores: selectedProveedorEdit,
              ano,
              EAN,
              precioEsp,
              codigoSAT,
              codigoUnidad
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Articulo",
              detalle: `${nombre} ${codigo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {

          axios.patch(
            URL_ARTICULOS_EDIT,
            {
              codigo,
              nombre,
              piezasPorCaja,
              costoUSD,
              costoMX,
              venta,
              utilidadP,
              utilidadM,
              lista1,
              lista2,
              lista3,
              lista4,
              lineas: selectedLineaEdit,
              marcas: selectedMarcaEdit,
              familias: selectedFamiliaEdit,
              colecciones: selectedColeccionEdit,
              proveedores: selectedProveedorEdit,
              ano,
              EAN,
              precioEsp,
              codigoSAT,
              codigoUnidad
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idEditArticulo);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Articulo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }
  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Pzas Caja", field: "piezasPorCaja", sortable: true },
    // { name: "FOB USD", field: "costoUSD", sortable: true },
    // { name: "Costo MX", field: "costoMX", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Familia", field: "familia", sortable: true },
    { name: "Linea", field: "linea", sortable: true },
    { name: "Marca", field: "marca", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Año", field: "ano", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment._id.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigoSAT.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigoUnidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.piezasPorCaja.toString().includes(search) ||
          comment.costoUSD.toString().includes(search) ||
          comment.costoMX.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.ano.toString().includes(search)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedNombre)
      );
    }
    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }
    if (selectedMarca) {
      computedComments = computedComments.filter((e) =>
        e.idMarca.includes(selectedMarca)
      );
    }
    if (selectedFamilia) {
      computedComments = computedComments.filter((e) =>
        e.idFamilia.includes(selectedFamilia)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }
    if (selectedAno) {
      computedComments = computedComments.filter((e) =>
        e.ano.includes(selectedAno)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "piezasPorCaja" &&
      sorting.field != "costoUSD" &&
      sorting.field != "costoMX" &&
      sorting.field != "venta"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field === "piezasPorCaja" ||
        sorting.field === "costoUSD" ||
        sorting.field === "costoMX" ||
        sorting.field === "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field === "piezasPorCaja" ||
        sorting.field === "costoUSD" ||
        sorting.field === "costoMX" ||
        sorting.field === "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    setCommentsTotal(computedComments);
    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCodigo,
    selectedNombre,
    selectedLinea,
    selectedMarca,
    selectedFamilia,
    selectedColeccion,
    selectedProveedor,
    selectedAno,
    activo,
  ]);

  async function QRGen(
    idArt,
    codigo,
    nombre,
    piezasPorCaja,
    linea,
    marca,
    familia,
    coleccion,
    ano,
    composicion,
    venta
  ) {
    await axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArt}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPorporcion = res.data.filter(
          (porcion) => porcion.cantidad !== "0"
        );
        console.log(res.data);
        const doc = new jsPDF("landscape", "mm", [100, 60], true);

        const tallas = Array.from(
          new Set(
            allPorporcion.flatMap((porcion) =>
              porcion.tallas.map((talla) => talla.name)
            )
          )
        );

        const customSizeOrder = [
          "EECH",
          "ECH",
          "CH",
          "M",
          "G",
          "EG",
          "2EG",
          "3EG",
          "4EG",
          "5EG",
          "6EG",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "5/6",
          "7/8",
          "10/12",
          "14/16",
        ]; // Add other sizes as needed
        const sortedTallas = tallas.sort((a, b) => {
          return customSizeOrder.indexOf(a) - customSizeOrder.indexOf(b);
        });

        // Define columns (tallas)
        const columns = ["", ...sortedTallas];

        // Group data by color
        const groupedData = {};
        allPorporcion.forEach((porcion) => {
          const colorName = porcion.colores[0].name;
          if (!groupedData[colorName]) {
            groupedData[colorName] = {};
          }
          groupedData[colorName][porcion.tallas[0].name] =
            porcion.cantidad || 0;
        });

        // Define rows (colores, cantidad)
        const rows = Object.keys(groupedData).map((colorName) => {
          const rowData = [colorName];
          tallas.forEach((talla) => {
            rowData.push(groupedData[colorName][talla] || 0);
          });
          return rowData;
        });

        var base64 = qrcode.toDataURL(idArt);
        var img3 = new Image();
        img3.src = base64;

        doc.setFontSize(8).setFont(undefined, "bold");
        doc.text(`MODELO: ${codigo}`, 5, 5);
        doc.setFontSize(6);
        doc.text(`Piezas X Caja: ${piezasPorCaja}`, 45, 5);

        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 7,
          margin: { left: 0.5, right: 0.5 },
          // theme: "grid",
          headStyles: {
            fillColor: false,
            textColor: [0, 0, 0],
            fontStyle: "bold",
            lineWidth: 0,
          },
          styles: {
            fontStyle: "bold",
            fontSize: 5,
            cellPadding: 0.7,
            lineWidth: 0.3,
            lineColor: [0, 0, 0],
            textColor: [0, 0, 0],
          },
        });

        doc.addPage();
        doc.addImage(img3, "png", 30, 5, 40, 40);

        doc.save(`QR.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function QRGenSingle(
    idArt,
    codigo,
    nombre,
    piezasPorCaja,
    linea,
    marca,
    familia,
    coleccion,
    ano,
    composicion,
    venta
  ) {
    try {
      const response = await axios.get(
        `${URL_ARTICULOS_PROPORCION}Articulos/${idArt}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );

      const allPorporcion = response.data.filter(
        (porcion) => porcion.cantidad !== "0"
      );

      // Create new PDF document
      const doc = new jsPDF("landscape", "mm", [100, 60], true);

      // Define custom size order
      const customSizeOrder = [
        "EECH",
        "ECH",
        "CH",
        "S",
        "M",
        "G",
        "L",
        "EG",
        "XL",
        "2EG",
        "XXL",
        "3EG",
        "4EG",
        "5EG",
        "6EG",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "3/4",
        "5/6",
        "7/8",
        "9/10",
        "11/12",
        "10/12",
        "13/14",
        "14/16",
        "15/16",
      ];

      // Get unique tallas
      const tallas = Array.from(
        new Set(
          allPorporcion.flatMap((porcion) =>
            porcion.tallas.map((talla) => talla.name)
          )
        )
      );

      // Sort tallas according to custom order
      const sortedTallas = tallas.sort((a, b) => {
        return customSizeOrder.indexOf(a) - customSizeOrder.indexOf(b);
      });

      // Define columns
      const columns = ["", ...sortedTallas];

      // Group data by color
      const groupedData = {};
      allPorporcion.forEach((porcion) => {
        const colorName = porcion.colores[0].name;
        if (!groupedData[colorName]) {
          groupedData[colorName] = {};
        }
        groupedData[colorName][porcion.tallas[0].name] = porcion.cantidad || 0;
      });

      // Create rows from grouped data
      const rows = Object.keys(groupedData).map((colorName) => {
        const rowData = [colorName];
        tallas.forEach((talla) => {
          rowData.push(groupedData[colorName][talla] || 0);
        });
        return rowData;
      });

      // Generate first page with table
      doc.setFontSize(8).setFont(undefined, "bold");
      doc.text(`MODELO: ${codigo}`, 5, 5);
      doc.setFontSize(6);
      doc.text(`Piezas X Caja: ${piezasPorCaja}`, 45, 5);

      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 7,
        margin: { left: 0.5, right: 0.5 },
        headStyles: {
          fillColor: false,
          textColor: [0, 0, 0],
          fontStyle: "bold",
          lineWidth: 0,
        },
        styles: {
          fontStyle: "bold",
          fontSize: 5,
          cellPadding: 0.7,
          lineWidth: 0.3,
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
        },
      });

      // Generate QR code pages for each porcion
      for (const porcion of allPorporcion) {
        const qrData = idArt;

        for (let i = 0; i < porcion.cantidad; i++) {
          // Add new page for each QR code
          doc.addPage();

          // Generate QR code
          const qrCodeBase64 = await qrcode.toDataURL(JSON.stringify(qrData));
          const qrImage = new Image();
          qrImage.src = qrCodeBase64;

          // Add QR code to page
          doc.addImage(qrImage, "png", 30, 5, 40, 40);

          // Add text information below QR code
          doc.setFontSize(8);
          doc.text(`Modelo: ${codigo}`, 5, 50);
          doc.text(`Color: ${porcion.colores[0].name}`, 5, 55);
          doc.text(`Talla: ${porcion.tallas[0].name}`, 40, 50);
        }
      }

      // Save the PDF
      doc.save(`${codigo}_QR.pdf`);
    } catch (error) {
      console.error("Error generating QR codes:", error);
      throw error;
    }
  }

  function jalaProporcion(idEdit, codigo, nombre, tallas) {
    setIdEditArticulo(idEdit);
    setCodigo(codigo);
    setNombre(nombre);

    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idEdit}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProporcion = res.data;
        let arrayTabla = allProporcion
          .map((i) => {
            return {
              _id: i._id,
              size: i.tallas[0].name,
              sizeId: i.tallas[0]._id,
              color: i.colores[0].name,
              colorId: i.colores[0]._id,
              amount: i.cantidad,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        // setArrayTallas(tallas)

        // Setear Colores
        let uniqueColors = {};
        let arrayTablaColores = allProporcion
          .map((i) => {
            return {
              name: i.colores[0].name,
              id: i.colores[0]._id,
            };
          })
          .filter(function (el) {
            if (el && !uniqueColors[el.id]) {
              uniqueColors[el.id] = true;
              return true;
            }
            return false;
          });

        let dataFinalColores = Object.values(arrayTablaColores);

        setArrayColores(dataFinalColores);

        // Setear Tallas
        let uniqueTallas = {};
        let arrayTablaTallas = allProporcion
          .map((i) => {
            return {
              name: i.tallas[0].name,
              id: i.tallas[0]._id,
            };
          })
          .filter(function (el) {
            if (el && !uniqueTallas[el.id]) {
              uniqueTallas[el.id] = true;
              return true;
            }
            return false;
          });

        let dataFinalTallas = Object.values(arrayTablaTallas);

        const sizeOrder2 = {
          CH: 0,
          M: 1,
          G: 2,
          EG: 3,
          "2EG": 4,
          "3EG": 5,
          "4EG": 6,
          "5EG": 7,
          "6EG": 8,
          1: 9,
          2: 10,
          3: 11,
          4: 12,
          5: 13,
          6: 14,
          7: 15,
          8: 16,
          9: 17,
          10: 18,
          11: 19,
          12: 20,
          13: 21,
          14: 22,
          15: 23,
          16: 24,
          17: 25,
          18: 26,
          19: 27,
          20: 28,
          21: 29,
          22: 30,
          23: 31,
          24: 32,
          25: 33,
          26: 34,
          27: 35,
          28: 36,
          29: 37,
          30: 38,
          31: 39,
          32: 40,
          33: 41,
          34: 42,
          35: 43,
          36: 44,
          37: 45,
          38: 46,
          39: 47,
          40: 48,
          41: 49,
          42: 50,
          43: 51,
          44: 52,
          45: 53,
          46: 54,
          47: 55,
          48: 56,
          49: 57,
          50: 58,
          "5/6": 59,
          "7/8": 60,
          "10/12": 61,
          "14/16": 62,
        };

        let arrayFinalTallas = dataFinalTallas.sort((a, b) => {
          return sizeOrder2[a.name] - sizeOrder2[b.name];
        });

        setArrayTallas(arrayFinalTallas);

        function customSort(a, b) {
          // First, compare colors
          const colorComparison = a.color.localeCompare(b.color);

          // If colors are the same, compare sizes
          if (colorComparison === 0) {
            const sizeOrder = {
              CH: 0,
              M: 1,
              G: 2,
              EG: 3,
              "2EG": 4,
              "3EG": 5,
              "4EG": 6,
              "5EG": 7,
              "6EG": 8,
              1: 9,
              2: 10,
              3: 11,
              4: 12,
              5: 13,
              6: 14,
              7: 15,
              8: 16,
              9: 17,
              10: 18,
              11: 19,
              12: 20,
              13: 21,
              14: 22,
              15: 23,
              16: 24,
              17: 25,
              18: 26,
              19: 27,
              20: 28,
              21: 29,
              22: 30,
              23: 31,
              24: 32,
              25: 33,
              26: 34,
              27: 35,
              28: 36,
              29: 37,
              30: 38,
              31: 39,
              32: 40,
              33: 41,
              34: 42,
              35: 43,
              36: 44,
              37: 45,
              38: 46,
              39: 47,
              40: 48,
              41: 49,
              42: 50,
              43: 51,
              44: 52,
              45: 53,
              46: 54,
              47: 55,
              48: 56,
              49: 57,
              50: 58,
              "5/6": 59,
              "7/8": 60,
              "10/12": 61,
              "14/16": 62,
            };

            return sizeOrder[a.size] - sizeOrder[b.size];
          }

          return colorComparison;
        }

        // Sort the array using the custom sort function
        const sortedData = dataFinal.sort(customSort);

        setAmounts(sortedData);
        toggleProporcion();
      })
      .catch((err) => console.log(err));
  }

  const handleInputChange = (sizeId, colorId, value) => {
    const updatedAmounts = [...amounts];
    const existingEntryIndex = updatedAmounts.findIndex(
      (entry) => entry.sizeId === sizeId && entry.colorId === colorId
    );

    if (existingEntryIndex !== -1) {
      updatedAmounts[existingEntryIndex].amount = value;
    } else {
      updatedAmounts.push({ sizeId, colorId, amount: value });
    }

    setAmounts(updatedAmounts);
  };

  const handleInputChangeNew = (sizeId, colorId, value) => {
    const updatedAmounts = [...inputFields];
    const existingEntryIndex = updatedAmounts.findIndex(
      (entry) => entry.sizeId === sizeId && entry.colorId === colorId
    );

    if (existingEntryIndex !== -1) {
      updatedAmounts[existingEntryIndex].amount = value;
    } else {
      updatedAmounts.push({ sizeId, colorId, amount: value });
    }

    setInputFields(updatedAmounts);
  };

  function editProporcion() {
    let total = amounts.length + inputFields.length;
    amounts.map((a) => {
      axios
        .patch(
          `${URL_ARTICULOS_PROPORCION}/${a._id}`,
          {
            cantidad: a.amount,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((res) => {
          total = total - 1;
          if (total === 0) {
            Swal.fire({
              icon: "success",
              title: "Se actualizo correctamente",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

    inputFields.map((a) => {
      if (a.amount > 0) {
        axios
          .post(
            URL_ARTICULOS_PROPORCION,
            {
              articulos: idEditArticulo,
              cantidad: a.amount,
              colores: a.colorId,
              tallas: a.sizeId,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            total = total - 1;
            if (total === 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Agregar Color Proporcion",
                  detalle: `${idEditArticulo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
            setValidaBoton(true);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
          });
      } else {
        total = total - 1;
        if (total === 0) {
          axios.post(
            URL_LOGS,
            {
              tipo: "Agregar Color Proporcion",
              detalle: `${idEditArticulo}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    });
  }

  const addColor = () => {
    if (selectedColor === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Selecciona un color!",
      });
      return;
    }
    let c = "";
    let foundColor = colores.find((a) => a._id === selectedColor);

    if (foundColor) {
      c = foundColor.name;
    }

    let add = arrayTallas.map((a) => ({
      id: uuidv4(),
      colorId: selectedColor,
      color: c,
      sizeId: a.id,
      size: a.name,
      amount: 0,
    }));

    setInputFields([...inputFields, ...add]);

    let colNew = [...arrayColoresNew, { id: selectedColor, name: c }];
    setArrayColoresNew(colNew);
  };

  const addTalla = () => {
    if (selectedTalla === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Selecciona un Talla!",
      });
      return;
    }
    let c = "";
    let foundTalla = tallas.find((a) => a._id === selectedTalla);

    if (foundTalla) {
      c = foundTalla.name;
    }

    let add = arrayColores.map((a) => ({
      id: uuidv4(),
      colorId: a.id,
      color: a.name,
      sizeId: selectedTalla,
      size: c,
      amount: 0,
    }));

    setInputFields([...inputFields, ...add]);

    let colNew = [...arrayTallasNew, { id: selectedTalla, name: c }];
    setArrayTallasNew(colNew);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-success"
                    href="/ArticulosCreate"
                  >
                    Nuevo Modelo
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Modelo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={QRGenMasivo}
                >
                  QR <i class="fas fa-qrcode"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Modelos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Modelos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td> */}
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedFamilia}
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAno}
                  onChange={(e) => {
                    setSelectedAno(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.piezasPorCaja}</td>
                      {/* <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.costoUSD
                          )}{" "}
                      </td> */}
                      {/* <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX)}{" "}
                      </td> */}
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.venta)}{" "}
                      </td>
                      <td>{a.familia} </td>
                      <td>{a.linea} </td>
                      <td>{a.marca} </td>
                      <td>{a.coleccion} </td>
                      <td>{a.proveedor} </td>
                      <td>{a.ano} </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaFoto(a._id)}
                            >
                              <i class="fas fa-camera"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) =>
                                QRGenSingle(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.linea,
                                  a.marca,
                                  a.familia,
                                  a.coleccion,
                                  a.ano,
                                  a.venta
                                )
                              }
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                QRGen(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.linea,
                                  a.marca,
                                  a.familia,
                                  a.coleccion,
                                  a.ano,
                                  a.venta
                                )
                              }
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button>

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.costoUSD,
                                  a.costoMX,
                                  a.venta,
                                  a.utilidadP,
                                  a.utilidadM,
                                  a.lista1,
                                  a.lista2,
                                  a.lista3,
                                  a.lista4,
                                  a.observaciones,
                                  a.idLinea,
                                  a.idMarca,
                                  a.idFamilia,
                                  a.idColeccion,
                                  a.idProveedor,
                                  a.ano,
                                  a.EAN,
                                  a.precioEsp,
                                  a.codigoSAT,
                                  a.codigoUnidad,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) =>
                                jalaProporcion(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.tallas
                                )
                              }
                            >
                              <i class="fas fa-ruler"></i>
                            </Button>

                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Ficha Tecnica</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/noydiArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido una Ficha Tecnica....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Modelo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Numero de Modelo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigo}
                required
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Piezas Por Caja</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas Por Caja"
                value={piezasPorCaja}
                required
                onChange={(e) => {
                  setPiezasPorCaja(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>EAN</Label>
              <Input
                type="text"
                placeholder="EAN"
                value={EAN}
                required
                onChange={(e) => {
                  setEAN(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label>FOB USD</Label>
              <Input
                type="text"
                placeholder="FOB USD"
                value={costoUSD}
                required
                onChange={(e) => {
                  setCostoUSD(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Costo MX</Label>
              <Input
                type="text"
                placeholder="Costo MX"
                value={costoMX}
                required
                onChange={(e) => {
                  setCostoMX(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Utilidad %</Label>
              <Input
                type="number"
                placeholder="Utilidad %"
                value={utilidadP}
                required
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  SetUtilidadP(value);
                  setLastChanged("utilidadP");
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Utilidad por monto </Label>
              <Input
                type="number"
                placeholder="Utilidad por monto"
                value={utilidadM}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;
                  setUtilidadM(value);
                  setLastChanged("utilidadM");
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Venta</Label>
              <Input
                type="text"
                placeholder="Venta"
                value={venta}
                required
                onChange={(e) => {
                  setVenta(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Observaciones</Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
              <Col md={3}>
                                <Label>Codigo SAT</Label>
                                    <Input
                                      type="select"
                                      placeholder="CodigoSAT"
                                      value={codigoSAT}
                                      required
                                      onChange={(e) => {
                                        setCodigoSAT(e.target.value);
                                      }}
                                    >
                                      <option value="">Selecciona</option>
                                      <option value="53101604">53101604 Blusas Mujer</option>
                                      <option value="53102002">53102002 Vestidos, Faldas, Jumper</option>
                                      <option value="53101504">53101504 Pantalones Mujer</option>
                                      <option value="53101804">53101804 Chamarras Mujer</option>
                                      <option value="53101704">53101704 Sweater Mujer</option>
                                      <option value="53103100">53103100 Chalecos</option>
                                      <option value="53102800">53102800 Trajes de Baño</option>
                                      <option value="53101602">53101602 Camisas Hombre</option>
                                      <option value="53101502">53101502 Pantalon Hombre</option>
                                      <option value="52121500">52121500 Cobija</option>
                                      <option value="52121509">52121509 Sabanas</option>
                                    </Input>
                              </Col>
                          <Col md={3}>
                            <Label>Codigo Unidad</Label>
                            <Input
                              type="string"
                              placeholder="CodigoUnidad"
                              value={codigoUnidad}
                              required
                              onChange={(e) => {
                                setCodigoUnidad(e.target.value);
                              }}
                            />
                          </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Linea</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedLineaEdit}
                required
                onChange={(e) => {
                  setSelectedLineaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Linea</option>
                {lineas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Marca</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedMarcaEdit}
                required
                onChange={(e) => {
                  setSelectedMarcaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Marca</option>
                {marcas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Familia</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedFamiliaEdit}
                required
                onChange={(e) => {
                  setSelectedFamiliaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Familia</option>
                {familias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Coleccion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColeccionEdit}
                required
                onChange={(e) => {
                  setSelectedColeccionEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Coleccion</option>
                {colecciones
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Año</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={ano}
                required
                onChange={(e) => {
                  setAno(e.target.value);
                }}
              >
                <option value="0">Selecciona un Año</option>
                {years.map((a) => {
                  return <option value={a}>{a}</option>;
                })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Proveedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Proveedor</option>
                {proveedores
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Precio Especial</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={precioEsp}
                required
                onChange={(e) => {
                  setPrecioEsp(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
            <Col md={6}>
              <Label>Foto</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
          <br />
          <h4>Precios de Venta</h4>
            <Row>
            <Col md={3}>
                <Label>Lista 1</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista1}
                  required
                  onChange={(e) => {
                    setLista1(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 2</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista2}
                  required
                  onChange={(e) => {
                    setLista2(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 3</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={lista3}
                  required
                  onChange={(e) => {
                    setLista3(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 4</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista4}
                  required
                  onChange={(e) => {
                    setLista4(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalProporcion} toggle={toggleProporcion}>
        <ModalHeader toggle={toggleProporcion}>
          <h4>
            Proporcion Modelo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label>Color</Label>
              <Input
                type="select"
                value={selectedColor}
                onChange={(e) => {
                  setSelectedColor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={1}>
              <Label>Agregar</Label>
              <br />
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  addColor();
                }}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </Col>

            <Col md={2}>
              <Label>Talla</Label>
              <Input
                type="select"
                value={selectedTalla}
                onChange={(e) => {
                  setSelectedTalla(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {tallas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={1}>
              <Label>Agregar</Label>
              <br />
              <Button
                color="info"
                size="sm"
                onClick={() => {
                  addTalla();
                }}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </Row>

          <Row>
            <div>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    {/* Original colors */}
                    {arrayColores.map((color) => (
                      <th key={color.id}>{color.name}</th>
                    ))}
                    {/* New colors */}
                    {arrayColoresNew.map((color) => (
                      <th key={color.id}>{color.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Original sizes */}
                  {arrayTallas.map((size) => (
                    <tr key={size.id}>
                      <td>{size.name}</td>
                      {/* Original colors cells */}
                      {arrayColores.map((color) => {
                        const cellAmount =
                          amounts.find(
                            (entry) =>
                              entry.sizeId === size.id &&
                              entry.colorId === color.id
                          )?.amount || 0;
                        return (
                          <td key={color.id}>
                            <input
                              type="number"
                              value={cellAmount}
                              onChange={(e) =>
                                handleInputChange(
                                  size.id,
                                  color.id,
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </td>
                        );
                      })}
                      {/* New colors cells */}
                      {arrayColoresNew.map((color) => {
                        const cellAmount =
                          inputFields.find(
                            (entry) =>
                              entry.sizeId === size.id &&
                              entry.colorId === color.id
                          )?.amount || 0;
                        return (
                          <td key={color.id}>
                            <input
                              type="number"
                              value={cellAmount}
                              onChange={(e) =>
                                handleInputChangeNew(
                                  size.id,
                                  color.id,
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}

                  {/* New sizes */}
                  {arrayTallasNew.map((size) => (
                    <tr key={size.id}>
                      <td>{size.name}</td>
                      {/* Original colors cells */}
                      {arrayColores.map((color) => {
                        const cellAmount =
                          inputFields.find(
                            (entry) =>
                              entry.sizeId === size.id &&
                              entry.colorId === color.id
                          )?.amount || 0;
                        return (
                          <td key={color.id}>
                            <input
                              type="number"
                              value={cellAmount}
                              onChange={(e) =>
                                handleInputChangeNew(
                                  size.id,
                                  color.id,
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </td>
                        );
                      })}
                      {/* New colors cells */}
                      {arrayColoresNew.map((color) => {
                        const cellAmount =
                          inputFields.find(
                            (entry) =>
                              entry.sizeId === size.id &&
                              entry.colorId === color.id
                          )?.amount || 0;
                        return (
                          <td key={color.id}>
                            <input
                              type="number"
                              value={cellAmount}
                              onChange={(e) =>
                                handleInputChangeNew(
                                  size.id,
                                  color.id,
                                  e.target.value
                                )
                              }
                              min="0"
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editProporcion}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoArticulos;
