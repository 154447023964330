import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [clienteNombre, setClienteNombre] = useState("");
  const [cliente, setCliente] = useState("");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("");
  const [idPaqueteria, setIdPaqueteria] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);
  const [listaPrecios, setListaPrecios] = useState(1);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   articulos: "",
    //   cantidad: 0,
    //   precio: 0,
    //   precioOriginal: 0,
    //   total: 0,
    //   articulosNombre: "",
    //   idColor: "",
    //   colorNombre: "",
    //   idTalla: "",
    //   tallaNombre: "",
    //   colores: [],
    //   tallas: [],
    // },
  ]);
  const [expandedTables, setExpandedTables] = useState({});

  const [manualInput, setManualInput] = useState({

    articulosNombre: "",
    articulos: "",
    idColor: "",
    idTalla: "",
    tallaNombre: "",
    cantidad: 0,
    precio: 0,
    colores: [],
    tallas: [],
    total: 0,
  });

  const handleManualArticuloChange = (selectedArticulo) => {
 
    if (selectedArticulo) {

      let precioArt;
      if (listaPrecios == 1) {
        precioArt = selectedArticulo.lista1;
      } else if (listaPrecios == 2) {
        precioArt = selectedArticulo.lista2;
      } else if (listaPrecios == 3) {
        precioArt = selectedArticulo.lista3;
      } else if (listaPrecios == 4) {
        precioArt = selectedArticulo.lista4;
      }

      setManualInput({
        articulos: selectedArticulo._id,
        articulosNombre: selectedArticulo.codigo + selectedArticulo.nombre,
        precio: precioArt,
        colores: selectedArticulo.colores,
        tallas: selectedArticulo.tallas,
        idColor: "",
        idTalla: "",
        cantidad: 0,
        idProporcion: "",
      });
    }
  };

  const ActualizaTotales = useCallback(() => {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }, [
    inputFields,
    selectedImpuesto,
    descuento,
    comisionTDC,
    comisionFlete,
    setTotalCantidad,
    setSubTotal,
    setIva,
    setDescuentoNumero,
    setTotalGeneral,
  ]);

  useEffect(() => {
    ActualizaTotales();
  }, [inputFields, ActualizaTotales]);

  const handleManualInputChange = (event) => {
    const { name, value } = event.target;

    setManualInput((prev) => {
      const updatedInput = {
        ...prev,
        [name]: value,
      };

      // If changing talla, update tallaNombre
      if (name === "idTalla") {
        const selectedTalla = prev.tallas.find((t) => t._id === value);
        updatedInput.tallaNombre = selectedTalla ? selectedTalla.name : "";
      }

      // Calculate total whenever cantidad changes
      if (name === "cantidad") {
        updatedInput.cantidad = parseFloat(value);
        updatedInput.total = parseFloat(value) * prev.precio;
      }

      return updatedInput;
    });
  };

  const handleManualAdd = async () => {
    if (
      !manualInput.articulosNombre ||
      !manualInput.idColor ||
      !manualInput.idTalla ||
      !manualInput.cantidad
    ) {
      return;
    }

    let res;

    try {
      res = await axios.get(
        `${URL_ARTICULOS_PROPORCION}/ArticuloColorTalla/${manualInput.articulos}/${manualInput.idColor}/${manualInput.idTalla}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }

    if (!res) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No se encontró el artículo con la talla y color seleccionados",
        showConfirmButton: false,
      });
      return;
    }
    const idArticuloProporcion = res.data[0]._id;

    const avaible = inputFields.find(
      (i) => i.idArticuloProporcion === idArticuloProporcion
    );

    if (avaible) {
      // Create new array with updated object
      const updatedFields = inputFields.map((field) => {
        if (field.idArticuloProporcion === idArticuloProporcion) {
          return {
            ...field,
            cantidad: field.cantidad + manualInput.cantidad,
            total: (field.cantidad + manualInput.cantidad) * field.precio,
          };
        }
        return field;
      });

      setInputFields(updatedFields);
       // Reset only specific fields after adding
    setManualInput((prev) => ({
      ...prev,
      idColor: "",
      articulos: "",
      articulosNombre: "",
      cantidad: 0,
      idTalla: "",
      tallaNombre: "",
      total: 0,
      idProporcion: "",
    }));

      return null;
    }

    

    const selectedColor = manualInput.colores.find(
      (c) => c._id === manualInput.idColor
    );
    const selectedTalla = manualInput.tallas.find(
      (t) => t._id === manualInput.idTalla
    );

    const newField =  {
      id: uuidv4(),
      articulos: manualInput.articulos,
      articulosNombre: manualInput.articulosNombre,
      precio: manualInput.precio,
      precioOriginal: manualInput.precio,
      cantidad: parseFloat(manualInput.cantidad),
      idTalla: manualInput.idTalla,
      tallaNombre: selectedTalla ? selectedTalla.name : "",
      idColor: manualInput.idColor,
      colorNombre: selectedColor ? selectedColor.name : "",
      total: manualInput.cantidad * manualInput.precio,
      colores: manualInput.colores,
      tallas: manualInput.tallas,
      idArticuloProporcion: idArticuloProporcion,
    };

    setInputFields((prev) => [...prev, newField]);

    // Reset only specific fields after adding
    setManualInput((prev) => ({
      ...prev,
      idColor: "",
      articulos: "",
      articulosNombre: "",
      cantidad: 0,
      idTalla: "",
      tallaNombre: "",
      precio: 0,
      total: 0,
      idProporcion: "",
    }));
  };

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      precio: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
        let result = allArticulos.map((a) => a._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}DisponibleGENERAL`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedArea]);

  const savePedido = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields.length;
    let contador = 0;
    if (cambiarPrecio === false && user.precio_menor === false) {
      inputFields.map(async (a) => {
        if (a.precioOriginal - 51 < a.precio) {
          return (contador = contador + 1);
        }
      });
    } else {
      contador = inputFields.length;
    }

    if (contador === cantidadIF) {
      if (selectedCliente !== "" && selectedArea !== "") {
        setValidaBoton(false);
        toggleProgreso();
        let totalPedido = inputFields.length;
        try {
          await axios
            .post(
              URL_PEDIDOS,
              {
                fecha,
                clientes: selectedCliente,
                departamentosClientes: departamentoCliente,
                colaboradores: selectedColaborador,
                total_general: parseFloat(total_general).toFixed(2),
                observaciones,
                total_cantidad,
                paqueterias: idPaqueteria,
                areas: selectedArea,
                comisionTDC,
                comisionFlete,
                subTotal: parseFloat(subTotal).toFixed(2),
                iva: parseFloat(iva).toFixed(2),
                impuestos: selectedImpuesto,
                surtido: "No",
                autorizadoApartado: "Si",
                autorizadoSurtido: "No",
                editado: "No",
                fechaModificado: fecha,
                creado: user.id,
                fecha_estimada: fecha,
                fecha_entrega: fecha,
                entregaInsumos: "No",
                descuento,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((data) => {
              inputFields.map((a) => {
                if (a.articulos !== "" && a.cantidad !== 0) {
                  axios
                    .post(
                      URL_ARTICULOS_PEDIDO,
                      {
                        pedidos: data.data._id,
                        articulos: a.articulos,
                        colores: a.idColor,
                        tallas: a.idTalla,
                        clientes: selectedCliente,
                        cantidad: parseFloat(a.cantidad),
                        surtido: 0,
                        pendiente_surtir: parseFloat(a.cantidad),
                        precio: parseFloat(a.precio).toFixed(2),
                        total: parseFloat(a.total).toFixed(2),
                        autorizadoSurtido: "No",
                        autorizadoApartado: "Si",
                        observaciones: "NA",
                        articulosProporcion: a.idArticuloProporcion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      totalPedido = totalPedido - 1;
                      if (totalPedido === 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Pedido",
                              detalle: `${selectedCliente} ${total_general}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              `success`
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          });
                      }
                    });
                } else {
                  totalPedido = totalPedido - 1;
                  if (totalPedido === 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Pedido",
                          detalle: `${selectedCliente} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                }
                return null;
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Faltan datos, favor de revisar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes vender $50 menos del precio original",
        showConfirmButton: false,
      });
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        idColor: "",
        colorNombre: "",
        idTalla: "",
        tallaNombre: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * TG) / 100 +
        TG +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.precio = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
    ActualizaTotales();
  };


  function BuscaCodigoProporcion(id, event) {
    if (event.target.value.length >= 24) {
      const foundArticle = articulos.find((a) => a._id === event.target.value);

      if (foundArticle) {
        const idArticulo = event.target.value;
        const articulosNombre = `${foundArticle.codigo} ${foundArticle.nombre}`;
        // const precio = foundArticle.venta;
        const colores = foundArticle.colores;
        const tallas = foundArticle.tallas;

        let precioArt;
        if (listaPrecios == 1) {
          precioArt = foundArticle.lista1;
        } else if (listaPrecios == 2) {
          precioArt = foundArticle.lista2;
        } else if (listaPrecios == 3) {
          precioArt = foundArticle.lista3;
        } else if (listaPrecios == 4) {
          precioArt = foundArticle.lista4;
        }

        handleChangeInputArticuloProporcion(
          id,
          idArticulo,
          precioArt,
          articulosNombre,
          colores,
          tallas
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Artículo no encontrado",
          text: "No se encontró ningún artículo con el código proporcionado",
        });
      }
    }
  }

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    articulos.map((a) => {
      if (a._id === selectedArticulo._id) {
        let idArticulo = selectedArticulo._id;
        let piezasPorCaja = a.piezasPorCaja;
        let precio = a.venta;
        let precioOriginal = a.venta;
        let articulosNombre = selectedArticulo.codigo;
        let colores = a.colores;
        let tallas = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          piezasPorCaja,
          precio,
          precioOriginal,
          articulosNombre,
          colores,
          tallas
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp =
      temp +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) +
      parseFloat(iva);

    setTotalGeneral(totTemp);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const options2 = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    setSelectedCliente(cliente._id);

    axios
      .get(`${URL_CLIENTES}/${cliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setDescuento(allCliente.descuento);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
        setClienteNombre(allCliente.codigo + " " + allCliente.nombre_comercial);
        setListaPrecios(allCliente.listaPrecios);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e !== 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (e * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password === randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios.post(
      `${URL_WHATSAPP}SendMsg`,
      {
        number: process.env.REACT_APP_TELEFONO_SUPER_ADMIN,
        message: `Hola,
El Vendedor ${user.nombre} ${user.apellido} esta solicitando un cambio de precio.
Si lo autorizas proporciona la clave:
${randomCambiaPrecio}`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    );

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: process.env.REACT_APP_EMAIL_SUPER_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>El vendedor ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function BuscaArticuloProporcion(id, selectedArticulo) {
    const foundArticle = articulos.find((a) => a._id === selectedArticulo._id);

    if (foundArticle) {
      const idArticulo = selectedArticulo._id;
      const articulosNombre = `${selectedArticulo.codigo} ${selectedArticulo.nombre}`;
      const colores = foundArticle.colores;
      const tallas = foundArticle.tallas;

      let precioArt;
      if (listaPrecios == 1) {
        precioArt = foundArticle.lista1;
      } else if (listaPrecios == 2) {
        precioArt = foundArticle.lista2;
      } else if (listaPrecios == 3) {
        precioArt = foundArticle.lista3;
      } else if (listaPrecios == 4) {
        precioArt = foundArticle.lista4;
      }

      handleChangeInputArticuloProporcion(
        id,
        idArticulo,
        precioArt,
        articulosNombre,
        colores,
        tallas
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Artículo no encontrado",
        text: "No se encontró ningún artículo con el código proporcionado",
      });
    }
  }
  async function buscarArticuloSingular(id, event) {
    // First, add validation to ensure event and event.target exist
    if (!event || !event.target) {
        console.error("Invalid event object");
        return;
    }

    const scanValue = event.target.value;
    
    // Validate the scan value exists
    if (!scanValue || scanValue.length < 24) {
        return;
    }

    try {
        const res = await axios.get(
            `${URL_ARTICULOS_PROPORCION}/${scanValue}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("app_token")}`,
                },
            }
        );

        if (!res.data) {
            throw new Error("No data received");
        }

        const {
            _id: idArticuloProporcion,
            articulos: [firstArticulo],
            colores,
            tallas
        } = res.data;

        // Check if any required data is missing
        if (!firstArticulo || !colores.length || !tallas.length) {
            throw new Error("Invalid article data");
        }

        // Check for existing item
        const existingItem = inputFields.find(
            (item) => item.idArticuloProporcion === scanValue
        );

        if (existingItem) {
            const updatedFields = inputFields.map((field) => {
                if (field.idArticuloProporcion === scanValue) {
                    const newQuantity = field.cantidad + 1;
                    return {
                        ...field,
                        cantidad: newQuantity,
                        total: newQuantity * field.precio,
                    };
                }
                return field;
            });

            setInputFields(updatedFields);
            return;
        }

        let precioArt;
        if (listaPrecios == 1) {
          precioArt = firstArticulo.lista1;
        } else if (listaPrecios == 2) {
          precioArt = firstArticulo.lista2;
        } else if (listaPrecios == 3) {
          precioArt = firstArticulo.lista3;
        } else if (listaPrecios == 4) {
          precioArt = firstArticulo.lista4;
        }

        // Create new item
        const newField = {
            id: uuidv4(),
            articulos: firstArticulo._id,
            articulosNombre: `${firstArticulo.codigo} ${firstArticulo.nombre}`,
            precio: precioArt,
            precioOriginal: precioArt,
            cantidad: 1,
            idTalla: tallas[0]._id,
            tallaNombre: tallas[0].name,
            idColor: colores[0]._id,
            colorNombre: colores[0].name,
            total: precioArt,
            colores: colores,
            tallas: tallas,
            idArticuloProporcion: idArticuloProporcion,
        };

        setInputFields((prev) => [...prev, newField]);

    } catch (error) {
        console.error("Error:", error);
        Swal.fire({
            icon: "error",
            title: "Artículo no encontrado",
            text: "No se encontró ningún artículo con el código proporcionado",
        });
    }
}

  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    precio,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        i.articulosNombre = articulosNombre;
        i.cajas = 0;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
    ActualizaTotales();
  };

  function agregarProporcion(
    idArticulo,
    articulosNombre,
    precio,
    cajas,
    colores,
    tallas
  ) {
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;

        const avaible = inputFields.find((f) => f.articulos === idArticulo);
        if (avaible) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ya existe este articulo en la lista",
            showConfirmButton: false,
          });
          return;
        }

        const newInputFields = allArticulos.map((i) => {
          return {
            id: uuidv4(),
            articulos: idArticulo,
            articulosNombre: articulosNombre,
            precio: precio,
            precioOriginal: precio,
            cantidad: cajas * i.cantidad,
            idTalla: i.tallas[0]._id,
            tallaNombre: i.tallas[0].name,
            idColor: i.colores[0]._id,
            colorNombre: i.colores[0].name,
            total: cajas * i.cantidad * precio,
            colores: colores,
            tallas: tallas,
            idArticuloProporcion: i._id,
            // Add a groupKey to help with visual grouping
            groupKey: `${articulosNombre}-${i.colores[0].name}`,
            isFirstInGroup: false, // We'll set this after sorting
          };
        });

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          // Sort using your existing order
          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            const tallaOrder = [
              "EECH",
              "ECH",
              "CH",
              "S",
              "M",
              "G",
              "L",
              "EG",
              "XL",
              "2EG",
              "XXL",
              "3EG",
              "4EG",
              "5EG",
              "6EG",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
              "32",
              "33",
              "34",
              "35",
              "36",
              "37",
              "38",
              "39",
              "40",
              "41",
              "42",
              "43",
              "44",
              "45",
              "46",
              "47",
              "48",
              "49",
              "50",
              "3/4",
              "5/6",
              "7/8",
              "9/10",
              "11/12",
              "10/12",
              "13/14",
              "14/16",
              "15/16",
            ];
            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Mark first items in each group
          let currentGroup = "";
          sortedInputFields.forEach((field, index) => {
            if (field.groupKey !== currentGroup) {
              field.isFirstInGroup = true;
              currentGroup = field.groupKey;
            } else {
              field.isFirstInGroup = false;
            }
          });

          // Calculate totals
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );
          setTotalCantidad(totalCantidad);

          let importes = sortedInputFields.map((c) => parseFloat(c.total));
          let TG = importes.reduce((t, total) => t + total, 0);
          setSubTotal(TG);

          if (selectedImpuesto !== 0) {
            setIva((selectedImpuesto * TG) / 100);
          } else {
            setIva(0);
          }

          let temp1 = (TG * descuento) / 100;
          setDescuentoNumero(temp1);

          let totTemp =
            (selectedImpuesto * TG) / 100 +
            TG +
            parseFloat(comisionTDC) +
            parseFloat(comisionFlete) -
            parseFloat(temp1);
          setTotalGeneral(totTemp);

          return sortedInputFields;
        });

        setInputArticulos([
          {
            id: uuidv4(),
            articulos: "",
            cajas: 0,
            precio: 0,
            articulosNombre: "",
            colores: [],
            tallas: [],
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12 p-4">
          <h3 align="center">Nuevo Pedido</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Buscar</Label>
              <Autocomplete
                size="small"
                value={value}
                onChange={(event, selectedCliente) => {
                  if (selectedCliente) {
                    jalaCliente(selectedCliente);
                  }
                }}
                options={options2.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.codigo} {option.nombre_comercial}
                  </React.Fragment>
                )}
              />
            </Col>
            <Col md={3}>
              <Label>Cliente</Label>
              <Input
                type="text"
                placeholder="Cliente"
                value={clienteNombre}
                required
                disabled
              />
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                // onChange={(e) => {
                //   setSelectedColaborador(e.target.value)
                // }}
                disabled
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={idPaqueteria}
                required
                onChange={(e) => {
                  setIdPaqueteria(e.target.value);
                }}
              >
                <option value="0">Selecciona </option>
                {paqueterias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.esTienda == "No") {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Cambio de Precio</Label>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => toggleCambiaPrecio()}
              >
                Solicitar
              </Button>
            </Col>
          </Row>
          <br />

          {/* FULL PRODUCT SECTION */}
          <h4 className="text-dark">
            <b>Agregar Caja</b>
          </h4>
          <>
            {/* QR Scanner Input */}
            <Row className=""></Row>

            {/* Proportion Input Section */}
            <Row className="">
              <Col md={2}>
                <Label className="mr-sm-2">Escanea el QR</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={2}>
                <Input
                  name="articulos"
                  type="string"
                  value={inputArticulos[0]?.articulos}
                  onChange={(event) => {
                    BuscaCodigoProporcion(inputArticulos[0].id, event);
                  }}
                ></Input>
              </Col>
              <Col md={2}>
                <Autocomplete
                  size="small"
                  value={inputArticulos[0]?.articulosNombre || ""}
                  onChange={(event, selectedArticulo) => {
                    BuscaArticuloProporcion(
                      inputArticulos[0]?.id || "template",
                      selectedArticulo
                    );
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.codigo} {option.nombre}
                    </React.Fragment>
                  )}
                />
              </Col>
              <Col md={2}>
                <Input
                  name="articulosNombre"
                  type="string"
                  placeholder="Articulo"
                  value={inputArticulos[0]?.articulosNombre || ""}
                  disabled
                />
              </Col>
              <Col md={1}>
                <Input
                  name="cajas"
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Cajas"
                  value={inputArticulos[0]?.cajas || ""}
                  required
                  onChange={(event) =>
                    handleChangeInputProporcion(
                      inputArticulos[0]?.id || "template",
                      event
                    )
                  }
                />
              </Col>
              <Col md={1}>
                <Input
                  name="precio"
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Precio"
                  value={inputArticulos[0]?.precio || ""}
                  required
                  onChange={(event) =>
                    handleChangeInputPrecio(
                      inputArticulos[0]?.id || "template",
                      event
                    )
                  }
                />
              </Col>
              <Col>
                <Button
                  size="sm"
                  className="btn"
                  color="success"
                  disabled={
                    !inputArticulos[0]?.articulos ||
                    !inputArticulos[0]?.cajas ||
                    !inputArticulos[0]?.precio
                  }
                  onClick={() =>
                    agregarProporcion(
                      inputArticulos[0]?.articulos,
                      inputArticulos[0]?.articulosNombre,
                      inputArticulos[0]?.precio,
                      inputArticulos[0]?.cajas,
                      inputArticulos[0]?.colores,
                      inputArticulos[0]?.tallas
                    )
                  }
                  tabindex="-1"
                >
                  Agregar
                </Button>
              </Col>
            </Row>

            {/* MANUAL PRODUCT SECTION */}
            <h4 className="text-dark">
              <b>Agregar Pieza</b>
            </h4>
            <Row className="">
              <Col md={2}>
                <Label className="mr-sm-2">Escanea el QR</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Input
                  name="articulos"
                  type="string"
                  value={manualInput.idProporcion}
                  onChange={(event) => {
                    buscarArticuloSingular(manualInput.id, event);
                  }}
                ></Input>
              </Col>
              <Col md={2}>
                <Autocomplete
                  size="small"
                  value={manualInput?.articulosNombre || ""}
                  onChange={(event, selectedArticulo) => {
                    handleManualArticuloChange(selectedArticulo);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.codigo} {option.nombre}
                    </React.Fragment>
                  )}
                />
              </Col>
              <Col md={2}>
                <Input
                  type="text"
                  value={manualInput?.articulosNombre || ""}
                  disabled
                />
              </Col>
              <Col md={1}>
                <Input
                  name="idColor"
                  type="select"
                  value={manualInput?.idColor || ""}
                  onChange={handleManualInputChange}
                >
                  <option value="">Selecciona Color</option>
                  {manualInput?.colores?.map((color) => (
                    <option key={color._id} value={color._id}>
                      {color.name}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md={1}>
                <Input
                  name="idTalla"
                  type="select"
                  value={manualInput.idTalla || ""}
                  onChange={handleManualInputChange}
                >
                  <option value="">Selecciona Talla</option>
                  {manualInput.tallas &&
                    manualInput.tallas.map((talla) => (
                      <option key={talla._id} value={talla._id}>
                        {talla.name}
                      </option>
                    ))}
                </Input>
              </Col>
              <Col md={1}>
                <Input
                  name="cantidad"
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Cantidad"
                  value={manualInput?.cantidad || 0}
                  onChange={handleManualInputChange}
                />
              </Col>
              <Col md={1}>
                <Input
                  name="precio"
                  type="number"
                  value={manualInput?.precio || 0}
                />
              </Col>
              <Col>
                <Button
                  size="sm"
                  className="btn"
                  color="success"
                  onClick={handleManualAdd}
                  disabled={
                    !manualInput?.articulosNombre ||
                    !manualInput?.idColor ||
                    !manualInput?.idTalla ||
                    !manualInput?.cantidad
                  }
                  tabindex="-1"
                >
                  Agregar
                </Button>
              </Col>
            </Row>
          </>

          <br />
          <hr />

          <div>
            {Object.entries(
              inputFields
                .filter((field) => field.articulosNombre && field.articulos)
                .reduce((acc, field) => {
                  const key = `${field.articulosNombre}`;
                  if (!acc[key]) {
                    acc[key] = {
                      articuloInfo: {
                        articulosNombre: field.articulosNombre,
                        articulos: field.articulos,
                        availableTallas: Array.from(
                          new Set(
                            inputFields
                              .filter(
                                (f) =>
                                  f.articulosNombre === field.articulosNombre
                              )
                              .map((f) => f.tallaNombre)
                              .filter(Boolean)
                              .sort((a, b) => {
                                const tallaOrder = [
                                  "EECH",
                                  "ECH",
                                  "CH",
                                  "S",
                                  "M",
                                  "G",
                                  "L",
                                  "EG",
                                  "XL",
                                  "2EG",
                                  "XXL",
                                  "3EG",
                                  "4EG",
                                  "5EG",
                                  "6EG",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                  "10",
                                  "11",
                                  "12",
                                  "13",
                                  "14",
                                  "15",
                                  "16",
                                  "17",
                                  "18",
                                  "19",
                                  "20",
                                  "21",
                                  "22",
                                  "23",
                                  "24",
                                  "25",
                                  "26",
                                  "27",
                                  "28",
                                  "29",
                                  "30",
                                  "31",
                                  "32",
                                  "33",
                                  "34",
                                  "35",
                                  "36",
                                  "37",
                                  "38",
                                  "39",
                                  "40",
                                  "41",
                                  "42",
                                  "43",
                                  "44",
                                  "45",
                                  "46",
                                  "47",
                                  "48",
                                  "49",
                                  "50",
                                  "3/4",
                                  "5/6",
                                  "7/8",
                                  "9/10",
                                  "11/12",
                                  "10/12",
                                  "13/14",
                                  "14/16",
                                  "15/16",
                                ];
                                return (
                                  tallaOrder.indexOf(a) - tallaOrder.indexOf(b)
                                );
                              })
                          )
                        ),
                      },
                      colorGroups: [],
                    };
                  }

                  const existingColorIndex = acc[key].colorGroups.findIndex(
                    (item) => item.idColor === field.idColor
                  );

                  if (existingColorIndex === -1) {
                    acc[key].colorGroups.push({
                      id: field.id,
                      articulos: field.articulos,
                      articulosNombre: field.articulosNombre,
                      idColor: field.idColor,
                      colorNombre: field.colorNombre,
                      colores: field.colores,
                      precioOriginal: field.precioOriginal,
                      precio: field.precio,
                      quantities: {
                        [field.tallaNombre]: {
                          cantidad: field.cantidad,
                          id: field.id,
                        },
                      },
                      total: field.cantidad * field.precio,
                    });
                  } else {
                    acc[key].colorGroups[existingColorIndex].quantities[
                      field.tallaNombre
                    ] = {
                      cantidad: field.cantidad,
                      id: field.id,
                    };
                    acc[key].colorGroups[existingColorIndex].total +=
                      field.cantidad * field.precio;
                  }
                  return acc;
                }, {})
            ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
              // Calculate totals for this article
              const articleTotals = colorGroups.reduce(
                (totals, group) => {
                  const quantitySum = Object.values(group.quantities).reduce(
                    (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                    0
                  );
                  const priceSum = Object.values(group.quantities).reduce(
                    (sum, q) =>
                      sum + (parseFloat(q.cantidad) || 0) * group.precio,
                    0
                  );

                  return {
                    items: totals.items + quantitySum,
                    price: totals.price + priceSum,
                    singlePrice: group.precio,
                  };
                },
                { items: 0, price: 0 }
              );

              return (
                <div key={articuloNombre} className="mb-8">
                  <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                    <div className="d-flex align-items-center">
                      <Button
                        size="sm"
                        className="btn mr-3"
                        color="info"
                        onClick={() =>
                          setExpandedTables((prev) => ({
                            ...prev,
                            [articuloNombre]: !prev[articuloNombre],
                          }))
                        }
                      >
                        <i
                          className={`fas fa-chevron-${
                            expandedTables[articuloNombre] ? "up" : "down"
                          }`}
                        ></i>
                      </Button>
                      <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                    </div>
                    {!expandedTables[articuloNombre] && (
                      <div className="d-flex align-items-center">
                        <span
                          className="mr-4 text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Piezas:</strong>{" "}
                          {articleTotals.items}
                        </span>
                        <span
                          className="mr-4 text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Precio:</strong>{" "}
                          $
                          {articleTotals.singlePrice.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                        <span
                          className="text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          <strong style={{ fontSize: "18px" }}>Total:</strong> $
                          {articleTotals.price.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    )}
                  </div>

                  {expandedTables[articuloNombre] && (
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Articulo</th>
                          <th>Color</th>
                          {articuloInfo.availableTallas.map((talla) => (
                            <th key={talla} style={{ maxWidth: "60px" }}>
                              {talla}
                            </th>
                          ))}
                          <th style={{ maxWidth: "60px" }}>Disponible</th>
                          <th style={{ maxWidth: "60px" }}>Precio Original</th>
                          <th style={{ maxWidth: "60px" }}>Precio</th>
                          <th style={{ maxWidth: "80px" }}>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {colorGroups.map((group) => (
                          <tr key={`${group.id}-${group.idColor}`}>
                            {/* Your existing row content */}
                            <td>
                              <Input
                                name="articulo"
                                type="string"
                                placeholder="Articulo"
                                value={group.articulosNombre}
                                disabled
                              />
                            </td>
                            <td>
                              <Input
                                name="idColor"
                                type="select"
                                value={group.idColor}
                                onChange={(event) =>
                                  handleChangeInput(group.id, event)
                                }
                              >
                                <option value="">Selecciona</option>
                                {group.colores?.map((a) => (
                                  <option key={a._id} value={a._id}>
                                    {a.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            {articuloInfo.availableTallas.map((talla) => (
                              <td key={talla} style={{ maxWidth: "60px" }}>
                                <Input
                                  name="cantidad"
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={group.quantities[talla]?.cantidad || 0}
                                  onChange={(event) =>
                                    handleChangeInputCantidad(
                                      group.quantities[talla]?.id || group.id,
                                      event
                                    )
                                  }
                                />
                              </td>
                            ))}
                            <td style={{ maxWidth: "60px" }}>
                              <Input type="select" disabled>
                                {inventarios.map((a) => {
                                  if (group.articulos === a.idArticulo) {
                                    return (
                                      <option key={a.id}>{a.disponible}</option>
                                    );
                                  }
                                  return null;
                                })}
                              </Input>
                            </td>
                            <td style={{ maxWidth: "60px" }}>
                              <Input
                                name="precioOriginal"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="PrecioOriginal"
                                value={group.precioOriginal}
                                disabled
                              />
                            </td>
                            <td style={{ maxWidth: "60px" }}>
                              <Input
                                style={
                                  group.precioOriginal - 50 > group.precio
                                    ? { backgroundColor: "#ed8c8c" }
                                    : null
                                }
                                name="precio"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="Precio"
                                value={group.precio}
                                required
                                onChange={(event) =>
                                  handleChangeInput(group.id, event)
                                }
                              />
                            </td>
                            <td style={{ maxWidth: "80px" }}>
                              <Input
                                name="total"
                                type="number"
                                min="0"
                                step="any"
                                placeholder="total"
                                value={group.total}
                                disabled
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled={inputFields.length === 1}
                                onClick={() => handleRemoveFields(group.id)}
                                tabindex="-1"
                              >
                                <i className="fas fa-minus"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              );
            })}
          </div>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}

          <br />
          <Row className="align-items-center mt-3">
            <Col xs={3} className="text-dark" style={{ fontSize: "20px" }}>
              <span className="fw-bold">
                <b>Total Piezas:</b>{" "}
              </span>
              <span>
                {new Intl.NumberFormat("en-US").format(total_cantidad)}
              </span>
            </Col>
            <Col xs={3} className="text-dark" style={{ fontSize: "20px" }}>
              <span className="fw-bold">
                <b>Subtotal:</b>{" "}
              </span>
              <span>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(subTotal)}
              </span>
            </Col>
            <Col xs={3} className="text-dark" style={{ fontSize: "20px" }}>
              <span className="fw-bold">
                <b>IVA:</b>{" "}
              </span>
              <span>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(iva)}
              </span>
            </Col>
            <Col xs={3} className="text-dark" style={{ fontSize: "20px" }}>
              <span className="fw-bold">
                <b>Total:</b>{" "}
              </span>
              <span>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(total_general)}
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoPedidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado === false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
