import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosSalidaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaSalida, setAreaSalida] = useState("");
  const [areaDestino, setAreaDestino] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    // {
    //   id: uuidv4(),
    //   articulos: "",
    //   articulosNombre: "",
    //   cantidad: 0,
    //   cantInv: 0,
    //   idInv: "",
    //   costoUnitario: 0,
    //   colores: [],
    //   tallas: [],
    //   colorNombre: "",
    //   tallaNombre: "",
    //   idColor: "",
    //   idTalla: "",
    // },
  ]);

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [manualInput, setManualInput] = useState({
    articulosNombre: "",
    articulos: "",
    idColor: "",
    idTalla: "",
    tallaNombre: "",
    cantInv: 0,
    cantidad: 0,
    colores: [],
    tallas: [],
    total: 0,
  });

  useEffect(() => {
    if (inputFields.length > 0) {
      const totalCantidad = inputFields.reduce(
        (acc, curr) => acc + parseFloat(curr.cantidad || 0),
        0
      );
      setTotal(totalCantidad);
    } else {
      setTotal(0);
    }
  }, [inputFields]);

   async function buscarArticuloSingular(id, event) {
      try {
        inventarios.map((a) => {
          if (a.idArticuloProporcion === event.target.value) {
            let idArticulo = a.idArticulo;
            let articulosNombre = a.nombre;
            let colores = a.colores;
            let tallas = a.tallas;
            let cantInv = parseFloat(a.cantidad);
            let idInv = a._id;
            let idColor = a.idColor;
            let idTalla = a.idTalla;
            let precio = a.venta;
  
            const avaible = inputFields.find(
              (i) => i.idArticuloProporcion === event.target.value
            );
  
            if (avaible) {
              // Create new array with updated object
              const updatedFields = inputFields.map((field) => {
                if (field.idArticuloProporcion === event.target.value) {
                  return {
                    ...field,
                    cantidad: field.cantidad + 1,
                    total: (field.cantidad + 1) * field.precio,
                  };
                }
                return field;
              });
  
              setInputFields(updatedFields);
              return null;
            }
  
            // const avaiable = inputFields.find((i) => {
            //   if (i.articulos === idArticulo) {
            //     Swal.fire({
            //       icon: "error",
            //       title: "Oops...",
            //       text: "El artículo ya ha sido agregado",
            //       showConfirmButton: false,
            //     });
            //     return true;
            //   }
            //   return false;
            // });
            // if (avaiable) {
            //   return;
            // }
            const newField = {
              id: uuidv4(),
              articulos: idArticulo,
              idInv: idInv,
              cantInv: cantInv,
              articulosNombre: articulosNombre,
      
              cantidad: 1,
              costoUnitario: a.costoUnitario,
      
      
              idTalla: a.idTalla,
              tallaNombre: a.talla,
              idColor: idColor,
              colorNombre: a.color,
              colores: a.colores,
              tallas: a.tallas,
              idArticuloProporcion: a.idArticuloProporcion,
            };
  
  
            setInputFields((prev) => [...prev, newField]);
  
  
            // Reset only specific fields after adding
            setManualInput((prev) => ({
              ...prev,
              idColor: "",
              articulos: "",
              articulosNombre: "",
              cantidad: 0,
              idTalla: "",
              tallaNombre: "",
              total: 0,
              precio: 0,
              idProporcion: "",
            }));
          }
          return null;
        });
      } catch (error) {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Artículo no encontrado",
          text: "No se encontró ningún artículo con el código proporcionado",
        });
      }
    }

  const handleManualArticuloChange = (selectedArticulo) => {
   
    // Filter inventory for the selected article
    let inventario = inventarios.filter(
      (inv) => inv.idArticulo === selectedArticulo.idArticulo
    );


    // Get unique tallas from inventory
    let tallas = inventario.reduce((acc, curr) => {
      if (!acc.includes(curr.talla)) {
        acc.push(curr.talla);
      }
      return acc;
    }, []);

    // Get unique colores from inventory
    let colores = inventario.reduce((acc, curr) => {
      if (!acc.includes(curr.color)) {
        acc.push(curr.color);
      }
      return acc;
    }, []);

    // Filter selectedArticulo.colores to only include colors present in inventory
    let coloresRed = selectedArticulo.colores.filter((c) =>
      colores.includes(c.name)
    );

    // Filter selectedArticulo.tallas to only include sizes present in inventory
    let tallasRed = selectedArticulo.tallas.filter((t) =>
      tallas.includes(t.name)
    );



    if (selectedArticulo) {
      setManualInput({
        articulos: selectedArticulo.idArticulo,
        articulosNombre: selectedArticulo.nombre,
        idProporcion: "",
        colores: coloresRed,
        tallas: tallasRed,
        idColor: "",
        idTalla: "",
        cantidad: 0,
      });
    }
  };
  const handleManualInputChange = (event) => {
    const { name, value } = event.target;

    setManualInput((prev) => {
      const updatedInput = {
        ...prev,
        [name]: value,
      };

      // If changing talla, update tallaNombre
      if (name === "idTalla") {
        const selectedTalla = prev.tallas.find((t) => t._id === value);
        updatedInput.tallaNombre = selectedTalla ? selectedTalla.name : "";
      }

      // Calculate total whenever cantidad changes
      if (name === "cantidad") {
        updatedInput.cantidad = parseFloat(value);
        const inventario = inventarios.find(
          (inv) =>
            inv.articulo === manualInput.idArticulo &&
            inv.color === manualInput.colores[0].name);
            if(updatedInput.cantidad > inventario.cantidad){
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `La cantidad excede tu inventario de ${inventario.cantidad}`,
                showConfirmButton: false,
              });
            }
      }

      return updatedInput;
    });
  };

  const handleManualAdd = async () => {
    if (
      !manualInput.articulosNombre ||
      !manualInput.idColor ||
      !manualInput.idTalla ||
      !manualInput.cantidad
    ) {
      return;
    }

    const selectedColor = manualInput.colores.find(
      (c) => c._id === manualInput.idColor
    );
    const selectedTalla = manualInput.tallas.find(
      (t) => t._id === manualInput.idTalla
    );


    const inventario = inventarios.find(
      (inv) =>
        inv.idArticulo === manualInput.articulos &&
        inv.color === selectedColor.name &&
        inv.talla === selectedTalla.name
    );


    const cantidadFinal = inventario
      ? Math.min(inventario.cantidad, manualInput.cantidad)
      : manualInput.cantidad;

    if (inventario) {
      const avaible = inputFields.find(
        (i) => i.idArticuloProporcion === inventario.idArticuloProporcion
      );

      if (avaible) {
        // Create new array with updated object
        const updatedFields = inputFields.map((field) => {
          if (field.idArticuloProporcion === inventario.idArticuloProporcion) {
            return {
              ...field,
              cantidad: field.cantidad + manualInput.cantidad,
              total: (field.cantidad + manualInput.cantidad) * field.precio,
            };
          }
          return field;
        });

        setInputFields(updatedFields);
         // Reset only specific fields after adding
      setManualInput((prev) => ({
        ...prev,
        idColor: "",
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        idTalla: "",
        tallaNombre: "",
        total: 0,
        idProporcion: "",
      }));

        return null;
      }
      
      const newField = {
        id: uuidv4(),
        articulos: manualInput.articulos,
        idInv: inventario._id,
        cantInv: inventario.cantidad,
        articulosNombre: manualInput.articulosNombre,

        cantidad: parseFloat(manualInput.cantidad),
        costoUnitario: inventario.costoUnitario,


        idTalla: manualInput.idTalla,
        tallaNombre: selectedTalla ? selectedTalla.name : "",
        idColor: manualInput.idColor,
        colorNombre: selectedColor ? selectedColor.name : "",
        colores: manualInput.colores,
        tallas: manualInput.tallas,
        idArticuloProporcion: inventario.idArticuloProporcion,
      };

      setInputFields((prev) => [...prev, newField]);

      // Reset only specific fields after adding
      setManualInput((prev) => ({
        ...prev,
        idColor: "",
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        idTalla: "",
        tallaNombre: "",
        total: 0,
        idProporcion: "",
      }));
    
  }
  };

  const [expandedTables, setExpandedTables] = useState({});

  const [validaBoton, setValidaBoton] = useState(true);
  

  useEffect(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTraspasosSalida = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;
    try {
      await axios
        .post(
          URL_TRASPASOS_SALIDA,
          {
            fecha,
            areaSalida,
            areaDestino,
            observaciones,
            user: user.id,
            total,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_TS,
                  {
                    traspasosSalida: data.data._id,
                    articulos: a.articulos,
                    cantidad: a.cantidad,
                    inventarios: a.idInv,
                    areaDestino,
                    costoUnitario: a.costoUnitario,
                    colores: a.idColor,
                    tallas: a.idTalla,
                    articulosProporcion:
                    a.idArticuloProporcion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos === 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Traspaso Salida",
                          detalle: `${areaSalida} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos === 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Traspaso Salida",
                      detalle: `${areaSalida} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        costoUnitario: 0,
        colores: [],
        tallas: [],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantInv >= event.target.value) {
          i[event.target.name] = event.target.value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario o Disponible!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (event.target.value.length >= 24) {
      inventarios.map((a) => {
        if (a.idArticulo === event.target.value) {
          let articulosNombre = a.codigo + " " + a.nombre;
          let colores = a.colores;
          let tallas = a.tallas;
          handleChangeInputArticuloProporcion(
            id,
            event.target.value,  // idArticulo
            articulosNombre,
            colores,
            tallas
          );
        }
        return null;
      });
    } else {
      const newInputArticulos = inputArticulos.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
          i.articulosNombre = "";
          i.cajas = 0;
          i.colores = [];
          i.tallas = [];
        }
        return i;
      });
      setInputArticulos(newInputArticulos);
    }
}
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    cantInv,
    idInv,
    costoUnitario,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = parseFloat(costoUnitario);
        i.articulosNombre = articulosNombre;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function jalaAlmacenSalida(e) {
    setAreaSalida(e);
    toggleProgreso();

    axios
      .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);

        let unicos = allInventarios.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      setModalProgreso(false);
    }, 3000);

  }


  function jalaAlmacenDestino(e) {
    setAreaDestino(e);
    toggleProgreso();

    axios
      .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);

        let unicos = allInventarios.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      setModalProgreso(false);
    }, 3000);

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        colores: [],
        tallas: [],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
      },
    ]);
  }

  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function BuscaArticuloProporcion(id, selectedArticulo) {
    inventarios.map((a) => {
      if (a.idArticulo === selectedArticulo.idArticulo) {
        let idArticulo = selectedArticulo.idArticulo;
        let articulosNombre =
          selectedArticulo.codigo + " " + selectedArticulo.nombre;
        let colores = a.colores;
        let tallas = a.tallas;
        handleChangeInputArticuloProporcion(
          id,
          idArticulo,
          articulosNombre,
          colores,
          tallas
        );
      }
    });
  }

  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.articulosNombre = articulosNombre;
        i.cajas = 0;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function agregarProporcion(
    idArticulo,
    articulosNombre,
    cajas,
    colores,
    tallas
  ) {
    if (cajas === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La cantidad debe ser mayor a 0",
      });
      return;
    }
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        const newInputFields = allArticulos
          .map((i) => {
            // Encuentra el inventario correspondiente
            const inventario = inventarios.find(
              (inv) =>
                inv.articulo === i.articulo &&
                inv.color === i.colores[0].name &&
                inv.talla === i.tallas[0].name
            );


            // Calcula la cantidad final basada en la disponibilidad del inventario
            const cantidadFinal = inventario
              ? Math.min(inventario.cantidad, i.cantidad * cajas)
              : i.cantidad * cajas;

            if (inventario) {
              return {
                id: uuidv4(),
                articulos: idArticulo,
                idInv: inventario._id,
                cantInv: inventario.cantidad,
                articulosNombre: articulosNombre,

                cantidad: cantidadFinal,
                costoUnitario: inventario.costoUnitario,

                idTalla: i.tallas[0]._id,
                tallaNombre: i.tallas[0].name,
                idColor: i.colores[0]._id,
                colorNombre: i.colores[0].name,
                colores: colores,
                tallas: tallas,
                idArticuloProporcion: i._id,
              };
            }
            return null;
          })
          .filter((i) => i !== null);

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          const tallaOrder = [
            "EECH",
            "ECH",
            "CH",
            "M",
            "G",
            "EG",
            "2EG",
            "3EG",
            "4EG",
            "5EG",
            "6EG",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "5/6",
            "7/8",
            "10/12",
            "14/16",
          ];

          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Calculate total quantities and amounts
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );

          setTotal(totalCantidad);

          return sortedInputFields;
        });


        setInputArticulos([
          {
            id: uuidv4(),
            articulos: "",
            cajas: 0,
            articulosNombre: "",
            colores: [],
            tallas: [],
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const options = articulosUnicos.map((option) => {
    const junta = option.codigo + " " + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_salida_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Salida</h3>
          <Form onSubmit={saveTraspasosSalida}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Salida</Label>
                {user.areas === AREAS_GENERAL ? (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                ) : (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas === a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                )}
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    setAreaDestino(e.target.value);
                  }}
                >
                  <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
            </Row>
            <h4 className="text-dark">
              <b>Agregar Caja</b>
            </h4>
            <>
              {/* QR Scanner Input */}
              <Row className=""></Row>

              {/* Proportion Input Section */}
              <Row className="">
                <Col md={2}>
                  <Label className="mr-sm-2">Escanea el QR</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Selecciona</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cajas</Label>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col md={2}>
                  <Input
                    name="articulos"
                    type="string"
                    value={inputArticulos[0]?.articulos}
                    onChange={(event) => {
                      BuscaArticulo(inputArticulos[0].id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={2}>
                  <Autocomplete
                    size="small"
                    value={inputArticulos[0]?.articulosNombre || ""}
                    onChange={(event, selectedArticulo) => {
                      BuscaArticuloProporcion(
                        inputArticulos[0]?.id || "template",
                        selectedArticulo
                      );
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    name="articulosNombre"
                    type="string"
                    placeholder="Articulo"
                    value={inputArticulos[0]?.articulosNombre || ""}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="cajas"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cajas"
                    value={inputArticulos[0]?.cajas || ""}
                    onChange={(event) =>
                      handleChangeInputProporcion(
                        inputArticulos[0]?.id || "template",
                        event
                      )
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="success"
                    disabled={
                      !inputArticulos[0]?.articulos || !inputArticulos[0]?.cajas
                    }
                    onClick={() =>
                      agregarProporcion(
                        inputArticulos[0]?.articulos,
                        inputArticulos[0]?.articulosNombre,
                        inputArticulos[0]?.cajas,
                        inputArticulos[0]?.colores,
                        inputArticulos[0]?.tallas
                      )
                    }
                    tabindex="-1"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>

              {/* MANUAL PRODUCT SECTION */}
              <h4 className="text-dark">
                <b>Agregar Pieza</b>
              </h4>
              <Row className="">
                <Col md={2}>
                  <Label className="mr-sm-2">Escanea el QR</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Selecciona</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Talla</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <Input
                    name="idProporcion"
                    type="string"
                    value={manualInput.idProporcion}
                    onChange={(event) => {
                      buscarArticuloSingular(manualInput.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={2}>
                  <Autocomplete
                    size="small"
                    value={manualInput?.articulosNombre || ""}
                    onChange={(event, selectedArticulo) => {
                      handleManualArticuloChange(selectedArticulo);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    type="text"
                    value={manualInput?.articulosNombre || ""}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="idColor"
                    type="select"
                    value={manualInput?.idColor || ""}
                    onChange={handleManualInputChange}
                  >
                    <option value="">Selecciona Color</option>
                    {manualInput?.colores?.map((color) => (
                      <option key={color._id} value={color._id}>
                        {color.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    name="idTalla"
                    type="select"
                    value={manualInput.idTalla || ""}
                    onChange={handleManualInputChange}
                  >
                    <option value="">Selecciona Talla</option>
                    {manualInput.tallas &&
                      manualInput.tallas.map((talla) => (
                        <option key={talla._id} value={talla._id}>
                          {talla.name}
                        </option>
                      ))}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={manualInput?.cantidad || 0}
                    onChange={handleManualInputChange}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="success"
                    onClick={handleManualAdd}
                    disabled={
                      !manualInput?.articulosNombre ||
                      !manualInput?.idColor ||
                      !manualInput?.idTalla ||
                      !manualInput?.cantidad
                    }
                    tabindex="-1"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </>

            <br />
            <hr />

            <div>
              {Object.entries(
                inputFields
                  .filter((field) => field.articulosNombre && field.articulos)
                  .reduce((acc, field) => {
                    const key = `${field.articulosNombre}`;
                    if (!acc[key]) {
                      acc[key] = {
                        articuloInfo: {
                          articulosNombre: field.articulosNombre,
                          articulos: field.articulos,
                          availableTallas: Array.from(
                            new Set(
                              inputFields
                                .filter(
                                  (f) =>
                                    f.articulosNombre === field.articulosNombre
                                )
                                .map((f) => f.tallaNombre)
                                .filter(Boolean)
                                .sort((a, b) => {
                                  const tallaOrder = [
                                    "EECH",
                                    "ECH",
                                    "CH",
                                    "S",
                                    "M",
                                    "G",
                                    "L",
                                    "EG",
                                    "XL",
                                    "2EG",
                                    "XXL",
                                    "3EG",
                                    "4EG",
                                    "5EG",
                                    "6EG",
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7",
                                    "8",
                                    "9",
                                    "10",
                                    "11",
                                    "12",
                                    "13",
                                    "14",
                                    "15",
                                    "16",
                                    "17",
                                    "18",
                                    "19",
                                    "20",
                                    "21",
                                    "22",
                                    "23",
                                    "24",
                                    "25",
                                    "26",
                                    "27",
                                    "28",
                                    "29",
                                    "30",
                                    "31",
                                    "32",
                                    "33",
                                    "34",
                                    "35",
                                    "36",
                                    "37",
                                    "38",
                                    "39",
                                    "40",
                                    "41",
                                    "42",
                                    "43",
                                    "44",
                                    "45",
                                    "46",
                                    "47",
                                    "48",
                                    "49",
                                    "50",
                                    "3/4",
                                    "5/6",
                                    "7/8",
                                    "9/10",
                                    "11/12",
                                    "10/12",
                                    "13/14",
                                    "14/16",
                                    "15/16",
                                  ];
                                  return (
                                    tallaOrder.indexOf(a) -
                                    tallaOrder.indexOf(b)
                                  );
                                })
                            )
                          ),
                        },
                        colorGroups: [],
                      };
                    }

                    const existingColorIndex = acc[key].colorGroups.findIndex(
                      (item) => item.idColor === field.idColor
                    );

                    if (existingColorIndex === -1) {
                      acc[key].colorGroups.push({
                        id: field.id,
                        articulos: field.articulos,
                        articulosNombre: field.articulosNombre,
                        idColor: field.idColor,
                        colorNombre: field.colorNombre,
                        colores: field.colores,
                        quantities: {
                          [field.tallaNombre]: {
                            cantidad: field.cantidad,
                            id: field.id,
                            cantInv: field.cantInv,
                          },
                        },
                      });
                    } else {
                      acc[key].colorGroups[existingColorIndex].quantities[
                        field.tallaNombre
                      ] = {
                        cantidad: field.cantidad,
                        id: field.id,
                        cantInv: field.cantInv,
                      };
                    }
                    return acc;
                  }, {})
              ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
                // Calculate totals for this article
                const articleTotals = colorGroups.reduce(
                  (totals, group) => {
                    const quantitySum = Object.values(group.quantities).reduce(
                      (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                      0
                    );

                    return {
                      items: totals.items + quantitySum,
                    };
                  },
                  { items: 0, price: 0 }
                );

                return (
                  <div key={articuloNombre} className="mb-8">
                    <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                      <div className="d-flex align-items-center">
                        <Button
                          size="sm"
                          className="btn mr-3"
                          color="info"
                          onClick={() =>
                            setExpandedTables((prev) => ({
                              ...prev,
                              [articuloNombre]: !prev[articuloNombre],
                            }))
                          }
                        >
                          <i
                            className={`fas fa-chevron-${
                              expandedTables[articuloNombre] ? "up" : "down"
                            }`}
                          ></i>
                        </Button>
                        <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                      </div>
                      {!expandedTables[articuloNombre] && (
                        <div className="d-flex align-items-center">
                          <span
                            className="mr-4 text-dark"
                            style={{ fontSize: "18px" }}
                          >
                            <strong style={{ fontSize: "18px" }}>
                              Piezas:
                            </strong>{" "}
                            {articleTotals.items}
                          </span>

                          <span
                            className="text-dark"
                            style={{ fontSize: "18px" }}
                          ></span>
                        </div>
                      )}
                    </div>

                    {expandedTables[articuloNombre] && (
                      <Table borderless>
                        <thead>
                          <tr>
                            <th>Articulo</th>
                            <th>Color</th>
                            {articuloInfo.availableTallas.map((talla) => (
                              <th key={talla} style={{ maxWidth: "35px" }}>
                                {talla}
                              </th>
                            ))}
                            <th style={{ maxWidth: "80px" }}>Disponible</th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {colorGroups.map((group) => (
                            <tr key={`${group.id}-${group.idColor}`}>
                              {/* Your existing row content */}
                              <td>
                                <Input
                                  name="articulo"
                                  type="string"
                                  placeholder="Articulo"
                                  value={group.articulosNombre}
                                  disabled
                                />
                              </td>
                              <td>
                                <Input
                                  name="idColor"
                                  type="select"
                                  value={group.idColor}
                                  onChange={(event) =>
                                    handleChangeInput(group.id, event)
                                  }
                                >
                                  <option value="">Selecciona</option>
                                  {group.colores?.map((a) => (
                                    <option key={a._id} value={a._id}>
                                      {a.name}
                                    </option>
                                  ))}
                                </Input>
                              </td>
                              {articuloInfo.availableTallas.map((talla) => (
                                <td key={talla} style={{ maxWidth: "60px" }}>
                                  <Input
                                    name="cantidad"
                                    type="number"
                                    min="0"
                                    step="any"
                                    value={
                                      group.quantities[talla]?.cantidad || 0
                                    }
                                    onChange={(event) =>
                                      handleChangeInputCantidad(
                                        group.quantities[talla]?.id || group.id,
                                        event
                                      )
                                    }
                                  />
                                </td>
                              ))}
                              <td style={{ maxWidth: "60px" }}>
                                <Input
                                  name="pendiente_surtir"
                                  type="text"
                                  value={
                                    Object.entries(group.quantities)
                                      .map(
                                        ([talla, data]) =>
                                          `${talla}:${data.cantInv}`
                                      )
                                      .join("|") || "0"
                                  }
                                  disabled
                                />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  disabled={inputFields.length === 1}
                                  onClick={() => handleRemoveFields(group.id)}
                                  tabindex="-1"
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                );
              })}
            </div>

            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosSalida"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosSalidaCreate;
