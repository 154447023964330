import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.css"
import './index.css';
import Routes from './components/Routes'

let tema = localStorage.getItem("tema")

if(tema === "white-content"){
  document.body.classList.add("white-content")
}

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

