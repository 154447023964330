import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function ListadoPedidosCadenas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_INSUMOS = process.env.REACT_APP_URL_INSUMOS;
  const URL_INSUMOS_PEDIDOS = process.env.REACT_APP_URL_INSUMOS_PEDIDOS;
  const URL_DETALLE_INSUMOS_PEDIDOS =
    process.env.REACT_APP_URL_DETALLE_INSUMOS_PEDIDOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [status, setStatus] = useState("Abierto");
  const [autorizadoApartado, setAutorizadoApartado] = useState("");
  const [autorizadoSurtido, setAutorizadoSurtido] = useState("");

  const [explosion, setExplosion] = useState([]);
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);
  const [idEdit, setIdEdit] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");

  const [modalInsumos, setModalInsumos] = useState(false);
  const toggleInsumos = () => setModalInsumos(!modalInsumos);
  const [insumos, setInsumos] = useState([]);
  const [idPedidoHab, setIdPedidoHab] = useState("");
  const [idClienteHab, setIdClienteHab] = useState("");
  const [fechaHab, setFechaHab] = useState(endDate);

  const [inputFieldsInsumos, setInputFieldsInsumos] = useState([
    {
      id: uuidv4(),
      idInsumos: "",
      cantidad: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_PEDIDOS}FechasCadenas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
              autorizadoApartado: a.autorizadoApartado,
              autorizadoSurtido: a.autorizadoSurtido,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              paqueteria: a.paqueterias[0].name,
              idPaqueteria: a.paqueterias[0]._id,
              areaName: a.areas[0].name,
              idArea: a.areas[0]._id,
              surtido: a.surtido,
              codigoClientes: a.clientes[0].codigo,
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              RFC: a.clientes[0].RFC,
              area: a.areas[0].name,
              fechaModificado: a.fechaModificado,
              telefono: a.clientes[0].telefono,
              editado: a.editado,
              editPedido: a.editPedido,
              autorizoApartado: a.autorizoApartado,
              autorizoSurtido: a.autorizoSurtido,
              entregaInsumos: a.entregaInsumos
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}General`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_INSUMOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInsumos = response.data;
        // Array para pagination
        let arrayTabla = allInsumos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              nombre: a.nombre,
              unidad: a.unidad,
              observaciones: a.observaciones,
              descripcion: a.descripcion,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setInsumos(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin, status]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    impuestos,
    comisionFlete,
    comisionTDC,
    idPDFOC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    autorizadoApartado,
    autorizadoSurtido,
    telefono,
    editado,
    editPedido,
    autorizoApartado,
    autorizoSurtido
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos:
                a.articulos[0].nombre +
                " " +
                a.articulos[0].lineas[0].name +
                " " +
                a.articulos[0].familias[0].name,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              autorizoApartado: a.pedidos[0].autorizoApartado,
              autorizoSurtido: a.pedidos[0].autorizoSurtido,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let autorizoApartado = "";
        if (arrayTabla[0].autorizoApartado != "") {
          autorizoApartado =
            arrayTabla[0].autorizoApartado[0].nombre +
            " " +
            arrayTabla[0].autorizoApartado[0].apellido;
        }

        let autorizoSurtido = "";
        if (arrayTabla[0].autorizoSurtido != "") {
          autorizoSurtido =
            arrayTabla[0].autorizoSurtido[0].nombre +
            " " +
            arrayTabla[0].autorizoSurtido[0].apellido;
        }

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        const data = dataFinal.map((a) => {
          // totalParcial = a.cantidad * a.precio;
          // subTotalTabla = subTotalTabla + totalParcial
          // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.surtido),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 20, 5);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`No. Pedido:`, 35, 20);
        doc.text(`${numero}`, 60, 20);

        doc.text(`Ejecutivo:`, 120, 15);
        doc.text(`${colaboradores}`, 150, 15);
        doc.text(`Aut. Apartado:`, 120, 20);
        doc.text(`${autorizadoApartado} ${autorizoApartado}`, 150, 20);
        doc.text(`Aut. Surtido:`, 120, 25);
        doc.text(`${autorizadoSurtido}  ${autorizoSurtido}`, 150, 25);

        doc.setFontSize(8);
        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 150, 32);
        doc.text(
          `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,
          10,
          42
        );
        doc.text(
          `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,
          10,
          47
        );

        doc.autoTable({
          head: [
            ["Articulo", "Cajas", "Cantidad", "Surtido", "Precio", "Total"],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 50,
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              new Intl.NumberFormat("en-US").format(TK) + " Piezas",
              "",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(TG),
            ],
            [
              "",
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              "",
              "",
              "",
              "",
              "Comision Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Almacen", field: "areaName", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Explosion", field: "explosion", sortable: false },
    { name: "Insumos", field: "insumos", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.areaName.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizadoSurtido) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoSurtido.includes(autorizadoSurtido)
      );
    }
    if (autorizadoApartado) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoApartado.includes(autorizadoApartado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    status,
    selectedFechaInicio,
    selectedFechaFin,
    autorizadoApartado,
    autorizadoSurtido,
    selectedArea,
  ]);

  async function requisicion(id, numero, cantidad) {
    setExplosion([]);
    toggleProgreso();
    setIdEdit(id);
    setNumeroPedido(numero);

    let temp2 = [];

    axios
      .get(`${URL_EXPLOSION}Cliente/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allExplosion = response.data;
        let arrayExp = allExplosion
          .map((b) => {
            temp2.push({
              insumo: b.insumos[0].nombre,
              cantidad: b.cantidad * cantidad,
            });
          })
          .filter(function (el) {
            return el != null;
          });
      })
      .catch((err) => {
        console.log(err);
      });

    setExplosion(temp2);
    setTimeout(() => {
      setModalProgreso(false);
      toggleExplosion();
    }, 1000);
  }

  function jalaInsumos(idPedido, idCliente) {
    setIdPedidoHab(idPedido);
    setIdClienteHab(idCliente);
    toggleInsumos();
  }

  function saveInsumos(event) {
    event.preventDefault();
    let totalRollos = inputFieldsInsumos.length;
    axios
      .post(
        URL_INSUMOS_PEDIDOS,
        {
          pedidos: idPedidoHab,
          fecha: fechaHab,
          clientes: idClienteHab,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data2) => {
        inputFieldsInsumos.map((a) => {
          if (a.cantidad != 0) {
            axios
              .post(
                URL_DETALLE_INSUMOS_PEDIDOS,
                {
                  cantidad: a.cantidad,
                  insumosPedidos: data2.data._id,
                  pedidos: idPedidoHab,
                  insumos: a.idInsumos,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Insumos",
                        detalle: `Pedido ${idPedidoHab}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              });
          } else {
            totalRollos = totalRollos - 1;
            if (totalRollos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Salida de Insumos",
                    detalle: `Pedido ${idPedidoHab}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  const handleChangeInputInsumos = (id, event) => {
    const newInputFieldsInsumos = inputFieldsInsumos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsInsumos(newInputFieldsInsumos);
  };

  const handleRemoveFieldsHabilitaccion = (id) => {
    const values = [...inputFieldsInsumos];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsInsumos(values);
  };

  const handleAddFieldsInsumos = () => {
    setInputFieldsInsumos([
      ...inputFieldsInsumos,
      {
        id: uuidv4(),
        idInsumos: "",
        cantidad: 0,
      },
    ]);
  };

  function PDFIns(idPedido, numero, cliente) {
    let total = 0
    axios
      .get(`${URL_DETALLE_INSUMOS_PEDIDOS}/pedido/${idPedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((hab) => {
        let allHabilitacionCorte = hab.data;
        if(allHabilitacionCorte.length==0){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se han entregado Insumos",
          });
        }else{
        let arrayTablaHab = allHabilitacionCorte
          .map((a) => {
            total = total + (a.insumos[0].costo * a.cantidad)
            return [
              a.insumosPedidos[0].fecha,
              a.insumos[0].nombre + " " + a.insumos[0].descripcion,
              a.cantidad,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.insumos[0].costo),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.insumos[0].costo * a.cantidad),
            ];
          })
          .filter(function (el) {
            return el != null;
          });

        const dataPDFHab = Object.values(arrayTablaHab);

        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 5, 33, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 150, 15, 35, 15);
        doc.setFontSize(12);
        doc.text(`Pedido ${numero}`, 15, 15);
        doc.text(`Cliente ${cliente}`, 15, 20);

        doc.text(`Habilitacion`, 15, 28);
        doc.autoTable({
          head: [["Fecha", "Nombre", "Cantidad", "Precio", "Total"]],
          body: dataPDFHab,
          startY: 33,
          foot: [
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`InsumosPedido-${numero}.pdf`);}
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function entregarInsumos(idPedido) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Sera marcado con Insumos Entregados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${idPedido}`,
            {
              entregaInsumos: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se entregaron Insumos",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function desEntregarInsumos(idPedido) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Sera marcado con Insumos No Entregados!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${idPedido}`,
            {
              entregaInsumos: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se entregaron Insumos",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_cadenas ? (
        <div className="card col-12">
          <>
            <Row>
              <Col md={8}>
                <Button
                  size="sm"
                  href="/MenuCadenas"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Col>
              <Col sm={4}>
                <ButtonGroup id="logoutBoton">
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    onClick={PDFTabla}
                  >
                    PDF <i class="far fa-file-pdf"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    onClick={toggleMail}
                    color="info"
                  >
                    eMail <i class="fas fa-at"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="primary"
                    onClick={excel}
                  >
                    Excel <i class="far fa-file-excel"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
              <ModalHeader toggle={toggleMail}>
                <h4>Enviar Listado Pedidos</h4>
              </ModalHeader>
              <ModalBody>
                <Label className="mr-sm-2">Email</Label>
                <Input
                  className="col-sm-12"
                  type="text"
                  value={mailTo}
                  required
                  onChange={(e) => {
                    setMailTo(e.target.value);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button size="sm" color="success" onClick={enviaMail}>
                  Enviar
                </Button>
              </ModalFooter>
            </Modal>
            <h3 align="center">Pedidos</h3>
            <div className="row">
              <div className="col-md-8">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
              <div className="col-md-4 d-flex flex-row-reverse">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  required
                  onChange={(e) => {
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  required
                  onChange={(e) => {
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Label>Autorizado</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={autorizadoApartado}
                  required
                  onChange={(e) => {
                    setAutorizadoApartado(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>

              <Col md={2}>
                <Label>Autorizado Surtir</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={autorizadoSurtido}
                  required
                  onChange={(e) => {
                    setAutorizadoSurtido(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>

              <Col md={2}>
                <Label>Status</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={status}
                  required
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  {/* <option value="">Selecciona</option> */}
                  <option value="Abierto">Abierto</option>
                  <option value="Cerrado">Cerrado</option>
                </Input>
              </Col>
            </Row>
            <br />
            <Table size="sm" striped borderless className="table-responsive-xl">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tr>
                <td></td>
                <td></td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                              if (a.esTienda == "No"){
                                return <option value={a._id}>{a.name}</option>;
                              }
                        })}
                    </Input>
                  </td>
              </tr>
              <tbody>
                {commentsData.map((a) => {
                  if (a.activo == "No") {
                    return (
                      <tr style={{ backgroundColor: "#ed8c8c" }}>
                        <td>{a.numero}</td>
                        <td>{a.fecha}</td>
                        <td>{a.clientes}</td>
                        <td>{a.colaboradores}</td>
                        <td>{a.areaName}</td>
                        <td>CANCELADO</td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.colaboradores,
                                a.idClientes,
                                a.clientes,
                                a.total_cantidad,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a.impuestos,
                                a.comisionFlete,
                                a.comisionTDC,
                                a._id,
                                a.calle,
                                a.numero_ext,
                                a.numero_int,
                                a.pais,
                                a.estado,
                                a.ciudad,
                                a.delegacion,
                                a.colonia,
                                a.cp,
                                a.RFC,
                                a.paqueteria,
                                a.area,
                                a.codigoClientes,
                                a.autorizadoApartado,
                                a.autorizadoSurtido,
                                a.telefono,
                                a.editado,
                                a.editPedido,
                                a.autorizoApartado,
                                a.autorizoSurtido
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              requisicion(
                                a.idClientes,
                                a.numero,
                                a.total_cantidad
                              )
                            }
                          >
                            <i class="fas fa-tag"></i>
                          </Button>
                          </td>
                          <td>
                        
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) => PDFIns(a._id, a.numero, a.clientes)}
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          {a.entregaInsumos == "No"?(
                            <>
                             <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) => jalaInsumos(a._id, a.idClientes)}
                          >
                            <i class="fas fa-sign-out-alt"></i>
                          </Button>
                            <Button
                            color="primary"
                            id="Baja"
                            onClick={(e )=> entregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-check-circle"></i>
                            </Button>
                            </>
                          ):(
                            <Button
                            color="danger"
                            id="Baja"
                            onClick={(e )=> desEntregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                         
                        </td>
                      </tr>
                    );
                  } else if (a.autorizadoSurtido == "Si") {
                    return (
                      <tr style={{ backgroundColor: "#64e379" }}>
                        <td>{a.numero}</td>
                        <td>{a.fecha}</td>
                        <td>{a.clientes}</td>
                        <td>{a.colaboradores}</td>
                        <td>{a.areaName}</td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(
                            a.total_cantidad
                          )}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(a.total_general)}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.colaboradores,
                                a.idClientes,
                                a.clientes,
                                a.total_cantidad,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a.impuestos,
                                a.comisionFlete,
                                a.comisionTDC,
                                a._id,
                                a.calle,
                                a.numero_ext,
                                a.numero_int,
                                a.pais,
                                a.estado,
                                a.ciudad,
                                a.delegacion,
                                a.colonia,
                                a.cp,
                                a.RFC,
                                a.paqueteria,
                                a.area,
                                a.codigoClientes,
                                a.autorizadoApartado,
                                a.autorizadoSurtido,
                                a.telefono,
                                a.editado,
                                a.editPedido,
                                a.autorizoApartado,
                                a.autorizoSurtido
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              requisicion(
                                a.idClientes,
                                a.numero,
                                a.total_cantidad
                              )
                            }
                          >
                            <i class="fas fa-tag"></i>
                          </Button>

                          </td>
                          <td>
                         
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) => PDFIns(a._id, a.numero, a.clientes)}
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          {a.entregaInsumos == "No"?(
                            <>
                             <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) => jalaInsumos(a._id, a.idClientes)}
                          >
                            <i class="fas fa-sign-out-alt"></i>
                          </Button>
                            <Button
                            color="primary"
                            id="Baja"
                            onClick={(e )=> entregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-check-circle"></i>
                            </Button>
                            </>
                           ):(
                            <Button
                            color="danger"
                            id="Baja"
                            onClick={(e )=> desEntregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>{a.numero}</td>
                        <td>{a.fecha}</td>
                        <td>{a.clientes}</td>
                        <td>{a.colaboradores}</td>
                        <td>{a.areaName}</td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(
                            a.total_cantidad
                          )}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(a.total_general)}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.colaboradores,
                                a.idClientes,
                                a.clientes,
                                a.total_cantidad,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a.impuestos,
                                a.comisionFlete,
                                a.comisionTDC,
                                a._id,
                                a.calle,
                                a.numero_ext,
                                a.numero_int,
                                a.pais,
                                a.estado,
                                a.ciudad,
                                a.delegacion,
                                a.colonia,
                                a.cp,
                                a.RFC,
                                a.paqueteria,
                                a.area,
                                a.codigoClientes,
                                a.autorizadoApartado,
                                a.autorizadoSurtido,
                                a.telefono,
                                a.editado,
                                a.editPedido,
                                a.autorizoApartado,
                                a.autorizoSurtido
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              requisicion(
                                a.idClientes,
                                a.numero,
                                a.total_cantidad
                              )
                            }
                          >
                            <i class="fas fa-tag"></i>
                          </Button>

                          </td>
                          <td>
                         
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) => PDFIns(a._id, a.numero, a.clientes)}
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          {a.entregaInsumos == "No"?(
                            <>
                             <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) => jalaInsumos(a._id, a.idClientes)}
                          >
                            <i class="fas fa-sign-out-alt"></i>
                          </Button>
                            <Button
                            color="primary"
                            id="Baja"
                            onClick={(e )=> entregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-check-circle"></i>
                            </Button>
                            </>
                           ):(
                            <Button
                            color="danger"
                            id="Baja"
                            onClick={(e )=> desEntregarInsumos(a._id)}
                            size="sm"
                            >
                            <i class="far fa-times-circle"></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
            <div className="col-md-6">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>Explosion</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Insumo</th>
              <th>Cantidad</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.insumo}</td>
                <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
              </tr>
            ))}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalInsumos} toggle={toggleInsumos}>
        <ModalHeader toggle={toggleInsumos}>
          <h3>Entregar Insumos</h3>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveInsumos}>
            <Row>
              <Col md={6}>
                <Label>Fecha</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={fechaHab}
                  onChange={(event) => {
                    setFechaHab(event.target.value);
                  }}
                ></Input>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Insumo</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFieldsInsumos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="idInsumos"
                      type="select"
                      value={inputField.idInsumos}
                      onChange={(event) => {
                        handleChangeInputInsumos(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {insumos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.nombre}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      onChange={(event) => {
                        handleChangeInputInsumos(inputField.id, event);
                      }}
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsInsumos.length === 1}
                      onClick={() =>
                        handleRemoveFieldsHabilitaccion(inputField.id)
                      }
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsInsumos}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoPedidosCadenas;
