import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosEntradaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_TRASPASOS_ENTRADA = process.env.REACT_APP_URL_TRASPASOS_ENTRADA;
  const URL_ARTICULOS_TE = process.env.REACT_APP_URL_ARTICULOS_TE;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaDestino, setAreaDestino] = useState(user.areas);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [traspasosSalida, setTraspasosSalida] = useState([]);
  const [selectedTraspasoSalida, setSelectedTraspasoSalida] = useState("");
  const [expandedTables, setExpandedTables] = useState({});

  
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();


  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
     
  }, []);

  const saveTraspasosEntrada = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalArticulos = inventarios.length + 1
    try {
      await axios
        .post(
          URL_TRASPASOS_ENTRADA,
          {
            fecha,
            traspasosSalida:selectedTraspasoSalida,
            observaciones,
            user: user.id,
            total
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inventarios.map((a) => {
            axios.post(
              URL_ARTICULOS_TE,
              {
                traspasosEntrada: data.data._id,
                articulos: a.idArticulo,
                cantidad: parseFloat(a.cantidad),
                areaDestino: a.areaDestino,
                tamanoCaja: "0",
                pesoCaja: 0,
                costoUnitario: a.costoUnitario,
                colores: a.idColor,
                tallas: a.idTalla,
                articulosProporcion: a.articulosProporcion, 
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              totalArticulos = totalArticulos -1
              if(totalArticulos === 0){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Traspaso Entrada",
                  detalle: `${areaDestino} ${total}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
              }
            })
          })

          // Actualizamos Traspasos Salida
            axios.patch(
              `${URL_TRASPASOS_SALIDA}/${selectedTraspasoSalida}`,
              {
                recibida: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              totalArticulos = totalArticulos -1
              if(totalArticulos === 0){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Traspaso Entrada",
                  detalle: `${areaDestino} ${total}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
              }
            })

        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
 

  function jalaAlmacen(e) {
    setAreaDestino(e);

    axios
    .get(`${URL_TRASPASOS_SALIDA}/almacen/${e}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allTraspasosSalida = res.data;
      setTraspasosSalida(allTraspasosSalida);
    })
    .catch((err) => {
      console.log(err);
    });

    setInventarios([])

  }

  function jalaArticulosSalida(e) {
    setSelectedTraspasoSalida(e);

    axios
      .get(`${URL_ARTICULOS_TS}/traspasoSalida/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        let arrayTabla = allInventarios
        .map((a) => {
          return {
            _id: a._id,
            articulo: a.articulos[0].codigo + " " + a.articulos[0].nombre + " - " + a.colores[0].name + " - " + a.tallas[0].name,
            idArticulo: a.articulos[0]._id,
            cantidad: a.cantidad,
            areaDestino: a.traspasosSalida[0].areaDestino[0]._id,
            traspasosSalida: a.traspasosSalida[0]._id,
            tamanoCaja: a.inventarios[0].tamanoCaja,
            pesoCaja: a.inventarios[0].pesoCaja,
            costoUnitario: a.inventarios[0].costoUnitario,
            idColor: a.colores[0]._id,
            idTalla: a.tallas[0]._id,
            colorName: a.colores[0].name,
            tallaName: a.tallas[0].name,
            articulosProporcion: a.articulosProporcion[0]._id,

          };
        })
        .filter(function (el) {
          return el != null;
        });

      let dataFinal = Object.values(arrayTabla);

         

        setInventarios(dataFinal);
        console.log(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotal(TC);
      })
      .catch((err) => {
        console.log(err);
      })

  }

  const handleChangeInput = (id, event) => {
    const newInventarios = inventarios.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInventarios(newInventarios);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_entrada_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Entrada</h3>
          <Form onSubmit={saveTraspasosEntrada}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                { user.areas === AREAS_GENERAL ? (
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    jalaAlmacen(e.target.value);
                  }}
                >
                <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a._id != AREAS_GENERAL){
                      return <option value={a._id}>{a.name}</option>
                      }})}
                </Input>
                ):(
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                 onChange={(e) => {
                  jalaAlmacen(e.target.value);
                }}
                >
                <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (user.areas === a._id) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>)}
                
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Salida</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTraspasoSalida}
                  required
                  onChange={(e) => {
                    jalaArticulosSalida(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Salida</option>
                  {traspasosSalida
                    .sort((a, b) => (a.idTraspasosSalida < b.idTraspasosSalida ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.idTraspasosSalida} / {a.areaSalida[0].name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            {Object.entries(
  inventarios
    .reduce((acc, item) => {
      const articuloBase = item.articulo.split(' - ')[0];
      
      if (!acc[articuloBase]) {
        acc[articuloBase] = {
          articuloInfo: {
            nombre: articuloBase,
            availableTallas: Array.from(
              new Set(
                inventarios
                  .filter(f => f.articulo.startsWith(articuloBase))
                  .map(f => f.tallaName)
                  .sort()
              )
            ),
          },
          colorGroups: [],
        };
      }

      const existingColorIndex = acc[articuloBase].colorGroups.findIndex(
        group => group.idColor === item.idColor
      );

      if (existingColorIndex === -1) {
        acc[articuloBase].colorGroups.push({
          id: item._id,
          articulos: item.articulo,
          idColor: item.idColor,
          colorName: item.colorName,
          colores: item.colores,
          quantities: {
            [item.tallaName]: {
              cantidad: item.cantidad,
              id: item._id,
            },
          },
        });
      } else {
        acc[articuloBase].colorGroups[existingColorIndex].quantities[item.tallaName] = {
          cantidad: item.cantidad,
          id: item._id,
        };
      }
      return acc;
    }, {})
).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
  const articleTotals = colorGroups.reduce(
    (total, group) => {
      const quantitySum = Object.values(group.quantities).reduce(
        (sum, q) => sum + (parseFloat(q.cantidad) || 0),
        0
      );
      return total + quantitySum;
    },
    0
  );

  return (
    <div key={articuloNombre} className="mb-8">
      <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            className="btn mr-3"
            color="info"
            onClick={() =>
              setExpandedTables((prev) => ({
                ...prev,
                [articuloNombre]: !prev[articuloNombre],
              }))
            }
          >
            <i
              className={`fas fa-chevron-${
                expandedTables[articuloNombre] ? "up" : "down"
              }`}
            ></i>
          </Button>
          <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
        </div>
        {expandedTables[articuloNombre] && (
          <div className="d-flex align-items-center">
            <span className="mr-4 text-dark" style={{ fontSize: "18px" }}>
              <strong style={{ fontSize: "18px" }}>Piezas: </strong>
              {articleTotals}
            </span>
          </div>
        )}
      </div>

      {!expandedTables[articuloNombre] && (
        <Table borderless>
          <thead>
            <tr>
              <th>Articulo</th>
              <th>Color</th>
              {articuloInfo.availableTallas.map((talla) => (
                <th key={talla} style={{ maxWidth: "35px" }}>
                  {talla}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {colorGroups.map((group) => (
              <tr key={`${group.id}-${group.idColor}`}>
                <td>
                  <Input
                    name="articulo"
                    type="string"
                    placeholder="Articulo"
                    value={group.articulos}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    name="idColor"
                    type="text"
                    value={group.colorName}
                    disabled
                  />
                </td>
                {articuloInfo.availableTallas.map((talla) => (
                  <td key={talla} style={{ maxWidth: "60px" }}>
                    <Input
                      name="cantidad"
                      type="number"
                      value={group.quantities[talla]?.cantidad || 0}
                      disabled
                    />
                  </td>
                ))}
                <td style={{ width: "40px" }}>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled
                    tabIndex="-1"
                  >
                    <i className="fas fa-minus"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
})}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosEntrada"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosEntradaCreate;
