import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StatusMultiple from "../../StatusMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidosWhatsapp() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_PROPORCION =
    process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [paqueterias, setPaqueterias] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [paqueteria, setPaqueteria] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("2024-01-01");
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [surtido, setSurtido] = useState("");
  const [areaSurtido, setAreaSurtido] = useState("");
  const [nombreClienteEdit, setNombreClienteEdit] = useState("");
  const [fechaModificado, setFechaModificado] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [expandedTables, setExpandedTables] = useState({});

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      precio: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);
  const [selectedAreaEditVieja, setSelectedAreaEditVieja] = useState("");
  const [selectedAreaEdit, setSelectedAreaEdit] = useState("");
  const [descuentoEdit, setDescuentoEdit] = useState("");
  const [descuentoNumeroEdit, setDescuentoNumeroEdit] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      precio: 0,
      total: 0,
      colores: [],
      tallas: [],
    },
  ]);
  useEffect(() => {
    ActualizaTotales();
  }, [inputFields, ActualizaTotales]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [status, setStatus] = useState("");
  const [autorizadoApartado, setAutorizadoApartado] = useState("");
  const [autorizadoSurtido, setAutorizadoSurtido] = useState("");

  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [AbrirEditar, setAbrirEditar] = useState(false);

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_PEDIDOS}WhatsappFechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
              autorizadoApartado: a.autorizadoApartado,
              autorizadoSurtido: a.autorizadoSurtido,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              paqueteria: a.paqueterias[0].name,
              idPaqueteria: a.paqueterias[0]._id,
              areaName: a.areas[0].name,
              idArea: a.areas[0]._id,
              surtido: a.surtido,
              codigoClientes: a.clientes[0].codigo,
              alias: "Principal",
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              RFC: a.clientes[0].RFC,
              area: a.areas[0].name,
              fechaModificado: a.fechaModificado,
              telefono: a.clientes[0].telefono,
              editado: a.editado,
              editPedido: a.editPedido,
              autorizoApartado: a.autorizoApartado,
              autorizoSurtido: a.autorizoSurtido,
              descuento: a.descuento,
              creado: a.creado[0].nombre + " " + a.creado[0].apellido,
              fechaHora:
                moment(a.createdAt).utcOffset("-0600").format("DD-MM-YYYY") +
                " " +
                moment(a.createdAt).utcOffset("-0600").format("HH:mm:ss"),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const options = articulos.map((option) => {
    const junta = option.codigo;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });
  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };
  function BuscaArticuloProporcion(id, selectedArticulo) {
    const foundArticle = articulos.find((a) => a._id === selectedArticulo._id);

    if (foundArticle) {
      const idArticulo = selectedArticulo._id;
      const articulosNombre = `${selectedArticulo.codigo} ${selectedArticulo.nombre}`;
      const precio = foundArticle.venta;
      const colores = foundArticle.colores;
      const tallas = foundArticle.tallas;

      handleChangeInputArticuloProporcion(
        id,
        idArticulo,
        precio,
        articulosNombre,
        colores,
        tallas
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Artículo no encontrado",
        text: "No se encontró ningún artículo con el código proporcionado",
      });
    }
  }
  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    precio,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        i.articulosNombre = articulosNombre;
        i.cajas = 0;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
    ActualizaTotales();
  };
  function agregarProporcion(
    idArticulo,
    articulosNombre,
    precio,
    cajas,
    colores,
    tallas
  ) {
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;

        const newInputFields = allArticulos.map((i) => {
          return {
            id: uuidv4(),
            articulos: idArticulo,
            articulosNombre: articulosNombre,
            precio: precio,
            precioOriginal: precio,
            cantidad: cajas * i.cantidad,
            idTalla: i.tallas[0]._id,
            tallaNombre: i.tallas[0].name,
            idColor: i.colores[0]._id,
            colorNombre: i.colores[0].name,
            total: cajas * i.cantidad * precio,
            colores: colores,
            tallas: tallas,
            // Add a groupKey to help with visual grouping
            groupKey: `${articulosNombre}-${i.colores[0].name}`,
            isFirstInGroup: false, // We'll set this after sorting
          };
        });

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          // Sort using your existing order
          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            const tallaOrder = [
              "EECH",
              "ECH",
              "CH",
              "S",
              "M",
              "G",
              "L",
              "EG",
              "XL",
              "2EG",
              "XXL",
              "3EG",
              "4EG",
              "5EG",
              "6EG",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
              "31",
              "32",
              "33",
              "34",
              "35",
              "36",
              "37",
              "38",
              "39",
              "40",
              "41",
              "42",
              "43",
              "44",
              "45",
              "46",
              "47",
              "48",
              "49",
              "50",
              "3/4",
              "5/6",
              "7/8",
              "9/10",
              "11/12",
              "10/12",
              "13/14",
              "14/16",
              "15/16",
            ];
            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Mark first items in each group
          let currentGroup = "";
          sortedInputFields.forEach((field, index) => {
            if (field.groupKey !== currentGroup) {
              field.isFirstInGroup = true;
              currentGroup = field.groupKey;
            } else {
              field.isFirstInGroup = false;
            }
          });

          // Calculate totals
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );
          setTotalCantidad(totalCantidad);

          let importes = sortedInputFields.map((c) => parseFloat(c.total));
          let TG = importes.reduce((t, total) => t + total, 0);
          setSubTotal(TG);

          if (selectedImpuesto !== 0) {
            setIva((selectedImpuesto * TG) / 100);
          } else {
            setIva(0);
          }

          let temp1 = (TG * descuentoEdit) / 100;
          setDescuentoEdit(temp1);

          let totTemp =
            (selectedImpuesto * TG) / 100 +
            TG +
            parseFloat(comisionTDC) +
            parseFloat(comisionFlete) -
            parseFloat(temp1);
          setTotalGeneral(totTemp);

          return sortedInputFields;
        });

        setInputArticulos([
          {
            id: uuidv4(),
            articulos: "",
            cajas: 0,
            precio: 0,
            articulosNombre: "",
            colores: [],
            tallas: [],
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [manualInput, setManualInput] = useState({
    articulosNombre: "",
    articulos: "",
    idColor: "",
    idTalla: "",
    tallaNombre: "",
    cantidad: 0,
    precio: 0,
    colores: [],
    tallas: [],
    total: 0,
  });

  const handleManualArticuloChange = (selectedArticulo) => {
    if (selectedArticulo) {
      setManualInput({
        articulos: selectedArticulo._id,
        articulosNombre: selectedArticulo.nombre,
        precio: selectedArticulo.venta,
        colores: selectedArticulo.colores,
        tallas: selectedArticulo.tallas,
        idColor: "",
        idTalla: "",
        cantidad: 0,
      });
    }
  };
  const handleManualInputChange = (event) => {
    const { name, value } = event.target;

    setManualInput((prev) => {
      const updatedInput = {
        ...prev,
        [name]: value,
      };

      // If changing talla, update tallaNombre
      if (name === "idTalla") {
        const selectedTalla = prev.tallas.find((t) => t._id === value);
        updatedInput.tallaNombre = selectedTalla ? selectedTalla.name : "";
      }

      // Calculate total whenever cantidad changes
      if (name === "cantidad") {
        updatedInput.cantidad = parseFloat(value);
        updatedInput.total = parseFloat(value) * prev.precio;
      }

      return updatedInput;
    });
  };

  const handleManualAdd = async () => {
    if (
      !manualInput.articulosNombre ||
      !manualInput.idColor ||
      !manualInput.idTalla ||
      !manualInput.cantidad
    ) {
      return;
    }

    const selectedColor = manualInput.colores.find(
      (c) => c._id === manualInput.idColor
    );
    const selectedTalla = manualInput.tallas.find(
      (t) => t._id === manualInput.idTalla
    );

    const newField = await {
      id: uuidv4(),
      articulos: manualInput.articulos,
      articulosNombre: manualInput.articulosNombre,
      precio: manualInput.precio,
      precioOriginal: manualInput.precio,
      cantidad: parseFloat(manualInput.cantidad),
      idTalla: manualInput.idTalla,
      tallaNombre: selectedTalla ? selectedTalla.name : "",
      idColor: manualInput.idColor,
      colorNombre: selectedColor ? selectedColor.name : "",
      total: manualInput.cantidad * manualInput.precio,
      colores: manualInput.colores,
      tallas: manualInput.tallas,
    };

    setInputFields((prev) => [...prev, newField]);

    // Reset only specific fields after adding
    setManualInput((prev) => ({
      ...prev,
      idColor: "",
      articulos: "",
      articulosNombre: "",
      cantidad: 0,
      idTalla: "",
      tallaNombre: "",
      precio: 0,
      total: 0,
    }));
  };
  async function buscarArticuloSingular(id, event) {
    if (event.target.value.length >= 24) {
      try {
        const res = await axios.get(
          `${URL_ARTICULOS_PROPORCION}/${event.target.value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("app_token")}`,
            },
          }
        );
        console.log("res", res.data);
        if (res.data) {
          const idArticulo = res.data._id;
          const articulosNombre = `${res.data.articulos[0].codigo} ${res.data.articulos[0].nombre}`;
          const precio = res.data.articulos[0].venta;
          const colores = res.data.colores;
          const coloresId = res.data.colores[0]._id;
          const tallas = res.data.tallas;
          const tallasId = res.data.tallas[0]._id;

          console.log("idArticulo", idArticulo);

          setManualInput({
            articulos: idArticulo,
            articulosNombre: articulosNombre,
            precio: precio,
            colores: colores,
            tallas: tallas,
            idColor: coloresId,
            idTalla: tallasId,
            cantidad: 1,
            total: precio,
          });
        }
      } catch (error) {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Artículo no encontrado",
          text: "No se encontró ningún artículo con el código proporcionado",
        });
      }
    }
  }


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio === 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin === 0 || selectedFechaFin >= a.fecha) &&
        (status === 0 || status === a.status) &&
        (autorizadoApartado === 0 ||
          autorizadoApartado === a.autorizadoApartado) &&
        (autorizadoSurtido === 0 ||
          autorizadoSurtido === a.autorizadoSurtido) &&
        (selectedCliente === 0 || selectedCliente === a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio === 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin === 0 || selectedFechaFin >= a.fecha) &&
        (status === 0 || status === a.status) &&
        (autorizadoApartado === 0 ||
          autorizadoApartado === a.autorizadoApartado) &&
        (autorizadoSurtido === 0 ||
          autorizadoSurtido === a.autorizadoSurtido) &&
        (selectedCliente === 0 || selectedCliente === a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio === 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin === 0 || selectedFechaFin >= a.fecha) &&
        (status === 0 || status === a.status) &&
        (autorizadoApartado === 0 ||
          autorizadoApartado === a.autorizadoApartado) &&
        (autorizadoSurtido === 0 ||
          autorizadoSurtido === a.autorizadoSurtido) &&
        (selectedCliente === 0 || selectedCliente === a.idClientes)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 38, 18);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    idClientes,
    total_cantidad,
    total_general,
    observaciones,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    idPaqueteria,
    idEdit,
    idArea
  ) {
    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setSelectedClienteEdit(idClientes);
    setTotalCantidad(total_cantidad);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setSelectedAreaEditVieja(idArea);
    setSelectedAreaEdit(idArea);
    setPaqueteria(idPaqueteria);
    setIdEdit(idEdit);

    toggleEdit();
  }


  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    impuestos,
    comisionFlete,
    comisionTDC,
    idPDFOC,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    autorizadoApartado,
    autorizadoSurtido,
    telefono,
    editado,
    editPedido,
    autorizoApartado,
    autorizoSurtido,
    descuento,
    creado,
    fechaHora
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo,
              idArticulo: a.articulos[0]._id,
              observaciones: a.observaciones,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            total: 0,
            pendiente_surtir: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].idArticulo = item.idArticulo;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          groups[val].total += item.total;
          groups[val].pendiente_surtir += item.pendiente_surtir;
          groups[val].cajas += item.cajas;

          return groups;
        }, []);

        let dataAgrupado = Object.values(agrupado);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        let temp1 = (TG * descuento) / 100;

        const data = dataAgrupado
          .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
          .map((a) => {
            // totalParcial = a.cantidad * a.precio;
            // subTotalTabla = subTotalTabla + totalParcial
            // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
            return [
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              // new Intl.NumberFormat("en-US").format(a.surtido),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.precio),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.total),
            ];
          });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF();
        // const doc = new jsPDF("landscape","mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 20, 10);

        doc.setFontSize(16);
        doc.text(`No. Pedido: ${numero}`, 35, 15);
        // doc.text(`Fecha:`, 35, 15);
        // doc.text(`${fecha}`, 60, 15);

        doc.setFontSize(12);
        doc.text(`Ejecutivo:`, 120, 15);
        doc.text(`${colaboradores}`, 150, 15);
        doc.text(`Aut. Apartado:`, 120, 20);
        doc.text(`${autorizadoApartado}`, 150, 20);
        doc.text(`Aut. Surtido:`, 120, 25);
        doc.text(`${autorizadoSurtido}`, 150, 25);

        doc.setFontSize(8);
        doc.text(`Creado por: ${creado}`, 120, 32);
        doc.text(`Fecha creado: ${fechaHora}`, 120, 37);

        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 10, 37);
        doc.text(
          `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int}`,
          10,
          47
        );
        doc.text(`Telefono: ${telefono}`, 10, 52);
        doc.text(`Edo: ${estado}, Ciudad: ${ciudad}, CP: ${cp}`, 10, 57);
        doc.text(`Del: ${delegacion}, Col: ${colonia}`, 10, 62);

        doc.setFontSize(12);
        doc.text(`Paqueteria: ${paqueteria}`, 120, 50);
        doc.text(`Almacen: ${area}`, 120, 57);
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cant.",
              // "Surt.",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
          },
          startY: 70,

          margin: { left: 10, right: 10 },
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TK) + "Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(TG),
            ],
            [
              "",
              "",
              `IVA`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              "",
              "",
              `Desc ${descuento}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(-temp1),
            ],
            [
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${idEdit}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${idEdit}`;

    if (selectedAreaEditVieja !== selectedAreaEdit) {
      Swal.fire({
        title: "Estas seguro?",
        text: "Estas cambiando de Alamacen y se liberará el inventario!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cambiar de Almacen!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .patch(
              URL_AUTORIZADO,
              {
                // autorizadoApartado: "No",
                // autorizadoSurtido: "No",
                autorizoApartado: user.id,
                observaciones,
                editPedido: user.id,
                paqueterias: paqueteria,
                areas: selectedAreaEdit,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              // axios
              //   .patch(
              //     URL_AUTORIZADO_HIJO,
              //     {
              //       autorizadoApartado: "No",
              //       autorizadoSurtido: "No",
              //     },
              //     {
              //       headers: {
              //         Authorization: `Bearer: ${localStorage.getItem(
              //           "app_token"
              //         )}`,
              //       },
              //     }
              //   )
              //   .then(() => {
              //     axios.post(
              //       URL_LOGS,
              //       {
              //         tipo: "Cambio Alamcen Pedido",
              //         detalle: `${numero_pedido} ${fecha}`,
              //         user: user.id,
              //       },
              //       {
              //         headers: {
              //           Authorization: `Bearer: ${localStorage.getItem(
              //             "app_token"
              //           )}`,
              //         },
              //       }
              //     );
              //     toggleEdit();
              //     Swal.fire("Good job!", "Actualizado con exito", "success");
              //     setTimeout(() => {
              //       window.location.reload();
              //     }, 1000);
              //   })
              //   .catch((error) => {
              //     Swal.fire({
              //       icon: "error",
              //       title: "Oops...",
              //       text: "Something went wrong!",
              //       footer: `${error.response.data}`,
              //     });
              //     console.log(error);
              //   });
              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        }
      });
    } else {
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            observaciones,
            editPedido: user.id,
            paqueterias: paqueteria,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Pedido",
              detalle: `${numero_pedido} ${fecha}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Almacen", field: "areaName", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Status", field: "status", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.areaName.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search) ||
          comment.alias.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizadoSurtido) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoSurtido.includes(autorizadoSurtido)
      );
    }
    if (autorizadoApartado) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoApartado.includes(autorizadoApartado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field !== "total_cantidad" &&
      sorting.field !== "total_general" &&
      sorting.field !== "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field === "total_cantidad" ||
        sorting.field === "total_general" ||
        sorting.field === "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field === "total_cantidad" ||
        sorting.field === "total_general" ||
        sorting.field === "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    status,
    selectedFechaInicio,
    selectedFechaFin,
    autorizadoApartado,
    autorizadoSurtido,
    selectedArea,
  ]);

  function EditArticulos(
    idOC,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    surtido,
    areaSurtido,
    clientes,
    idClientes,
    fechaModificado,
    descuento
  ) {
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setSurtido(surtido);
    setIva(iva.toFixed(2));
    setTotalGeneralEdit(total_general);
    setTotalCantidadEdit(total_cantidad);
    setSubTotal(subTotal);
    setAreaSurtido(areaSurtido);
    setNombreClienteEdit(clientes);
    setFechaModificado(fechaModificado);
    setSelectedClienteEdit(idClientes);
    setDescuentoEdit(descuento);
    let dn = (total_general * descuento) / 100;
    setDescuentoNumeroEdit(dn.toFixed(2));

    setArticulosPedido([]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              is_active: a.is_active,
              articulos: a.articulos[0]._id,
              codigo: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              colorNombre: a.colores[0].name,
              idColor: a.colores[0]._id,
              tallaNombre: a.tallas[0].name,
              idTalla: a.tallas[0]._id,
              colores: a.articulos[0].colores,
              tallas: a.articulos[0].tallas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    setAbrirEditar(true);
  }

  const handleChangeInputExistentesPrecio = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.precio = event;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id === event.target.value) {
        let idArticulo = a._id;
        handleChangeInputExistentesArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputExistentesArticulo = (id, idArticulo) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCantidadExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.cantidad = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir =
          parseFloat(event.target.value) - parseFloat(i.surtido);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let KG = articulosPedido.concat(values);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let temp1 = (TG * descuentoEdit) / 100;

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneralEdit(totTemp);
  };

  const handleChangeInputPrecio = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.precio = value;
        let totalParcial = i.cantidad * value;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.forEach((a) => {
      if (a._id === event.target.value) {
        let idArticulo = a._id;
        let precio = a.venta;
        let colores = a.colores;
        let tallas = a.tallas;
        handleChangeInputArticulo(id, idArticulo, precio, colores, tallas);
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    precio,
    colores,
    tallas
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let activos = articulosPedido.filter((e) => e.is_active === "Si");
    let activos2 = activos.concat(inputFields);
    let cantidadActivos = activos2.map((c) => parseFloat(c.cantidad));
    let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TA);

    let totalActivos = activos2.map((c) => parseFloat(c.total));
    let TG = totalActivos.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG.toFixed(2));

    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }

    let temp1 = (TG * descuentoEdit) / 100;

    setDescuentoNumeroEdit(temp1.toFixed(2));

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneralEdit(totTemp.toFixed(2));
    setFechaModificado(endDate);
  }

  function calculaDescuento(e) {
    setDescuentoEdit(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumeroEdit(temp1.toFixed(2));

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete);

    setTotalGeneralEdit(totTemp.toFixed(2));
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: parseFloat(total_general_edit).toFixed(2),
            total_cantidad: total_cantidad_edit,
            editArticulos: user.id,
            comisionTDC,
            comisionFlete,
            subTotal: parseFloat(subTotal).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos: selectedImpuesto,
            editado: "Si",
            // autorizadoApartado: "No",
            // autorizadoSurtido: "No",
            fechaModificado,
            // paqueterias: paqueteria
            descuento: descuentoEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos !== "" && a.cantidad !== 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total).toFixed(2),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    clientes: selectedClienteEdit,
                    colores: a.idColor,
                    tallas: a.idTalla,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido === 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido === 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total).toFixed(2),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  colores: a.idColor,
                  tallas: a.idTalla,
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido === 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function desautorizarApartado(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se liberará el inventario!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, liberar el inventario!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoApartado: "No",
              autorizadoSurtido: "No",
              autorizoApartado: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoApartado: "No",
                  autorizadoSurtido: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Desautorizar Apartado",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se libero el inventario",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoApartado = "No";
                    i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function autorizarApartado(id, almacen) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${id}`;

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir4/${id}/${almacen}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosAutorizar = response.data;

        let cantidadArticulos = allArticulosAutorizar.length;

        allArticulosAutorizar.map((a) => {
          // if (a.cantidadPedido > a.disponible) {
          //   return toggleNoDisponible();
          // } else
          {
            cantidadArticulos = cantidadArticulos - 1;
            if (cantidadArticulos === 0) {
              Swal.fire({
                title: "Estas seguro?",
                text: "Se va a bloquear el inventario!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, bloquear!",
              }).then((result) => {
                if (result.isConfirmed) {
                  axios
                    .patch(
                      URL_AUTORIZADO,
                      {
                        autorizadoApartado: "Si",
                        autorizoApartado: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((response) => {
                      axios
                        .patch(
                          URL_AUTORIZADO_HIJO,
                          {
                            autorizadoApartado: "Si",
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          axios.post(
                            URL_LOGS,
                            {
                              tipo: "Autorizar Apartado",
                              detalle: `${numero_pedido} ${fecha}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          );
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Se Aparto",
                            showConfirmButton: false,
                            timer: 2000,
                          });
                          const newComments = comments.map((i) => {
                            if (id === i._id) {
                              i.autorizadoApartado = "Si";
                            }
                            return i;
                          });
                          setComments(newComments);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              });
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function desautorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se quitara la autorizacion de Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quitar autorizacion!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "No",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Desautorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se quito la autorizacion",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function autorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a autorizar Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "Si",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Autorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Autorizo",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "Si";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e !== 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (e * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password === PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function bajaArticulo(id) {
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${idEdit}`;
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "No";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active === "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto !== 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "No",
              autorizadoApartado: "No",
              autorizadoSurtido: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .patch(
                    URL_AUTORIZADO_HIJO,
                    {
                      autorizadoApartado: "No",
                      autorizadoSurtido: "No",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    const newAP = articulosPedido.map((i) => {
                      if (id === i.id) {
                        i.is_active = "No";
                      }
                      return i;
                    });
                    setArticulosPedido(newAP);

                    const newPed = comments.map((i) => {
                      if (idEdit === i._id) {
                        i.total_cantidad = TA;
                        i.total_general = TGT;
                        i.subTotal = TG;
                        i.iva = IT;
                        // i.autorizadoApartado = "No";
                        // i.autorizadoSurtido = "No";
                      }
                      return i;
                    });
                    setComments(newPed);

                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Se dio de Baja",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function altaArticulo(id) {
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "Si";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active === "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto !== 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((response) => {
                const newAP = articulosPedido.map((i) => {
                  if (id === i.id) {
                    i.is_active = "Si";
                  }
                  return i;
                });
                setArticulosPedido(newAP);

                const newPed = comments.map((i) => {
                  if (idEdit === i._id) {
                    i.total_cantidad = TA;
                    i.total_general = TGT;
                    i.subTotal = TG;
                    i.iva = IT;
                    // i.autorizadoApartado = "No";
                    // i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newPed);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se dio de Alta",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          {!AbrirEditar ? (
            <>
              <Row>
                <Col md={8}>
                  {user.pedidos_create ? (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      href="/PedidosCreate"
                    >
                      Nuevo Pedido
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      disabled
                    >
                      Nuevo Pedido
                    </Button>
                  )}
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pedidos</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pedidos WhatsApp</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>

                <Col md={2}>
                  <Label>Autorizado</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoApartado}
                    required
                    onChange={(e) => {
                      setAutorizadoApartado(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Autorizado Surtir</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoSurtido}
                    required
                    onChange={(e) => {
                      setAutorizadoSurtido(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Status</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={status}
                    required
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Abierto">Abierto</option>
                    <option value="En Proceso">En Proceso</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clientes
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedColaborador}
                      onChange={(e) => {
                        setSelectedColaborador(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (a.esTienda == "No") {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                    </Input>
                  </td>
                </tr>
                <tbody>
                  {commentsData.map((a) => {
                    if (a.activo === "No") {
                      return (
                        <tr style={{ backgroundColor: "#ed8c8c" }}>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td></td>
                          <td></td>
                          <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.colaboradores,
                                  a.idClientes,
                                  a.clientes,
                                  a.total_cantidad,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a._id,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.pais,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.RFC,
                                  a.paqueteria,
                                  a.area,
                                  a.codigoClientes,
                                  a.autorizadoApartado,
                                  a.autorizadoSurtido,
                                  a.telefono,
                                  a.editado,
                                  a.editPedido,
                                  a.autorizoApartado,
                                  a.autorizoSurtido,
                                  a.descuento,
                                  a.creado,
                                  a.fechaHora
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            {/* <Button
                                    size="sm"
                                    className="btn"
                                    color="info"
                                    onClick={(e) =>
                                      PDFOCFoto(
                                        a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.alias,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.area,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido,
                                        a.descuento,
                                        a.creado,
                                        a.fechaHora
                                      )
                                    }
                                  >
                                    <i class="far fa-file-pdf"></i>
                                  </Button> */}
                          </td>
                          <td>CANCELADO</td>
                          <td></td>
                        </tr>
                      );
                    } else if (a.autorizadoSurtido === "Si") {
                      return (
                        <tr style={{ backgroundColor: "#64e379" }}>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.total_cantidad
                            )}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)}
                          </td>
                          <td>{a.status}</td>
                          <td>
                            {user.pedidos_create ? (
                              <div>
                                {a.surtido === "No" &&
                                user.pedidos_autorizar &&
                                a.status === "Abierto" ? (
                                  a.autorizadoApartado === "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarApartado(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-up"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) =>
                                          autorizarApartado(a._id, a.idArea)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-down"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.status === "Abierto" ? (
                                  <>
                                    <Button
                                      size="sm"
                                      className="btn"
                                      color="danger"
                                      onClick={(e) =>
                                        PDFOC(
                                          a.fecha,
                                          a.colaboradores,
                                          a.idClientes,
                                          a.clientes,
                                          a.total_cantidad,
                                          a.total_general,
                                          a.observaciones,
                                          a.numero,
                                          a.impuestos,
                                          a.comisionFlete,
                                          a.comisionTDC,
                                          a._id,
                                          a.alias,
                                          a.calle,
                                          a.numero_ext,
                                          a.numero_int,
                                          a.pais,
                                          a.estado,
                                          a.ciudad,
                                          a.delegacion,
                                          a.colonia,
                                          a.cp,
                                          a.RFC,
                                          a.paqueteria,
                                          a.area,
                                          a.codigoClientes,
                                          a.autorizadoApartado,
                                          a.autorizadoSurtido,
                                          a.telefono,
                                          a.editado,
                                          a.editPedido,
                                          a.autorizoApartado,
                                          a.autorizoSurtido,
                                          a.descuento,
                                          a.creado,
                                          a.fechaHora
                                        )
                                      }
                                    >
                                      <i class="far fa-file-pdf"></i>
                                    </Button>
                                    {/* <Button
                                   size="sm"
                                   className="btn"
                                   color="info"
                                   onClick={(e) =>
                                     PDFOCFoto(
                                       a.fecha,
                                       a.colaboradores,
                                       a.idClientes,
                                       a.clientes,
                                       a.total_cantidad,
                                       a.total_general,
                                       a.observaciones,
                                       a.numero,
                                       a.impuestos,
                                       a.comisionFlete,
                                       a.comisionTDC,
                                       a._id,
                                       a.alias,
                                       a.calle,
                                       a.numero_ext,
                                       a.numero_int,
                                       a.pais,
                                       a.estado,
                                       a.ciudad,
                                       a.delegacion,
                                       a.colonia,
                                       a.cp,
                                       a.RFC,
                                       a.paqueteria,
                                       a.area,
                                       a.codigoClientes,
                                       a.autorizadoApartado,
                                       a.autorizadoSurtido,
                                       a.telefono,
                                       a.editado,
                                       a.editPedido,
                                       a.autorizoApartado,
                                       a.autorizoSurtido,
                                       a.descuento,
                                       a.creado,
                                       a.fechaHora
                                     )
                                   }
                                 >
                                   <i class="far fa-file-pdf"></i>
                                 </Button> */}
                                  </>
                                ) : undefined}

                                {a.surtido === "No" &&
                                user.pedidos_autorizar &&
                                a.status === "Abierto" ? (
                                  a.autorizadoApartado === "Si" ? (
                                    a.autorizadoSurtido === "Si" ? (
                                      <span>
                                        <Button
                                          color="primary"
                                          id="Baja"
                                          onClick={(e) =>
                                            desautorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span>
                                        <Button
                                          color="danger"
                                          onClick={(e) =>
                                            autorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    )
                                  ) : a.autorizadoSurtido === "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarSurtido(a._id)
                                        }
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) => autorizarSurtido(a._id)}
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.surtido === "No" &&
                                user.pedidos_autorizar ? (
                                  <>
                                    <StatusMultiple
                                      idStatus={a._id}
                                      status={a.status}
                                      URL_STATUS={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                    <BajaMultiple
                                      idStatus={a._id}
                                      is_active={a.activo}
                                      URL_BAJA={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                  </>
                                ) : undefined}
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            {a.surtido === "No" ? (
                              a.status === "Abierto" && a.activo === "Si" ? (
                                <div>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a.fecha,
                                        a.idColaboradores,
                                        a.idClientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.idPaqueteria,
                                        a._id,
                                        a.idArea
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a._id,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.subTotal,
                                        a.iva,
                                        a.total_general,
                                        a.total_cantidad,
                                        a.surtido,
                                        a.idArea,
                                        a.clientes,
                                        a.idClientes,
                                        a.fechaModificado,
                                        a.descuento
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>
                                </div>
                              ) : undefined
                            ) : undefined}
                          </td>
                          <td>
                            {user.surtidos_create && user.pedidos_autorizar ? (
                              <>
                                <StatusMultipleSurtido
                                  idStatus={a._id}
                                  status={a.surtido}
                                  URL_STATUS={URL_PEDIDOS}
                                  hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                />
                              </>
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.total_cantidad
                            )}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)}
                          </td>
                          <td>{a.status}</td>
                          <td>
                            {user.pedidos_create ? (
                              <div>
                                {a.surtido === "No" &&
                                user.pedidos_autorizar &&
                                a.status === "Abierto" ? (
                                  a.autorizadoApartado === "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarApartado(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-up"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) =>
                                          autorizarApartado(a._id, a.idArea)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-down"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.status === "Abierto" ? (
                                  <>
                                    <Button
                                      size="sm"
                                      className="btn"
                                      color="danger"
                                      onClick={(e) =>
                                        PDFOC(
                                          a.fecha,
                                          a.colaboradores,
                                          a.idClientes,
                                          a.clientes,
                                          a.total_cantidad,
                                          a.total_general,
                                          a.observaciones,
                                          a.numero,
                                          a.impuestos,
                                          a.comisionFlete,
                                          a.comisionTDC,
                                          a._id,
                                          a.alias,
                                          a.calle,
                                          a.numero_ext,
                                          a.numero_int,
                                          a.pais,
                                          a.estado,
                                          a.ciudad,
                                          a.delegacion,
                                          a.colonia,
                                          a.cp,
                                          a.RFC,
                                          a.paqueteria,
                                          a.area,
                                          a.codigoClientes,
                                          a.autorizadoApartado,
                                          a.autorizadoSurtido,
                                          a.telefono,
                                          a.editado,
                                          a.editPedido,
                                          a.autorizoApartado,
                                          a.autorizoSurtido,
                                          a.descuento,
                                          a.creado,
                                          a.fechaHora
                                        )
                                      }
                                    >
                                      <i class="far fa-file-pdf"></i>
                                    </Button>
                                    {/* <Button
                                    size="sm"
                                    className="btn"
                                    color="info"
                                    onClick={(e) =>
                                      PDFOCFoto(
                                        a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.alias,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.area,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido,
                                        a.descuento,
                                        a.creado,
                                        a.fechaHora
                                      )
                                    }
                                  >
                                    <i class="far fa-file-pdf"></i>
                                  </Button> */}
                                  </>
                                ) : undefined}

                                {a.surtido === "No" &&
                                user.pedidos_autorizar &&
                                a.status === "Abierto" ? (
                                  a.autorizadoApartado === "Si" ? (
                                    a.autorizadoSurtido === "Si" ? (
                                      <span>
                                        <Button
                                          color="primary"
                                          id="Baja"
                                          onClick={(e) =>
                                            desautorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span>
                                        <Button
                                          color="danger"
                                          onClick={(e) =>
                                            autorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    )
                                  ) : a.autorizadoSurtido === "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarSurtido(a._id)
                                        }
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) => autorizarSurtido(a._id)}
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.surtido === "No" &&
                                user.pedidos_autorizar ? (
                                  <>
                                    <StatusMultiple
                                      idStatus={a._id}
                                      status={a.status}
                                      URL_STATUS={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                    <BajaMultiple
                                      idStatus={a._id}
                                      is_active={a.activo}
                                      URL_BAJA={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                  </>
                                ) : undefined}
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            {a.surtido === "No" ? (
                              a.status === "Abierto" && a.activo === "Si" ? (
                                <div>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a.fecha,
                                        a.idColaboradores,
                                        a.idClientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.idPaqueteria,
                                        a._id,
                                        a.idArea
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a._id,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.subTotal,
                                        a.iva,
                                        a.total_general,
                                        a.total_cantidad,
                                        a.surtido,
                                        a.idArea,
                                        a.clientes,
                                        a.idClientes,
                                        a.fechaModificado,
                                        a.descuento
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>
                                </div>
                              ) : undefined
                            ) : undefined}
                          </td>
                          <td>
                            {user.surtidos_create && user.pedidos_autorizar ? (
                              <StatusMultipleSurtido
                                idStatus={a._id}
                                status={a.surtido}
                                URL_STATUS={URL_PEDIDOS}
                                hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                              />
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <div className="card container col-md-12">
              <h4>Editar Pedido {numero_pedido}</h4>
              <h4>Cliente: {nombreClienteEdit}</h4>
              <Form onSubmit={savePedido}>
                <Row>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionTDC}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionTDC(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Flete</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionFlete}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionFlete(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={selectedImpuesto}
                      onChange={(e) => {
                        calculaImpuesto(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Descuento %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={descuentoEdit}
                      onChange={(e) => {
                        calculaDescuento(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>
                    <Label>Sub Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={subTotal}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Descuento $</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={descuentoNumeroEdit}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>IVA</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={iva}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total_general_edit}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Piezas</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Piezas"
                      value={total_cantidad_edit}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Label></Label>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={(e) => toggleCambiaPrecio()}
                    >
                      Cambiar Precio <i class="fas fa-dollar-sign"></i>
                    </Button>
                  </Col>
                </Row>
                <br />
                {/* Agregar mas articulos */}
                <Row className="">
                  <Col md={2}>
                    <Label className="mr-sm-2">Escanea el QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Selecciona</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={2}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputArticulos[0]?.articulos}
                      onChange={(event) => {
                        BuscaArticuloProporcion(inputArticulos[0].id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Autocomplete
                      size="small"
                      value={inputArticulos[0]?.articulosNombre || ""}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticuloProporcion(
                          inputArticulos[0]?.id || "template",
                          selectedArticulo
                        );
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      name="articulosNombre"
                      type="string"
                      placeholder="Articulo"
                      value={inputArticulos[0]?.articulosNombre || ""}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cajas"
                      value={inputArticulos[0]?.cajas || ""}
                      onChange={(event) =>
                        handleChangeInputProporcion(
                          inputArticulos[0]?.id || "template",
                          event
                        )
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputArticulos[0]?.precio || ""}
                      onChange={(event) =>
                        handleChangeInputPrecio(
                          inputArticulos[0]?.id || "template",
                          event
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="success"
                      disabled={
                        !inputArticulos[0]?.articulos ||
                        !inputArticulos[0]?.cajas ||
                        !inputArticulos[0]?.precio
                      }
                      onClick={() =>
                        agregarProporcion(
                          inputArticulos[0]?.articulos,
                          inputArticulos[0]?.articulosNombre,
                          inputArticulos[0]?.precio,
                          inputArticulos[0]?.cajas,
                          inputArticulos[0]?.colores,
                          inputArticulos[0]?.tallas
                        )
                      }
                      tabindex="-1"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
                <h4 className="text-dark">
                  <b>Agregar Pieza</b>
                </h4>
                <Row className="">
                  <Col md={2}>
                    <Label className="mr-sm-2">Escanea el QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Selecciona</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Talla</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <Input
                      name="articulos"
                      type="string"
                      value={manualInput.articulos}
                      onChange={(event) => {
                        buscarArticuloSingular(manualInput.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Autocomplete
                      size="small"
                      value={manualInput?.articulosNombre || ""}
                      onChange={(event, selectedArticulo) => {
                        handleManualArticuloChange(selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      type="text"
                      value={manualInput?.articulosNombre || ""}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      name="idColor"
                      type="select"
                      value={manualInput?.idColor || ""}
                      onChange={handleManualInputChange}
                    >
                      <option value="">Selecciona Color</option>
                      {manualInput?.colores?.map((color) => (
                        <option key={color._id} value={color._id}>
                          {color.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      name="idTalla"
                      type="select"
                      value={manualInput.idTalla || ""}
                      onChange={handleManualInputChange}
                    >
                      <option value="">Selecciona Talla</option>
                      {manualInput.tallas &&
                        manualInput.tallas.map((talla) => (
                          <option key={talla._id} value={talla._id}>
                            {talla.name}
                          </option>
                        ))}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={manualInput?.cantidad || 0}
                      onChange={handleManualInputChange}
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      name="precio"
                      type="number"
                      value={manualInput?.precio || 0}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="success"
                      onClick={handleManualAdd}
                      disabled={
                        !manualInput?.articulosNombre ||
                        !manualInput?.idColor ||
                        !manualInput?.idTalla ||
                        !manualInput?.cantidad
                      }
                      tabindex="-1"
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>

                {Object.entries(
                  [...articulosPedido, ...inputFields] // Combine both arrays
                    .filter(
                      (field) =>
                        (field.codigo && field.articulos) ||
                        (field.articulosNombre && field.articulos)
                    ) // Filter for both types
                    .reduce((acc, field) => {
                      const key = `${field.codigo || field.articulosNombre}`; // Use either codigo or articulosNombre
                      if (!acc[key]) {
                        acc[key] = {
                          articuloInfo: {
                            codigo: field.codigo || field.articulosNombre, // Use either one
                            articulos: field.articulos,
                            availableTallas: Array.from(
                              new Set(
                                [...articulosPedido, ...inputFields] // Search in both arrays
                                  .filter(
                                    (f) =>
                                      f.codigo === field.codigo ||
                                      f.articulosNombre ===
                                        field.articulosNombre
                                  )
                                  .map((f) => f.tallaNombre)
                                  .filter(Boolean)
                                  .sort((a, b) => {
                                    const tallaOrder = [
                                      "EECH",
                                      "ECH",
                                      "CH",
                                      "S",
                                      "M",
                                      "G",
                                      "L",
                                      "EG",
                                      "XL",
                                      "2EG",
                                      "XXL",
                                      "3EG",
                                      "4EG",
                                      "5EG",
                                      "6EG",
                                      "1",
                                      "2",
                                      "3",
                                      "4",
                                      "5",
                                      "6",
                                      "7",
                                      "8",
                                      "9",
                                      "10",
                                      "11",
                                      "12",
                                      "13",
                                      "14",
                                      "15",
                                      "16",
                                      "17",
                                      "18",
                                      "19",
                                      "20",
                                      "21",
                                      "22",
                                      "23",
                                      "24",
                                      "25",
                                      "26",
                                      "27",
                                      "28",
                                      "29",
                                      "30",
                                      "31",
                                      "32",
                                      "33",
                                      "34",
                                      "35",
                                      "36",
                                      "37",
                                      "38",
                                      "39",
                                      "40",
                                      "41",
                                      "42",
                                      "43",
                                      "44",
                                      "45",
                                      "46",
                                      "47",
                                      "48",
                                      "49",
                                      "50",
                                      "3/4",
                                      "5/6",
                                      "7/8",
                                      "9/10",
                                      "11/12",
                                      "10/12",
                                      "13/14",
                                      "14/16",
                                      "15/16",
                                    ];
                                    return (
                                      tallaOrder.indexOf(a) -
                                      tallaOrder.indexOf(b)
                                    );
                                  })
                              )
                            ),
                          },
                          colorGroups: [],
                        };
                      }

                      const existingColorIndex = acc[key].colorGroups.findIndex(
                        (item) => item.idColor === field.idColor
                      );

                      if (existingColorIndex === -1) {
                        acc[key].colorGroups.push({
                          id: field.id,
                          articulos: field.articulos,
                          codigo: field.codigo || field.articulosNombre,
                          idColor: field.idColor,
                          colorNombre: field.colorNombre,
                          colores: field.colores,
                          precio: field.precio,
                          surtido: field.surtido || 0,
                          pendiente_surtir: field.pendiente_surtir || 0,
                          quantities: {
                            [field.tallaNombre]: {
                              cantidad: field.cantidad,
                              id: field.id,
                              surtido: field.surtido || 0,
                              pendiente_surtir: field.pendiente_surtir || 0,
                            },
                          },
                          total: field.cantidad * field.precio,
                        });
                      } else {
                        acc[key].colorGroups[existingColorIndex].quantities[
                          field.tallaNombre
                        ] = {
                          cantidad: field.cantidad,
                          id: field.id,
                          surtido: field.surtido || 0,
                          pendiente_surtir: field.pendiente_surtir || 0,
                        };
                        acc[key].colorGroups[existingColorIndex].total +=
                          field.cantidad * field.precio;
                      }
                      return acc;
                    }, {})
                ).map(([codigo, { articuloInfo, colorGroups }]) => {
                  const articleTotals = colorGroups.reduce(
                    (totals, group) => {
                      const quantitySum = Object.values(
                        group.quantities
                      ).reduce(
                        (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                        0
                      );

                      // Create an object to store surtido by size across all color groups
                      const surtidoBySizeInGroup = Object.entries(
                        group.quantities
                      ).reduce((acc, [talla, data]) => {
                        if (data.surtido && data.surtido > 0) {
                          acc[talla] = (acc[talla] || 0) + data.surtido;
                        }
                        return acc;
                      }, {});

                      // Merge the current group's surtido counts with the total
                      Object.entries(surtidoBySizeInGroup).forEach(
                        ([talla, count]) => {
                          totals.surtidoBySize[talla] =
                            (totals.surtidoBySize[talla] || 0) + count;
                        }
                      );

                      const surtidoSum = Object.values(group.quantities).reduce(
                        (sum, q) => sum + (parseFloat(q.surtido) || 0),
                        0
                      );
                      const pendienteSum = Object.values(
                        group.quantities
                      ).reduce(
                        (sum, q) => sum + (parseFloat(q.pendiente_surtir) || 0),
                        0
                      );
                      const priceSum = Object.values(group.quantities).reduce(
                        (sum, q) =>
                          sum + (parseFloat(q.cantidad) || 0) * group.precio,
                        0
                      );

                      return {
                        items: totals.items + quantitySum,
                        surtido: totals.surtido + surtidoSum,
                        surtidoBySize: totals.surtidoBySize,
                        pendiente: totals.pendiente + pendienteSum,
                        price: totals.price + priceSum,
                        singlePrice: group.precio,
                      };
                    },
                    {
                      items: 0,
                      surtido: 0,
                      surtidoBySize: {},
                      pendiente: 0,
                      price: 0,
                    }
                  );
                  return (
                    <div key={codigo} className="mb-8">
                      <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                        <div className="d-flex align-items-center">
                          <Button
                            size="sm"
                            className="btn mr-3"
                            color="info"
                            onClick={() =>
                              setExpandedTables((prev) => ({
                                ...prev,
                                [codigo]: !prev[codigo],
                              }))
                            }
                          >
                            <i
                              className={`fas fa-chevron-${
                                expandedTables[codigo] ? "up" : "down"
                              }`}
                            ></i>
                          </Button>
                          <h4 className="negrita mb-0 mr-4">{codigo}</h4>
                        </div>
                        {!expandedTables[codigo] && (
                          <div className="d-flex align-items-center">
                            <span
                              className="mr-4 text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              <strong>Piezas:</strong> {articleTotals.items}
                            </span>
                            <span
                              className="mr-4 text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              <strong>Surtido:</strong> {articleTotals.surtido}
                            </span>
                            <span
                              className="mr-4 text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              <strong>Pendiente:</strong>{" "}
                              {articleTotals.pendiente}
                            </span>
                            <span
                              className="mr-4 text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              <strong>Precio:</strong> $
                              {articleTotals.singlePrice.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </span>
                            <span
                              className="text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              <strong>Total:</strong> $
                              {articleTotals.price.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </div>
                        )}
                      </div>

                      {expandedTables[codigo] && (
                        <Table borderless>
                          <thead>
                            <tr>
                              <th>Articulo</th>
                              <th>Color</th>
                              {articuloInfo.availableTallas.map((talla) => (
                                <th key={talla} style={{ maxWidth: "60px" }}>
                                  {talla}
                                </th>
                              ))}
                              <th style={{ maxWidth: "60px" }}>Surtido</th>
                              <th style={{ maxWidth: "60px" }}>Pendiente</th>
                              <th style={{ maxWidth: "60px" }}>Precio</th>
                              <th style={{ maxWidth: "80px" }}>Total</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {colorGroups.map((group) => (
                              <tr key={`${group.id}-${group.idColor}`}>
                                <td>
                                  <Input
                                    name="articulo"
                                    type="string"
                                    value={group.codigo}
                                    disabled
                                  />
                                </td>
                                <td style={{ maxWidth: "50px" }}>
                                  <Input
                                    name="idColor"
                                    type="select"
                                    value={group.idColor}
                                    onChange={(event) =>
                                      handleChangeInputExistentes(
                                        group.id,
                                        event
                                      )
                                    }
                                    disabled={group.surtido > 0}
                                  >
                                    <option value="">Selecciona</option>
                                    {group.colores?.map((a) => (
                                      <option key={a._id} value={a._id}>
                                        {a.name}
                                      </option>
                                    ))}
                                  </Input>
                                </td>
                                {articuloInfo.availableTallas.map((talla) => (
                                  <td key={talla} style={{ maxWidth: "60px" }}>
                                    <Input
                                      name="cantidad"
                                      type="number"
                                      min="0"
                                      value={
                                        group.quantities[talla]?.cantidad || 0
                                      }
                                      onChange={(event) =>
                                        handleChangeInputCantidadExistentes(
                                          group.quantities[talla]?.id,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                ))}
                                <td style={{ maxWidth: "90px" }}>
                                  <Input
                                    name="surtido"
                                    type="text"
                                    value={
                                      Object.entries(group.quantities)
                                        .filter(
                                          ([_, data]) =>
                                            data.surtido && data.surtido > 0
                                        )
                                        .map(
                                          ([talla, data]) =>
                                            `${talla}:${data.surtido}`
                                        )
                                        .join("|") || "0"
                                    }
                                    disabled
                                  />
                                </td>
                                <td style={{ maxWidth: "90px" }}>
                                  <Input
                                    name="pendiente_surtir"
                                    type="text"
                                    value={
                                      Object.entries(group.quantities)
                                        .map(
                                          ([talla, data]) =>
                                            `${talla}:${data.pendiente_surtir}`
                                        )
                                        .join("|") || "0"
                                    }
                                    disabled
                                  />
                                </td>
                                <td style={{ maxWidth: "60px" }}>
                                  <Input
                                    name="precio"
                                    type="number"
                                    value={group.precio}
                                    disabled={
                                      group.surtido > 0 || !user.cambiaPrecios
                                    }
                                  />
                                </td>
                                <td style={{ maxWidth: "80px" }}>
                                  <Input
                                    name="total"
                                    type="number"
                                    value={group.total}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    className="btn"
                                    color="danger"
                                    onClick={() => handleRemoveFields(group.id)}
                                    disabled={group.surtido > 0}
                                    tabindex="-1"
                                  >
                                    <i className="fas fa-minus"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  );
                })}

                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirEditar(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Paqueteria</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              >
                <option value="0">Selecciona </option>
                {paqueterias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={3}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedAreaEdit}
                onChange={(e) => {
                  setSelectedAreaEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.esTienda == "No") {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalCambiaPrecio} toggle={toggleCambiaPrecio}>
        <ModalHeader toggle={toggleCambiaPrecio}>
          <h4>Autorizar Cambio de Precio</h4>
        </ModalHeader>
        <ModalBody>
          <Input
            bsSize="sm"
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <br />
          <Row>
            <Col>
              <Button
                type="submit"
                className="btn btn-success"
                onClick={(e) => autorizaCambio()}
              >
                Autorizar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {/* <Modal size="sm" isOpen={modalNoDisponible} toggle={toggleNoDisponible}>
        <ModalHeader toggle={toggleNoDisponible}>
          <h4>No hay Inventario Disponible</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Disponible</th>
            </tr>

            {allArticulosAutorizar.map((a) => {
              if (a.disponible < a.cantidadPedido) {
                return (
                  <tr style={{ backgroundColor: "#ed8c8c" }}>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              }
            })}
          </Table>
        </ModalBody>
      </Modal> */}

      {loader}
    </>
  );
}

export default ListadoPedidosWhatsapp;
