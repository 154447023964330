import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesInventarios() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoInventarios" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponible" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.seguimiento_inventarios ?(
            <Button href="/ListadoTracking" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Tracking
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Tracking
        </Button> }
          </Col>

          </Row>
          <br />
        <Row>

          <Col md={4} align="center">
          {user.seguimiento_inventarios ?(
            <Button href="/ListadoKardex" className="botonesMenu" color="success">
              <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Kardex
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-clipboard-list fa-7x"></i>
          <br />
          <br />
          Kardex
        </Button> }
          </Col>          

        <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoTraspasosSalida" className="botonesMenu" color="success">
              <i class="fas fa-sign-out-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Salida
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-out-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Salida
        </Button> }
          </Col>
          

          
          <Col md={4} align="center">
          {user.menu_traspasos_entrada ?(
            <Button href="/ListadoTraspasosEntrada" className="botonesMenu" color="success">
              <i class="fas fa-sign-in-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Entrada
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-in-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Entrada
        </Button> }
          </Col>


     
          </Row>
        <br />
        <Row>


          <Col md={4} align="center">
          {user.ajustesInventario ?(
            <Button href="/ListadoAjustesInventario" className="botonesMenu" color="success">
              <i class="fas fa-plus fa-6x"></i>
              <br />
              <br />
              Ajuste Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plus fa-6x"></i>
          <br />
          <br />
          Ajuste Inventario
        </Button> }
          </Col>
          
          
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventarios;
