import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";
import { encode } from "base64-arraybuffer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentasTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_ARTICULOS_VENTAS_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES =process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_ARTICULOS_PROPORCION =process.env.REACT_APP_URL_ARTICULOS_PROPORCION;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  let hora = moment(date).format("HH:mm:ss");

  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [expandedTables, setExpandedTables] = useState({});

  const [paqueterias, setPaqueterias] = useState([]);
  const [idPaqueteria, setIdPaqueteria] = useState("647e2090513ca00014b4edbc");
  const [inventarios, setInventarios] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areaCliente, setAreaCliente] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [fecha, setFecha] = useState(hoy);
  const [condPago, setCondPago] = useState("Contado");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [alertasCliente, setAlertasCliente] = useState("No");
  const [listaPrecios, setListaPrecios] = useState(1);

  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("NA");
  const [fiscal, setFiscal] = useState("No");
  const [saldo, setSaldo] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([]);

  const [manualInput, setManualInput] = useState({
    articulosNombre: "",
    articulos: "",
    idColor: "",
    idTalla: "",
    tallaNombre: "",
    cantInv: 0,
    cantidad: 0,
    colores: [],
    tallas: [],
    total: 0,
    idProporcion: "",
  });

  const [inputArticulos, setInputArticulos] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cajas: 0,
      precio: 0,
      articulosNombre: "",
      colores: [],
      tallas: [],
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  // Crear Clientes
  const [vistaCliente, setVistaCliente] = useState(false);
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [plazo, setPlazo] = useState(0);
  const [regFiscal, setRegFiscal] = useState("NA");
  const [telefono, setTelefono] = useState("1");
  const [email, setEmail] = useState("a@a.a");
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedPaqueteria, setSelectedPaqueteria] = useState("647e2090513ca00014b4edbc");
  const [selectedAreaCliente, setSelectedAreaCliente] = useState("");
  const [selectedVendedorInput, setSelectedVendedorInput] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [observacionesCliente, setObservacionesCliente] = useState("NA");
  const [forma_pago, setFormaPago] = useState("Contado");
  const [descuentoCliente, setDescuentoCliente] = useState(0);

  const [areaUserName, setAreaUserName] = useState("");

  useMemo(() => {
    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      setAreaUserName(user.areaName);

  }, [user, selectedArea, selectedColaborador]);

  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrupos = res.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    ActualizaTotales();
  }, [inputFields, ActualizaTotales]);

  const handleManualArticuloChange = (selectedArticulo) => {
    // Filter inventory for the selected article
    let inventario = inventarios.filter(
      (inv) => inv.idArticulo === selectedArticulo.idArticulo
    );

    let precioArt;
    if (listaPrecios == 1) {
      precioArt = selectedArticulo.lista1;
    } else if (listaPrecios == 2) {
      precioArt = selectedArticulo.lista2;
    } else if (listaPrecios == 3) {
      precioArt = selectedArticulo.lista3;
    } else if (listaPrecios == 4) {
      precioArt = selectedArticulo.lista4;
    }

    // Get unique tallas from inventory
    let tallas = inventario.reduce((acc, curr) => {
      if (!acc.includes(curr.talla)) {
        acc.push(curr.talla);
      }
      return acc;
    }, []);

    // Get unique colores from inventory
    let colores = inventario.reduce((acc, curr) => {
      if (!acc.includes(curr.color)) {
        acc.push(curr.color);
      }
      return acc;
    }, []);

    // Filter selectedArticulo.colores to only include colors present in inventory
    let coloresRed = selectedArticulo.colores.filter((c) =>
      colores.includes(c.name)
    );

    // Filter selectedArticulo.tallas to only include sizes present in inventory
    let tallasRed = selectedArticulo.tallas.filter((t) =>
      tallas.includes(t.name)
    );

    if (selectedArticulo) {
      setManualInput({
        articulos: selectedArticulo.idArticulo,
        articulosNombre: selectedArticulo.nombre,

        colores: coloresRed,
        precio: precioArt,
        idProporcion: "",
        tallas: tallasRed,
        idColor: "",
        idTalla: "",
        cantidad: 0,
      });
    }
  };
  const handleManualInputChange = (event) => {
    const { name, value } = event.target;

    setManualInput((prev) => {
      const updatedInput = {
        ...prev,
        [name]: value,
      };

      // If changing talla, update tallaNombre
      if (name === "idTalla") {
        const selectedTalla = prev.tallas.find((t) => t._id === value);
        updatedInput.tallaNombre = selectedTalla ? selectedTalla.name : "";
      }

      // Calculate total whenever cantidad changes
      if (name === "cantidad") {
        const selectedColor = manualInput.colores.find(
          (c) => c._id === manualInput.idColor
        );
        const selectedTalla = manualInput.tallas.find(
          (t) => t._id === manualInput.idTalla
        );
        updatedInput.cantidad = parseFloat(value);
        const inventario = inventarios.find(
          (inv) =>
            inv.idArticulo === manualInput.articulos &&
            inv.color === selectedColor.name &&
            inv.talla === selectedTalla.name
        );
        updatedInput.idProporcion = inventario.idProporcion;

        if (updatedInput.cantidad > inventario.cantidad) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `La cantidad excede tu inventario de ${inventario.cantidad}`,
            showConfirmButton: false,
          });
        }
      }

      return updatedInput;
    });
  };

  const handleManualAdd = async () => {
    if (
      !manualInput.articulosNombre ||
      !manualInput.idColor ||
      !manualInput.idTalla ||
      !manualInput.cantidad
    ) {
      return;
    }

    const selectedColor = manualInput.colores.find(
      (c) => c._id === manualInput.idColor
    );
    const selectedTalla = manualInput.tallas.find(
      (t) => t._id === manualInput.idTalla
    );

    const inventario = inventarios.find(
      (inv) =>
        inv.idArticulo === manualInput.articulos &&
        inv.color === selectedColor.name &&
        inv.talla === selectedTalla.name
    );


    const cantidadFinal = inventario
      ? Math.min(inventario.cantidad, manualInput.cantidad)
      : manualInput.cantidad;

    if (inventario) {
      const avaible = inputFields.find(
        (i) => i.idArticuloProporcion === inventario.idArticuloProporcion
      );

      if (avaible) {
        // Create new array with updated object
        const updatedFields = inputFields.map((field) => {
          if (field.idArticuloProporcion === inventario.idArticuloProporcion) {
            return {
              ...field,
              cantidad: field.cantidad + manualInput.cantidad,
              total: (field.cantidad + manualInput.cantidad) * field.precio,
            };
          }
          return field;
        });

        setInputFields(updatedFields);
        setTotalCantidad((prevCantidad) => prevCantidad + manualInput.cantidad);
        setManualInput((prev) => ({
          ...prev,
          idColor: "",
          articulos: "",
          articulosNombre: "",
          cantidad: 0,
          idTalla: "",
          tallaNombre: "",
          total: 0,
          idProporcion: "",
        }));

        return null;
      }

      const newField = {
        id: uuidv4(),
        articulos: manualInput.articulos,
        precio: manualInput.precio,
        idInv: inventario._id,
        cantInv: inventario.cantidad,
        articulosNombre: manualInput.articulosNombre,
        idArticuloProporcion: inventario.idArticuloProporcion,

        precioEsp: inventario.precioEsp,
        venta: inventario.venta,
        costoUnitario: inventario.costoUnitario,
        precioOriginal: inventario.venta,
        cantidad: cantidadFinal,
        total: cantidadFinal * manualInput.precio,

        idTalla: manualInput.idTalla,
        tallaNombre: selectedTalla ? selectedTalla.name : "",
        idColor: manualInput.idColor,
        colorNombre: selectedColor ? selectedColor.name : "",
        colores: manualInput.colores,
        tallas: manualInput.tallas,
        cajas: 0,
      };

      setInputFields((prev) => [...prev, newField]);

      // Reset only specific fields after adding
      setManualInput((prev) => ({
        ...prev,
        idColor: "",
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        idTalla: "",
        tallaNombre: "",
        total: 0,
        precio: 0,
        idProporcion: "",
      }));
    }
  };

  async function buscarArticuloSingular(id, event) {
    try {
      inventarios.map((a) => {
        if (a.idArticuloProporcion === event.target.value) {
          let idArticulo = a.idArticulo;
          let articulosNombre = a.nombre;
          let colores = a.colores;
          let tallas = a.tallas;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let idColor = a.idColor;
          let idTalla = a.idTalla;

          let precio;
          if (listaPrecios == 1) {
            precio = a.lista1;
          } else if (listaPrecios == 2) {
            precio = a.lista2;
          } else if (listaPrecios == 3) {
            precio = a.lista3;
          } else if (listaPrecios == 4) {
            precio = a.lista4;
          }

          const avaible = inputFields.find(
            (i) => i.idArticuloProporcion === event.target.value
          );

          if (avaible) {
            // Create new array with updated object
            const updatedFields = inputFields.map((field) => {
              if (field.idArticuloProporcion === event.target.value) {
                return {
                  ...field,
                  cantidad: field.cantidad + 1,
                  total: (field.cantidad + 1) * field.precio,
                };
              }
              return field;
            });

            setInputFields(updatedFields);
            return null;
          }

          // const avaiable = inputFields.find((i) => {
          //   if (i.articulos === idArticulo) {
          //     Swal.fire({
          //       icon: "error",
          //       title: "Oops...",
          //       text: "El artículo ya ha sido agregado",
          //       showConfirmButton: false,
          //     });
          //     return true;
          //   }
          //   return false;
          // });
          // if (avaiable) {
          //   return;
          // }

          const newField = {
            id: uuidv4(),
            articulos: idArticulo,
            precio: precio,
            idInv: idInv,
            cantInv: 1,
            articulosNombre: articulosNombre,

            precioEsp: a.precioEsp,
            venta: a.venta,
            costoUnitario: a.costoUnitario,
            precioOriginal: a.venta,
            cantidad: 1,
            total: precio,

            idTalla: idTalla,
            tallaNombre: a.talla,
            idColor: idColor,
            colorNombre: a.color,
            colores: a.colores,
            tallas: a.tallas,
            cajas: 0,
            idArticuloProporcion: a.idArticuloProporcion,
          };

          setInputFields((prev) => [...prev, newField]);

          // Reset only specific fields after adding
          setManualInput((prev) => ({
            ...prev,
            idColor: "",
            articulos: "",
            articulosNombre: "",
            cantidad: 0,
            idTalla: "",
            tallaNombre: "",
            total: 0,
            precio: 0,
            idProporcion: "",
          }));
        }
        return null;
      });
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Artículo no encontrado",
        text: "No se encontró ningún artículo con el código proporcionado",
      });
    }
  }

  const saveVenta = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    if (saldo >= 0) {
      if (terminal !== "" && destino !== "") {
        let cantidadIF = inputFields.length;
        let contador = 0;
        let contadorCantidad = 0;

        if (cambiarPrecio === false && user.precio_menor === false) {
          inputFields.map(async (a) => {
            if (a.precioOriginal - 51 < a.precio) {
              return (contador = contador + 1);
            }
          });
        } else {
          contador = inputFields.length;
        }

        inputFields.map(async (a) => {
          if (a.cantidad > 0) {
            return (contadorCantidad = contadorCantidad + 1);
          }
        });

        if (contadorCantidad === cantidadIF) {
          if (contador === cantidadIF) {
            let efeGuarda = parseFloat(efectivo) + parseFloat(saldo);

            let prefijo;
            if (fiscal === "Si") {
             prefijo = "F";
            } else {
              prefijo = "N";
            }

            if (condPago === "Contado" && saldo > 0) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "El Pago no coincide con el Total",
              });
              setValidaBoton(true);
            } else {
              let totalPedido = inputFields.length;
              toggleProgreso();

              if (!vistaCliente) {
                try {
                  await axios
                    .post(
                      URL_VENTAS_TIENDAS,
                      {
                        fecha,
                        clientes: value._id,
                        colaboradores: selectedColaborador,
                        total_general: total_general.toFixed(2),
                        observaciones,
                        total_cantidad,
                        paqueterias: idPaqueteria,
                        areas: user.areas,
                        comisionTDC,
                        comisionFlete,
                        subTotal: subTotal.toFixed(2),
                        iva: iva.toFixed(2),
                        impuestos: selectedImpuesto,
                        cond_pago: condPago,
                        fiscal,
                        saldo: saldo.toFixed(2),
                        cortesia: 0,
                        prefijo,
                        consecutivo: 0,
                        entregado: "Si",
                        descuento: 0,
                        areaSurtido: selectedArea,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((data) => {
                      if (condPago === "Contado") {
                        axios.post(
                          URL_ABONOS_TIENDAS,
                          {
                            fecha,
                            total: total_general,
                            areas: user.areas,
                            clientes: value._id,
                            ventasTiendas: data.data._id,
                            efectivo: efeGuarda,
                            tdc,
                            transfer,
                            destino,
                            fechaTransfer,
                            terminal,
                            cortesia: 0,
                            codigoAgrupado,
                            concepto: "Abono",
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        );
                      }
                      inputFields.map((a) => {
                        if (a.articulos !== "" && a.cantidad > 0) {
                          axios
                            .post(
                              URL_ARTICULOS_VENTAS_TIENDAS,
                              {
                                fecha,
                                ventasTiendas: data.data._id,
                                articulos: a.articulos,
                                cantidad: parseFloat(a.cantidad),
                                precio: parseFloat(a.precio),
                                total: parseFloat(a.total),
                                inventarios: a.idInv,
                                entregado: "Si",
                                costoUnitario: a.costoUnitario,
                                colores: a.idColor,
                                tallas: a.idTalla,
                                articulosProporcion: a.idArticuloProporcion,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              totalPedido = totalPedido - 1;
                              if (totalPedido === 0) {
                                if (alertasCliente === "Si") {
                                  PDFOC(
                                    data.data._id,
                                    data.data.consecutivo,
                                    prefijo
                                  );
                                }

                                axios
                                  .post(
                                    URL_LOGS,
                                    {
                                      tipo: "Crear Venta de Tienda",
                                      detalle: `${value._id} ${total_general}`,
                                      user: user.id,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer: ${localStorage.getItem(
                                          "app_token"
                                        )}`,
                                      },
                                    }
                                  )
                                  .then(() => {
                                    Swal.fire({
                                      position: "center",
                                      icon: "success",
                                      title: "Creado con exito",
                                      text: "Por favor no refresques ni cierres la ventana",
                                      showConfirmButton: false,
                                    });
                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 3000);

                                    setModalProgreso(false);
                                  })
                                  .catch((error) => {
                                    Swal.fire({
                                      icon: "error",
                                      title: "Oops...",
                                      text: "Something went wrong!",
                                      footer: `${error.response}`,
                                    });
                                    console.log(error);
                                  });
                              }
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                footer: `${error.response}`,
                              });
                              console.log(error);
                            });
                        } else {
                          totalPedido = totalPedido - 1;
                          if (totalPedido === 0) {
                            if (alertasCliente === "Si") {
                              PDFOC(
                                data.data._id,
                                data.data.consecutivo,
                                prefijo
                              );
                            }

                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Venta de Tienda",
                                  detalle: `${value._id} ${total_general}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire({
                                  position: "center",
                                  icon: "success",
                                  title: "Creado con exito",
                                  text: "Por favor no refresques ni cierres la ventana",
                                  showConfirmButton: false,
                                });
                                setTimeout(() => {
                                  window.location.reload();
                                }, 3000);

                                setModalProgreso(false);
                              })
                              .catch((error) => {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!",
                                  footer: `${error.response}`,
                                });
                                console.log(error);
                              });
                          }
                        }
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response}`,
                      });
                      console.log(error);
                    });
                } catch (error) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response}`,
                  });
                  console.log(error);
                }
              } else {
                if (
                  selectedGrupo !== "" &&
                  selectedDepartamento !== "" &&
                  selectedPaqueteria !== "" &&
                  selectedAreaCliente !== "" &&
                  selectedVendedor !== ""
                ) {
                  try {
                    await axios
                      .post(
                        URL_CLIENTES,
                        {
                          nombre_comercial,
                          razon_social,
                          RFC,
                          calle,
                          numero_ext,
                          numero_int,
                          delegacion,
                          estado,
                          pais,
                          cp,
                          colonia,
                          ciudad,
                          telefono,
                          email,
                          grupos: selectedGrupo,
                          departamentosClientes: selectedDepartamento,
                          vendedor: selectedVendedor,
                          observaciones: observacionesCliente,
                          ultimo_pago: "2000-01-01",
                          forma_pago,
                          areas: selectedAreaCliente,
                          plazo,
                          regFiscal,
                          cargos: 0,
                          abonos: 0,
                          saldo: 0,
                          descuento: descuentoCliente,
                          paqueterias: selectedPaqueteria,
                          alertas: "Si",
                          listaPrecios: 1
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(async (dataCliente) => {
                        //  Crear Venta

                        // let totalPedido = inputFields.length;

                        try {
                          await axios
                            .post(
                              URL_VENTAS_TIENDAS,
                              {
                                fecha,
                                clientes: dataCliente.data._id,
                                colaboradores: selectedVendedor,
                                total_general: total_general.toFixed(2),
                                observaciones,
                                total_cantidad,
                                paqueterias: idPaqueteria,
                                areas: user.areas,
                                comisionTDC,
                                comisionFlete,
                                subTotal: subTotal.toFixed(2),
                                iva: iva.toFixed(2),
                                impuestos: selectedImpuesto,
                                cond_pago: condPago,
                                fiscal,
                                saldo: saldo.toFixed(2),
                                cortesia: 0,
                                prefijo,
                                consecutivo: 0,
                                entregado: "Si",
                                descuento: 0,
                                areaSurtido: selectedArea,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then((data) => {
                              if (condPago === "Contado") {
                                axios.post(
                                  URL_ABONOS_TIENDAS,
                                  {
                                    fecha,
                                    total: total_general,
                                    areas: user.areas,
                                    clientes: dataCliente.data._id,
                                    ventasTiendas: data.data._id,
                                    efectivo: efeGuarda,
                                    tdc,
                                    transfer,
                                    destino,
                                    fechaTransfer,
                                    terminal,
                                    cortesia: 0,
                                    codigoAgrupado,
                                    concepto: "Abono",
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                );
                              }
                              inputFields.map((a) => {
                                if (a.articulos !== "" && a.cantidad > 0) {
                                  axios
                                    .post(
                                      URL_ARTICULOS_VENTAS_TIENDAS,
                                      {
                                        fecha,
                                        ventasTiendas: data.data._id,
                                        articulos: a.articulos,
                                        cantidad: parseFloat(a.cantidad),
                                        precio: parseFloat(a.precio),
                                        total: parseFloat(a.total),
                                        inventarios: a.idInv,
                                        entregado: "Si",
                                        costoUnitario: a.costoUnitario,
                                        colores: a.idColor,
                                        tallas: a.idTalla,
                                        articulosProporcion:
                                          a.idArticuloProporcion,
                                      },
                                      {
                                        headers: {
                                          Authorization: `Bearer: ${localStorage.getItem(
                                            "app_token"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then(() => {
                                      totalPedido = totalPedido - 1;
                                      if (totalPedido === 0) {
                                        if (alertasCliente === "Si") {
                                          PDFOC(
                                            data.data._id,
                                            data.data.consecutivo,
                                            prefijo
                                          );
                                        }

                                        axios
                                          .post(
                                            URL_LOGS,
                                            {
                                              tipo: "Crear Venta de Tienda",
                                              detalle: `${dataCliente.data._id} ${total_general}`,
                                              user: user.id,
                                            },
                                            {
                                              headers: {
                                                Authorization: `Bearer: ${localStorage.getItem(
                                                  "app_token"
                                                )}`,
                                              },
                                            }
                                          )
                                          .then(() => {
                                            Swal.fire({
                                              position: "center",
                                              icon: "success",
                                              title: "Creado con exito",
                                              text: "Por favor no refresques ni cierres la ventana",
                                              showConfirmButton: false,
                                            });
                                            setTimeout(() => {
                                              window.location.reload();
                                            }, 3000);

                                            setModalProgreso(false);
                                          })
                                          .catch((error) => {
                                            Swal.fire({
                                              icon: "error",
                                              title: "Oops...",
                                              text: "Something went wrong!",
                                              footer: `${error.response}`,
                                            });
                                            console.log(error);
                                          });
                                      }
                                    })
                                    .catch((error) => {
                                      Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: "Something went wrong!",
                                        footer: `${error.response}`,
                                      });
                                      console.log(error);
                                    });
                                } else {
                                  totalPedido = totalPedido - 1;
                                  if ((totalPedido = 0)) {
                                    if (alertasCliente === "Si") {
                                      PDFOC(
                                        data.data._id,
                                        data.data.consecutivo,
                                        prefijo
                                      );
                                    }

                                    axios
                                      .post(
                                        URL_LOGS,
                                        {
                                          tipo: "Crear Venta de Tienda",
                                          detalle: `${dataCliente.data._id} ${total_general}`,
                                          user: user.id,
                                        },
                                        {
                                          headers: {
                                            Authorization: `Bearer: ${localStorage.getItem(
                                              "app_token"
                                            )}`,
                                          },
                                        }
                                      )
                                      .then(() => {
                                        Swal.fire({
                                          position: "center",
                                          icon: "success",
                                          title: "Creado con exito",
                                          text: "Por favor no refresques ni cierres la ventana",
                                          showConfirmButton: false,
                                        });
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 3000);

                                        setModalProgreso(false);
                                      })
                                      .catch((error) => {
                                        Swal.fire({
                                          icon: "error",
                                          title: "Oops...",
                                          text: "Something went wrong!",
                                          footer: `${error.response}`,
                                        });
                                        console.log(error);
                                      });
                                  }
                                }
                              });
                            })
                            .catch((error) => {
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                footer: `${error.response}`,
                              });
                              console.log(error);
                            });
                        } catch (error) {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response}`,
                          });
                          console.log(error);
                        }

                        // Fin Crear Venta
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data.message}`,
                        });
                        console.log(error);
                      });
                  } catch (error) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data.message}`,
                    });
                    console.log(error);
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Por favor revisa el formulario, faltan Datos",
                  });
                  setValidaBoton(true);
                }
              }
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "No puedes vender $50 menos del precio original",
              showConfirmButton: false,
            });
            setValidaBoton(true);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Existen Productos sin capturar la cantidad",
            showConfirmButton: false,
          });
          setValidaBoton(true);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes Capturar la Terminal o Destino",
          showConfirmButton: false,
        });
        setValidaBoton(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes pagar mas del Saldo",
        showConfirmButton: false,
      });
      setValidaBoton(true);
    }
  };

  async function PDFOC(idPDFOC, consecutivo, prefijo) {
    let LA = inputFields.length * 1.5;
    let largoTotal = LA + 4 + 13;

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["articulos"];
      groups[val] = groups[val] || {
        articulos: item.articulos,
        cantidad: 0,
        total: 0,
        cajas: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].articulosNombre = item.articulosNombre;
      groups[val].precio = item.precio;
      groups[val].total += item.total;
      groups[val].cajas += item.cajas;
      return groups;
    }, []);

    let dataAgrupado = Object.values(agrupado);

    const data = dataAgrupado
      .sort((a, b) => (a.articulosNombre > b.articulosNombre ? 1 : -1))
      .map((a) => {
        return [
          a.articulosNombre,
          a.cajas,
          new Intl.NumberFormat("en-US").format(a.cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.precio),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total),
        ];
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("p", "cm", [8, largoTotal], true);
    var base64 = await qrcode.toDataURL(idPDFOC); // Corrección: await para qrcode.toDataURL
    var img3 = new Image();
    img3.src = base64;

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

    doc.addImage(img2, "png", 5, 1, 2, 2);

    doc.setFontSize(6).setFont(undefined, "bold");
    doc.text(`${process.env.REACT_APP.NOMBRE_INSTITUCION}`, 0.3, 1);
    doc.text(`Sucursal: ${user.areaName}`, 0.3, 1.3);
    doc.text(`Direccion:${user.areaDireccion}`, 0.3, 1.6);
    doc.text(`Atencian al Cliente: ${user.areaTelefono}`, 0.3, 3);

    doc.text(`Fecha: ${fecha} ${hora}`, 0.3, 2.5);
    doc.text(
      `Nota: ${prefijo}-${consecutivo} / Almacen: ${selectedAreaName}`,
      0.3,
      2.8
    );

    let tel;
    let nom;

    if (!vistaCliente) {
      doc.text(`Cliente: ${value.nombre_comercial}`, 0.3, 3.4);
      tel = value.telefono;
      nom = value.nombre_comercial;
    } else {
      doc.text(`Cliente: ${nombre_comercial}`, 0.3, 3.4);
      tel = telefono;
      nom = nombre_comercial;
    }

    doc.text(`Vendedor: ${selectedColaboradorName}`, 0.3, 3.7);

    doc.autoTable({
      head: [["Articulo", "Cajas", "Cant", "Precio", "Total"]],
      body: dataPDFLimpia,
      styles: {
        fontSize: 8,
      },
      startY: 4.3,
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      foot: [
        [
          "",
          "",
          { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
          {
            content: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(subTotal),
            colSpan: 2,
          },
        ],
        [
          "",
          "",
          { content: `Comision`, colSpan: 2, styles: { halign: "left" } },
          {
            content: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(iva),
            colSpan: 2,
          },
        ],
        [
          "",
          "",
          { content: "Comision TDC", colSpan: 2, styles: { halign: "left" } },
          {
            content: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionTDC),
            colSpan: 2,
          },
        ],
        [
          "",
          "",
          { content: "Flete", colSpan: 2, styles: { halign: "left" } },
          {
            content: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionFlete),
            colSpan: 2,
          },
        ],
        [
          "",
          "",
          { content: "Total", colSpan: 2, styles: { halign: "left" } },
          {
            content: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total_general),
            colSpan: 2,
          },
        ],
        ["Forma de Pago"],
        ["Efectivo", "TDC", "Transfer"],
        [
          `$${new Intl.NumberFormat("en-US").format(efectivo)}`,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(transfer),
        ],
      ],
      showFoot: "lastPage",
      pageBreak: "auto",
      tableWidth: "wrap",
    });

    if (condPago === "Credito") {
      doc.text(
        `Firma: ________________`,
        0.3,
        doc.autoTable.previous.finalY + 0.5
      );
    }

    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");

    var att = doc.output("arraybuffer");

    let params = new URLSearchParams({
      number: tel,
      message: `Hola ${nom}, Gracias por confiar en ${process.env.REACT_APP_NOMBRE_INSTITUCION}, estamos para servirle!
      Cualquier duda estamos a tu órdenes!
      Bendiciones que tenga buena venta!`,
      fileName: `Nota-${prefijo}${consecutivo}.pdf`,
      mimetype: "application/pdf",
    });

    axios
      .post(`${URL_WHATSAPP}SendPDFFront?${params.toString()}`, att, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          "Content-Type": "application/octet-stream",
        },
      })
      .then(() => {
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInv: "",
        cantInv: 0,
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        precioEsp: "Si",
        venta: 0,
        costoUnitario: 0,
        colores: [],
        tallas: [],
        colorNombre: "",
        tallaNombre: "",
        idColor: "",
        idTalla: "",
        cajas: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let totalKG = values.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantInv >= event.target.value) {
          i.cantidad = event.target.value;
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu inventario!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    inventarios.map((a) => {
      if (a.idArticulo === selectedArticulo.idArticulo) {
        let idArticulo = a.idArticulo;
        let precio = a.venta;
        let idInv = a._id;
        let cantInv = a.cantidad;
        let articulosNombre = a.codigo;
        let precioEsp = a.precioEsp;
        let venta = a.venta;
        let costoUnitario = a.costoUnitario;
        let precioOriginal = a.venta;
        let colores = a.colores;
        let tallas = a.tallas;

        setInputArticulos({
          id: uuidv4(),
          articulos: idArticulo,
          articulosNombre: articulosNombre,
          cajas: 0,
          precio: 0,
          colores: colores,
          tallas: tallas,
        });
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    precio,
    idInv,
    cantInv,
    articulosNombre,
    precioEsp,
    venta,
    costoUnitario,
    precioOriginal,
    colores,
    tallas
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        i.idInv = idInv;
        i.cantInv = cantInv;
        i.articulosNombre = articulosNombre;

        i.precioEsp = precioEsp;
        i.venta = venta;
        i.costoUnitario = costoUnitario;
        i.precioOriginal = precioOriginal;
        i.cantidad = 0;
        i.total = 0;

        i.colores = colores;
        i.tallas = tallas;

        i.colorNombre = "";
        i.tallaNombre = "";
        i.idColor = "";
        i.idTalla = "";
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    // handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function calculaImpuesto(e) {
    setSelectedImpuesto(parseFloat(e));
    if (e !== 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(parseFloat(e));
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(e) +
      parseFloat(comisionFlete);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function jalaComisionFlete(e) {
    setComisionFlete(parseFloat(e));
    if (selectedImpuesto !== 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    let totTemp =
      (selectedImpuesto * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(e);

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function autorizaCambio() {
    if (password === randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function jalaCondPago(e) {
    setCondPago(e);
    setEfectivo(0);
    setTDC(0);
    setTransfer(0);
    setSaldo(total_general);
    setFechaTransfer("NA");
    setDestino("NA");
    setTerminal("NA");
  }

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(total_general - e - tdc - transfer);
  }

  function jalaTransfer(e) {
    setTransfer(e);
    if (e > 0) {
      setDestino("");
    } else {
      setDestino("NA");
    }
    setSaldo(total_general - efectivo - tdc - e);
  }

  function jalaTDC(e) {
    setTDC(e);
    if (e > 0) {
      setTerminal("");
    } else {
      setTerminal("NA");
    }
    setSaldo(total_general - efectivo - e - transfer);
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios.post(
      `${URL_WHATSAPP}SendMsg`,
      {
        number: process.env.REACT_APP_TELEFONO_SUPER_ADMIN,
        message: `Hola,
La tienda ${areaUserName} esta solicitando un cambio de precio.
Si lo autorizas proporciona la clave:
${randomCambiaPrecio}`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    );

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: process.env.REACT_APP_EMAIL_SUPER_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${areaUserName} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )

      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const options2 = articulosUnicos.map((option) => {
    const junta = option.codigo;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  function jalaAlmacen(e) {
    setSelectedArea(e);
    setInventarios([]);
    setInputFields([]);
    setTotalCantidad(0);
    setTotalGeneral(0);
    setSubTotal(0);
    setIva(0);
    setEfectivo(0);
    setTDC(0);
    setTransfer(0);
    setFechaTransfer("NA");
    setDestino("NA");
    setTerminal("NA");
    setSaldo(0);

    axios
      .get(`${URL_INVENTARIOS}VentaTienda/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
        setSelectedAreaName(res.data[0].area);
        let unicos = allInventarios.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.idArticulo === value.idArticulo && t.cantidad > 0
            )
        );

        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log("hola", articulosUnicos);

  const handleChangeInputProporcion = (id, event) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        if (event.target.name === "precio") {
          i[event.target.name] = parseFloat(event.target.value);
        } else {
          i[event.target.name] = event.target.value;
        }
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function BuscaArticuloProporcion(id, selectedArticulo) {
    inventarios.map((a) => {
      if (a.idArticulo === selectedArticulo.idArticulo) {
        let idArticulo = selectedArticulo.idArticulo;
        let articulosNombre =
          selectedArticulo.codigo + " " + selectedArticulo.nombre;
        let colores = a.colores;
        let tallas = a.tallas;

        let precio;
        if (listaPrecios == 1) {
          precio = a.lista1;
        } else if (listaPrecios == 2) {
          precio = a.lista2;
        } else if (listaPrecios == 3) {
          precio = a.lista3;
        } else if (listaPrecios == 4) {
          precio = a.lista4;
        }

        handleChangeInputArticuloProporcion(
          id,
          idArticulo,
          precio,
          articulosNombre,
          colores,
          tallas
        );
      }
    });
  }

  function BuscaCodigoProporcion(id, idArticulo) {
    if (idArticulo.length >= 24) {
      inventarios.map((a) => {
        if (a.idArticulo == idArticulo) {
          let articulosNombre = a.codigo + " " + a.nombre;
          let colores = a.colores;
          let tallas = a.tallas;

          let precio;
          if (listaPrecios == 1) {
            precio = a.lista1;
          } else if (listaPrecios == 2) {
            precio = a.lista2;
          } else if (listaPrecios == 3) {
            precio = a.lista3;
          } else if (listaPrecios == 4) {
            precio = a.lista4;
          }

          handleChangeInputArticuloProporcion(
            id,
            idArticulo,
            precio,
            articulosNombre,
            colores,
            tallas
          );
        }
      });
    } else {
      const newInputArticulos = inputArticulos.map((i) => {
        if (id === i.id) {
          i.articulos = idArticulo;
          i.precio = 0;
          i.articulosNombre = "";
          i.cajas = 0;
          i.colores = [];
          i.tallas = [];
        }
        return i;
      });
      setInputArticulos(newInputArticulos);
    }
  }

  const handleChangeInputArticuloProporcion = (
    id,
    idArticulo,
    precio,
    articulosNombre,
    colores,
    tallas
  ) => {
    const newInputArticulos = inputArticulos.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.precio = precio;
        i.articulosNombre = articulosNombre;
        i.cajas = 0;
        i.colores = colores;
        i.tallas = tallas;
      }
      return i;
    });
    setInputArticulos(newInputArticulos);
  };

  function agregarProporcion(
    idArticulo,
    articulosNombre,
    precio,
    cajas,
    colores,
    tallas
  ) {
    if (cajas === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La cantidad debe ser mayor a 0",
      });
      return;
    }
    axios
      .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        const newInputFields = allArticulos
          .map((i) => {
            // Encuentra el inventario correspondiente
            const inventario = inventarios.find(
              (inv) =>
                inv.articulo === i.articulo &&
                inv.color === i.colores[0].name &&
                inv.talla === i.tallas[0].name
            );
            const avaiable = inputFields.find((a) => {
              if (a.articulos === inputArticulos[0].articulos) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "El artículo ya ha sido agregado",
                  showConfirmButton: false,
                });
                return true;
              }
              return false;
            });
            if (avaiable) {
              return null;
            }

            // Calcula la cantidad final basada en la disponibilidad del inventario
            const cantidadFinal = inventario
              ? Math.min(inventario.cantidad, i.cantidad * cajas)
              : i.cantidad * cajas;

            if (inventario) {
              return {
                id: uuidv4(),
                articulos: idArticulo,
                precio: precio,
                idInv: inventario._id,
                cantInv: inventario.cantidad,
                articulosNombre: articulosNombre,

                precioEsp: inventario.precioEsp,
                venta: inventario.venta,
                costoUnitario: inventario.costoUnitario,
                precioOriginal: inventario.venta,
                cantidad: cantidadFinal,
                total: cantidadFinal * precio,

                idTalla: i.tallas[0]._id,
                tallaNombre: i.tallas[0].name,
                idColor: i.colores[0]._id,
                colorNombre: i.colores[0].name,
                colores: colores,
                tallas: tallas,
                cajas: cantidadFinal / inventario.piezasPorCaja,
                idArticuloProporcion: i._id,
              };
            }
            return null;
          })
          .filter((i) => i !== null);

        setInputFields((prevInputFields) => {
          const updatedInputFields = [...prevInputFields, ...newInputFields];

          const tallaOrder = [
            "EECH",
            "ECH",
            "CH",
            "M",
            "G",
            "EG",
            "2EG",
            "3EG",
            "4EG",
            "5EG",
            "6EG",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "5/6",
            "7/8",
            "10/12",
            "14/16",
          ];

          const sortedInputFields = updatedInputFields.sort((a, b) => {
            if (a.articulosNombre < b.articulosNombre) return -1;
            if (a.articulosNombre > b.articulosNombre) return 1;

            if (a.colorNombre < b.colorNombre) return -1;
            if (a.colorNombre > b.colorNombre) return 1;

            return (
              tallaOrder.indexOf(a.tallaNombre) -
              tallaOrder.indexOf(b.tallaNombre)
            );
          });

          // Calculate total quantities and amounts
          const totalCantidad = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.cantidad),
            0
          );
          const TG = sortedInputFields.reduce(
            (acc, curr) => acc + parseFloat(curr.total),
            0
          );

          // Update state with totals
          setTotalCantidad(totalCantidad);
          setSubTotal(TG);

          if (selectedImpuesto !== 0) {
            setIva((selectedImpuesto * TG) / 100);
          } else {
            setIva(0);
          }

          let totTemp =
            (selectedImpuesto * TG) / 100 +
            TG +
            parseFloat(comisionTDC) +
            parseFloat(comisionFlete);

          setTotalGeneral(totTemp);
          setSaldo(totTemp - efectivo - tdc - transfer);

          return sortedInputFields;
        });

        setInputArticulos([
          {
            id: uuidv4(),
            articulos: "",
            cajas: 0,
            precio: 0,
            articulosNombre: "",
            colores: [],
            tallas: [],
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.ventasTiendas ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Venta</h3>
          <Form onSubmit={saveVenta}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen de Entrega</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    jalaAlmacen(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (a.esTienda == "Si"){
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                </Input>
              </Col>
            </Row>
            {selectedArea !== "" ? (
              <>
                <Input
                  type="checkbox"
                  onChange={(event) => {
                    setVistaCliente(event.currentTarget.checked);
                    setAlertasCliente("Si");
                  }}
                  checked={vistaCliente}
                />
                <h4 className="subMenuTitulo">
                  Deseas Dar de alta al Cliente?
                </h4>
              </>
            ) : (
              <>
                <Input type="checkbox" disabled />
                <h4 className="subMenuTitulo">
                  Deseas Dar de alta al Cliente?
                </h4>
              </>
            )}

            {vistaCliente ? (
              <>
                <Row>
                  <Col md={3}>
                    <Label>Nombre Comercial</Label>
                    <Input
                      type="text"
                      style={{ backgroundColor: "yellow" }}
                      placeholder="Nombre Comercial"
                      value={nombre_comercial}
                      required
                      onChange={(e) => {
                        setNombreComercial(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Razon Social</Label>
                    <Input
                      type="text"
                      placeholder="Razon Social"
                      value={razon_social}
                      required
                      onChange={(e) => {
                        setRazonSocial(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>RFC</Label>
                    <Input
                      type="text"
                      placeholder="RFC"
                      value={RFC}
                      required
                      onChange={(e) => {
                        setRFC(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Paqueteria</Label>
                    <Input
                      className="col-sm-12"
                      style={{ backgroundColor: "yellow" }}
                      type="select"
                      value={selectedPaqueteria}
                      required
                      onChange={(e) => {
                        setSelectedPaqueteria(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {paqueterias
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Label>Calle</Label>
                    <Input
                      type="text"
                      placeholder="Calle"
                      value={calle}
                      required
                      onChange={(e) => {
                        setCalle(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>No. Ext</Label>
                    <Input
                      type="text"
                      placeholder="No. Ext"
                      value={numero_ext}
                      required
                      onChange={(e) => {
                        setNumeroExt(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>No. Int</Label>
                    <Input
                      type="text"
                      placeholder="No. Int"
                      value={numero_int}
                      required
                      onChange={(e) => {
                        setNumeroInt(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Pais</Label>
                    <Input
                      type="text"
                      placeholder="Pais"
                      value={pais}
                      required
                      onChange={(e) => {
                        setPais(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>Estado</Label>
                    <Input
                      type="text"
                      placeholder="Estado"
                      value={estado}
                      required
                      onChange={(e) => {
                        setEstado(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Ciudad</Label>
                    <Input
                      type="text"
                      placeholder="Ciudad"
                      value={ciudad}
                      required
                      onChange={(e) => {
                        setCiudad(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Delegacion</Label>
                    <Input
                      type="text"
                      placeholder="Delegacion o Municipio"
                      value={delegacion}
                      required
                      onChange={(e) => {
                        setDelegacion(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Colonia</Label>
                    <Input
                      type="text"
                      placeholder="Colonia"
                      value={colonia}
                      required
                      onChange={(e) => {
                        setColonia(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label>CP</Label>
                    <Input
                      type="text"
                      placeholder="CP"
                      value={cp}
                      required
                      onChange={(e) => {
                        setCP(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Telefono</Label>
                    <Input
                      type="text"
                      style={{ backgroundColor: "yellow" }}
                      placeholder="Telefono"
                      value={telefono}
                      required
                      onChange={(e) => {
                        setTelefono(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Vendedor</Label>
                    <Input
                      style={{ backgroundColor: "yellow" }}
                      className="col-sm-12"
                      type="select"
                      value={selectedVendedorInput}
                      required
                      onChange={(e) => {
                        setSelectedVendedorInput(e.target.value);
                        setSelectedVendedor(e.target.value.split(",")[0]);
                        setSelectedColaboradorName(
                          e.target.value.split(",")[1]
                        );
                      }}
                    >
                      <option value="0">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option
                              value={[a._id, a.nombre + " " + a.apellido]}
                            >
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Label>Forma de Pago</Label>
                    <Input
                      type="select"
                      placeholder="Forma de Pago"
                      value={forma_pago}
                      required
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="0">Forma de Pago</option>
                      <option value="Contado">Contado</option>
                      <option value="Credito">Credito</option>
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label>Plazo</Label>
                    <Input
                      type="select"
                      placeholder="Plazo"
                      value={plazo}
                      required
                      onChange={(e) => {
                        setPlazo(e.target.value);
                      }}
                    >
                      <option value="">Plazo</option>
                      <option value="0">0</option>
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="90">90</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                      <option value="180">180</option>
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label>Reg Fiscal</Label>
                    <Input
                      style={{ backgroundColor: "yellow" }}
                      type="select"
                      placeholder="RegFiscal"
                      value={regFiscal}
                      required
                      onChange={(e) => {
                        setRegFiscal(e.target.value);
                      }}
                    >
                      <option value="">Reg Fiscal</option>
                      <option value="0">0</option>
                      <option value="601">
                        601 General de Ley Personas Morales
                      </option>
                      <option value="603">
                        603 Personas Morales con Fines no Lucrativos
                      </option>
                      <option value="605">
                        605 Sueldos y Salarios e Ingresos Asimilados al Salario
                      </option>
                      <option value="608">608 Demas Ingresos</option>
                      <option value="612">
                        612 Personas Fisicas con Actividad Empresarial
                      </option>
                      <option value="616">616 Sin Obligacion Fiscal</option>
                      <option value="621">621 Incorporacion Fiscal</option>
                      <option value="625">
                        625 Reg. de las act. emp. cpn ingresos a traves de plat.
                        tec.
                      </option>
                      <option value="626">
                        626 Regimen simplificado de Confianza
                      </option>
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Grupo</Label>
                    <Input
                      className="col-sm-12"
                      style={{ backgroundColor: "yellow" }}
                      type="select"
                      value={selectedGrupo}
                      required
                      onChange={(e) => {
                        setSelectedGrupo(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona una Grupo</option>
                      {grupos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Departamento</Label>
                    <Input
                      className="col-sm-12"
                      style={{ backgroundColor: "yellow" }}
                      type="select"
                      value={selectedDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedDepartamento(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {departamentos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label className="mr-sm-2">Almacen</Label>
                    <Input
                      className="col-sm-12"
                      style={{ backgroundColor: "yellow" }}
                      type="select"
                      value={selectedAreaCliente}
                      required
                      onChange={(e) => {
                        setSelectedAreaCliente(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (a.esTienda == "Si"){
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observacionesCliente}
                      required
                      onChange={(e) => {
                        setObservacionesCliente(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Descuento</Label>
                    <Input
                      type="number"
                      placeholder="Descuento"
                      value={descuentoCliente}
                      required
                      onChange={(e) => {
                        setDescuentoCliente(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
              </>
            ) : undefined}

            <Row>
              <>
                {!vistaCliente ? (
                  <>
                    {selectedArea !== "" ? (
                      <>
                        <Col md={3}>
                          <Label className="mr-sm-2">Cliente</Label>
                          <Autocomplete
                            size="small"
                            value={value}
                            onChange={(event, selectedCliente) => {
                              if (selectedCliente) {
                                setValue(selectedCliente);
                                setAlertasCliente(selectedCliente.alertas);
                                setSelectedColaborador(
                                  selectedCliente.vendedor[0]._id
                                );
                                setSelectedColaboradorName(
                                  selectedCliente.vendedor[0].nombre +
                                    " " +
                                    selectedCliente.vendedor[0].apellido
                                );
                                setAreaCliente(selectedCliente.areas[0]._id);
                                setListaPrecios(selectedCliente.listaPrecios);
                              }
                            }}
                            options={options.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.junta}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Selecciona"
                                variant="outlined"
                              />
                            )}
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.codigo} {option.nombre_comercial}
                              </React.Fragment>
                            )}
                          />
                        </Col>
                        <Col md={1}>
                          <Label>Almacen</Label>
                          <Input
                            type="select"
                            value={areaCliente}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {areas.map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                          </Input>
                        </Col>

                        <Col md={1}>
                          <Label>Paqueteria</Label>
                          <Input
                            className="col-sm-12"
                            type="select"
                            value={idPaqueteria}
                            required
                            onChange={(e) => {
                              setIdPaqueteria(e.target.value);
                            }}
                          >
                            <option value="0">Selecciona </option>
                            {paqueterias
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={3}>
                          <Label className="mr-sm-2">Cliente</Label>
                          <Autocomplete
                            size="small"
                            value={value}
                            onChange={(event, selectedCliente) => {
                              if (selectedCliente) {
                                setValue(selectedCliente);
                                setSelectedColaborador(
                                  selectedCliente.vendedor[0]._id
                                );
                                setSelectedColaboradorName(
                                  selectedCliente.vendedor[0].nombre +
                                    " " +
                                    selectedCliente.vendedor[0].apellido
                                );
                                setAreaCliente(selectedCliente.areas[0]._id);
                              }
                            }}
                            disabled
                            options={options.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.junta}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Selecciona"
                                variant="outlined"
                              />
                            )}
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.codigo} {option.nombre_comercial}
                              </React.Fragment>
                            )}
                          />
                        </Col>
                        <Col md={1}>
                          <Label>Almacen</Label>
                          <Input
                            type="select"
                            value={areaCliente}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {areas.map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                          </Input>
                        </Col>

                        <Col md={1}>
                          <Label>Paqueteria</Label>
                          <Input
                            className="col-sm-12"
                            type="select"
                            value={idPaqueteria}
                            required
                            disabled
                          >
                            <option value="0">Selecciona </option>
                            {paqueterias
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.name}</option>;
                              })}
                          </Input>
                        </Col>
                      </>
                    )}
                  </>
                ) : undefined}
                {vistaCliente ? (
                  <>
                    <Col md={12}>
                      <h4>Venta</h4>
                    </Col>
                    <br />
                  </>
                ) : undefined}

                <Col md={1}>
                  <Label>Cond de Pago</Label>
                  <Input
                    type="select"
                    value={condPago}
                    required
                    onChange={(e) => {
                      jalaCondPago(e.target.value);
                    }}
                  >
                    <option value={"Contado"}>Contado</option>
                    <option value={"Credito"}>Credito</option>
                  </Input>
                </Col>
                <Col md={1}>
                  <Label>Comision TDC</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Comision TDC"
                    value={comisionTDC}
                    required
                    onChange={(e) => {
                      jalaComisionTDC(e.target.value);
                    }}
                  />
                </Col>

                <Col md={1}>
                  <Label>Flete</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Flete"
                    value={comisionFlete}
                    required
                    onChange={(e) => {
                      jalaComisionFlete(e.target.value);
                    }}
                  />
                </Col>

                <Col md={1}>
                  <Label>Impuestos %</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={selectedImpuesto}
                    onChange={(e) => {
                      calculaImpuesto(e.target.value);
                    }}
                  />
                </Col>
              </>
            </Row>
            <Row>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Label>Cambio de Precio</Label>
                <br />
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => toggleCambiaPrecio()}
                >
                  Solicitar
                </Button>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
            </Row>
            <h4 className="text-dark">
              <b>Agregar Caja</b>
            </h4>
            <>
              {/* QR Scanner Input */}
              <Row className=""></Row>

              {/* Proportion Input Section */}
              <Row className="">
                <Col md={2}>
                  <Label className="mr-sm-2">Escanea el QR</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Selecciona</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cajas</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col md={2}>
                  <Input
                    name="articulos"
                    type="string"
                    value={inputArticulos[0]?.articulos}
                    onChange={(event) => {
                      BuscaCodigoProporcion(
                        inputArticulos[0].id,
                        event.target.value
                      );
                    }}
                  ></Input>
                </Col>
                <Col md={2}>
                  <Autocomplete
                    size="small"
                    value={inputArticulos[0]?.articulosNombre || ""}
                    onChange={(event, selectedArticulo) => {
                      BuscaArticuloProporcion(
                        inputArticulos[0]?.id || "template",
                        selectedArticulo
                      );
                    }}
                    options={options2.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    name="articulosNombre"
                    type="string"
                    placeholder="Articulo"
                    value={inputArticulos[0].articulosNombre || ""}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="cajas"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cajas"
                    value={inputArticulos[0].cajas || ""}
                    onChange={(event) =>
                      handleChangeInputProporcion(
                        inputArticulos[0]?.id || "template",
                        event
                      )
                    }
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="precio"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Precio"
                    value={inputArticulos[0]?.precio || ""}
                    onChange={(event) =>
                      handleChangeInputProporcion(
                        inputArticulos[0]?.id || "template",
                        event
                      )
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="success"
                    disabled={
                      !inputArticulos[0]?.articulos || !inputArticulos[0]?.cajas
                    }
                    onClick={() =>
                      agregarProporcion(
                        inputArticulos[0]?.articulos,
                        inputArticulos[0]?.articulosNombre,
                        inputArticulos[0]?.precio,
                        inputArticulos[0]?.cajas,
                        inputArticulos[0]?.colores,
                        inputArticulos[0]?.tallas
                      )
                    }
                    tabindex="-1"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>

              {/* MANUAL PRODUCT SECTION */}
              <h4 className="text-dark">
                <b>Agregar Pieza</b>
              </h4>
              <Row className="">
                <Col md={2}>
                  <Label className="mr-sm-2">Escanea el QR</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Selecciona</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Talla</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <Input
                    name="idProporcion"
                    type="string"
                    value={manualInput.idProporcion}
                    onChange={(event) => {
                      buscarArticuloSingular(manualInput.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={2}>
                  <Autocomplete
                    size="small"
                    value={manualInput?.articulosNombre || ""}
                    onChange={(event, selectedArticulo) => {
                      handleManualArticuloChange(selectedArticulo);
                    }}
                    options={options2.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    type="text"
                    value={manualInput?.articulosNombre || ""}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="idColor"
                    type="select"
                    value={manualInput?.idColor || ""}
                    onChange={handleManualInputChange}
                  >
                    <option value="">Selecciona Color</option>
                    {manualInput?.colores?.map((color) => (
                      <option key={color._id} value={color._id}>
                        {color.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    name="idTalla"
                    type="select"
                    value={manualInput.idTalla || ""}
                    onChange={handleManualInputChange}
                  >
                    <option value="">Selecciona Talla</option>
                    {manualInput.tallas &&
                      manualInput.tallas.map((talla) => (
                        <option key={talla._id} value={talla._id}>
                          {talla.name}
                        </option>
                      ))}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={manualInput?.cantidad || 0}
                    onChange={handleManualInputChange}
                  />
                </Col>
                <Col md={1}>
                  <Input
                    name="precio"
                    type="number"
                    value={manualInput?.precio || 0}
                    onChange={handleManualInputChange}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="success"
                    onClick={handleManualAdd}
                    disabled={
                      !manualInput?.articulosNombre ||
                      !manualInput?.idColor ||
                      !manualInput?.idTalla ||
                      !manualInput?.cantidad
                    }
                    tabindex="-1"
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            </>

            <br />
            <hr />

            <div>
              {Object.entries(
                inputFields
                  .filter((field) => field.articulosNombre && field.articulos)
                  .reduce((acc, field) => {
                    const key = `${field.articulosNombre}`;
                    if (!acc[key]) {
                      acc[key] = {
                        articuloInfo: {
                          articulosNombre: field.articulosNombre,
                          articulos: field.articulos,
                          availableTallas: Array.from(
                            new Set(
                              inputFields
                                .filter(
                                  (f) =>
                                    f.articulosNombre === field.articulosNombre
                                )
                                .map((f) => f.tallaNombre)
                                .filter(Boolean)
                                .sort((a, b) => {
                                  const tallaOrder = [
                                    "EECH",
                                    "ECH",
                                    "CH",
                                    "S",
                                    "M",
                                    "G",
                                    "L",
                                    "EG",
                                    "XL",
                                    "2EG",
                                    "XXL",
                                    "3EG",
                                    "4EG",
                                    "5EG",
                                    "6EG",
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7",
                                    "8",
                                    "9",
                                    "10",
                                    "11",
                                    "12",
                                    "13",
                                    "14",
                                    "15",
                                    "16",
                                    "17",
                                    "18",
                                    "19",
                                    "20",
                                    "21",
                                    "22",
                                    "23",
                                    "24",
                                    "25",
                                    "26",
                                    "27",
                                    "28",
                                    "29",
                                    "30",
                                    "31",
                                    "32",
                                    "33",
                                    "34",
                                    "35",
                                    "36",
                                    "37",
                                    "38",
                                    "39",
                                    "40",
                                    "41",
                                    "42",
                                    "43",
                                    "44",
                                    "45",
                                    "46",
                                    "47",
                                    "48",
                                    "49",
                                    "50",
                                    "3/4",
                                    "5/6",
                                    "7/8",
                                    "9/10",
                                    "11/12",
                                    "10/12",
                                    "13/14",
                                    "14/16",
                                    "15/16",
                                  ];
                                  return (
                                    tallaOrder.indexOf(a) -
                                    tallaOrder.indexOf(b)
                                  );
                                })
                            )
                          ),
                        },
                        colorGroups: [],
                      };
                    }

                    const existingColorIndex = acc[key].colorGroups.findIndex(
                      (item) => item.idColor === field.idColor
                    );

                    if (existingColorIndex === -1) {
                      acc[key].colorGroups.push({
                        id: field.id,
                        articulos: field.articulos,
                        articulosNombre: field.articulosNombre,
                        idColor: field.idColor,
                        colorNombre: field.colorNombre,
                        colores: field.colores,
                        precioOriginal: field.precioOriginal,
                        precio: field.precio,
                        quantities: {
                          [field.tallaNombre]: {
                            cantidad: field.cantidad,
                            id: field.id,
                            cantInv: field.cantInv,
                          },
                        },
                        total: field.cantidad * field.precio,
                      });
                    } else {
                      acc[key].colorGroups[existingColorIndex].quantities[
                        field.tallaNombre
                      ] = {
                        cantidad: field.cantidad,
                        id: field.id,
                        cantInv: field.cantInv,
                      };
                      acc[key].colorGroups[existingColorIndex].total +=
                        field.cantidad * field.precio;
                    }
                    return acc;
                  }, {})
              ).map(([articuloNombre, { articuloInfo, colorGroups }]) => {
                // Calculate totals for this article
                const articleTotals = colorGroups.reduce(
                  (totals, group) => {
                    const quantitySum = Object.values(group.quantities).reduce(
                      (sum, q) => sum + (parseFloat(q.cantidad) || 0),
                      0
                    );
                    const priceSum = Object.values(group.quantities).reduce(
                      (sum, q) =>
                        sum + (parseFloat(q.cantidad) || 0) * group.precio,
                      0
                    );

                    return {
                      items: totals.items + quantitySum,
                      price: totals.price + priceSum,
                      singlePrice: group.precio,
                    };
                  },
                  { items: 0, price: 0 }
                );

                return (
                  <div key={articuloNombre} className="mb-8">
                    <div className="d-flex align-items-center border-t-2 border-gray-200 pt-2 pb-2 bg-gray-50">
                      <div className="d-flex align-items-center">
                        <Button
                          size="sm"
                          className="btn mr-3"
                          color="info"
                          onClick={() =>
                            setExpandedTables((prev) => ({
                              ...prev,
                              [articuloNombre]: !prev[articuloNombre],
                            }))
                          }
                        >
                          <i
                            className={`fas fa-chevron-${
                              expandedTables[articuloNombre] ? "up" : "down"
                            }`}
                          ></i>
                        </Button>
                        <h4 className="negrita mb-0 mr-4">{articuloNombre}</h4>
                      </div>
                      {!expandedTables[articuloNombre] && (
                        <div className="d-flex align-items-center">
                          <span
                            className="mr-4 text-dark"
                            style={{ fontSize: "18px" }}
                          >
                            <strong style={{ fontSize: "18px" }}>
                              Piezas:
                            </strong>{" "}
                            {articleTotals.items}
                          </span>
                          <span
                            className="mr-4 text-dark"
                            style={{ fontSize: "18px" }}
                          >
                            <strong style={{ fontSize: "18px" }}>
                              Precio:
                            </strong>{" "}
                            $
                            {articleTotals.singlePrice.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                          <span
                            className="text-dark"
                            style={{ fontSize: "18px" }}
                          >
                            <strong style={{ fontSize: "18px" }}>Total:</strong>{" "}
                            $
                            {articleTotals.price.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                      )}
                    </div>

                    {expandedTables[articuloNombre] && (
                      <Table borderless>
                        <thead>
                          <tr>
                            <th>Articulo</th>
                            <th>Color</th>
                            {articuloInfo.availableTallas.map((talla) => (
                              <th key={talla} style={{ maxWidth: "35px" }}>
                                {talla}
                              </th>
                            ))}
                            <th style={{ maxWidth: "60px" }}>Disponible</th>
                            <th style={{ maxWidth: "60px" }}>
                              Precio Original
                            </th>
                            <th style={{ maxWidth: "60px" }}>Precio</th>
                            <th style={{ maxWidth: "60px" }}>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {colorGroups.map((group) => (
                            <tr key={`${group.id}-${group.idColor}`}>
                              {/* Your existing row content */}
                              <td style={{ maxWidth: "60px" }}>
                                <Input
                                  name="articulo"
                                  type="string"
                                  placeholder="Articulo"
                                  value={group.articulosNombre}
                                  disabled
                                />
                              </td>
                              <td style={{ maxWidth: "35px" }}>
                                <Input
                                  name="idColor"
                                  type="select"
                                  value={group.idColor}
                                  disabled
                                >
                                  <option value="">Selecciona</option>
                                  {group.colores?.map((a) => (
                                    <option key={a._id} value={a._id}>
                                      {a.name}
                                    </option>
                                  ))}
                                </Input>
                              </td>
                              {articuloInfo.availableTallas.map((talla) => (
                                <td key={talla} style={{ maxWidth: "40px" }}>
                                  <Input
                                    name="cantidad"
                                    type="number"
                                    min="0"
                                    step="any"
                                    value={
                                      group.quantities[talla]?.cantidad || 0
                                    }
                                    onChange={(event) =>
                                      handleChangeInputCantidad(
                                        group.quantities[talla]?.id || group.id,
                                        event
                                      )
                                    }
                                  />
                                </td>
                              ))}
                              <td style={{ maxWidth: "80px" }}>
                                <Input
                                  name="pendiente_surtir"
                                  type="text"
                                  value={
                                    Object.entries(group.quantities)
                                      .map(
                                        ([talla, data]) =>
                                          `${talla}:${data.cantInv}`
                                      )
                                      .join("|") || "0"
                                  }
                                  disabled
                                />
                              </td>
                              <td style={{ maxWidth: "60px" }}>
                                <Input
                                  name="precioOriginal"
                                  type="number"
                                  min="0"
                                  step="any"
                                  placeholder="PrecioOriginal"
                                  value={group.precioOriginal}
                                  disabled
                                />
                              </td>
                              <td style={{ maxWidth: "60px" }}>
                                <Input
                                  style={
                                    group.precioOriginal - 50 > group.precio
                                      ? { backgroundColor: "#ed8c8c" }
                                      : null
                                  }
                                  name="precio"
                                  type="number"
                                  min="0"
                                  step="any"
                                  placeholder="Precio"
                                  value={group.precio}
                                  required
                                  onChange={(event) =>
                                    handleChangeInput(group.id, event)
                                  }
                                />
                              </td>
                              <td style={{ maxWidth: "80px" }}>
                                <Input
                                  name="total"
                                  type="number"
                                  min="0"
                                  step="any"
                                  placeholder="total"
                                  value={group.total}
                                  disabled
                                />
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  disabled={inputFields.length === 1}
                                  onClick={() => handleRemoveFields(group.id)}
                                  tabindex="-1"
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                );
              })}
            </div>

            <br />
            <Row>
              <Col md={6}></Col>
              <Col md={1}>
                <h4 align="right">Total Piezas:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US").format(total_cantidad)}
                </h4>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <h4 align="right">Subtotal:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(subTotal)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={9}></Col>
              <Col md={1}>
                <h4 align="right">IVA:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(iva)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={9}></Col>
              <Col md={1}>
                <h4 align="right">Total:</h4>
              </Col>
              <Col md={1}>
                <h4 align="right">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(total_general)}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col md={3}></Col>
              {condPago === "Contado" ? (
                <Col md={1}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    onChange={(e) => {
                      jalaEfectivo(e.target.value);
                    }}
                  />
                </Col>
              ) : (
                <Col md={1}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    disabled
                  />
                </Col>
              )}

              {condPago === "Contado" ? (
                <>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      required
                      onChange={(e) => {
                        jalaTDC(e.target.value);
                      }}
                    />
                  </Col>

                  {tdc > 0 ? (
                    <Col md={1}>
                      <Label>Terminal</Label>
                      <Input
                        type="select"
                        value={terminal}
                        required
                        onChange={(e) => {
                          setTerminal(e.target.value);
                        }}
                      >
                        <option value="NA">Selecciona</option>
                        <option value="BBVA">BBVA</option>
                      </Input>
                    </Col>
                  ) : (
                    <Col md={1}>
                      <Label>Terminal</Label>
                      <Input type="text" value={terminal} disabled />
                    </Col>
                  )}
                </>
              ) : (
                <>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Terminal</Label>
                    <Input type="text" value={terminal} disabled />
                  </Col>
                </>
              )}

              {condPago === "Contado" ? (
                <>
                  <Col md={1}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      onChange={(e) => {
                        jalaTransfer(e.target.value);
                      }}
                    />
                  </Col>
                  {transfer > 0 ? (
                    <>
                      <Col md={1}>
                        <Label>Destino</Label>
                        <Input
                          type="select"
                          value={destino}
                          required
                          onChange={(e) => {
                            setDestino(e.target.value);
                          }}
                        >
                          <option value="NA">Selecciona</option>
                          <option value="BBVA">BBVA</option>
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Label>Fecha Transfer</Label>
                        <Input
                          type="date"
                          value={fechaTransfer}
                          required
                          onChange={(e) => {
                            setFechaTransfer(e.target.value);
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={1}>
                        <Label>Destino</Label>
                        <Input type="text" value={destino} disabled />
                      </Col>
                      <Col md={1}>
                        <Label>Fecha Transfer</Label>
                        <Input
                          type="date"
                          value={fechaTransfer}
                          required
                          disabled
                        />
                      </Col>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Col md={1}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Destino</Label>
                    <Input type="text" value={destino} disabled />
                  </Col>
                </>
              )}

              <Col md={1}>
                <Label>Saldo</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  value={saldo}
                  required
                  disabled
                />
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/MenuTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado === false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default VentasTiendasCreate;
