import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function AbonosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;

  let date = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let hoy = moment(date).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [cortesia, setCortesia] = useState(0);
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransef] = useState(0);
  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [cargos, setCargos] = useState([]);
  const [total, setTotal] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());
  const [concepto, setConcepto] = useState("Abono");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [areaUserName, setAreaUserName] = useState("");
  const [areaCliente, setAreaCliente] = useState("");
  const [alertasCliente, setAlertasCliente] = useState("No");

  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalBonificacion, setModalBonificacion] = useState(false);
  const toggleBonificacion = () => setModalBonificacion(!modalBonificacion);
  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [autBonificacion, setAutBonificacion] = useState(false);
  const [password, setPassword] = useState("");
  const [randomBonificacion, setRandomBonificacion] = useState((Math.random() + 1).toString(36).substring(2));

  useMemo(() => {

    axios
    .get(`${URL_AREAS}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAreas = response.data;
      setAreas(allAreas);
    })
    .catch((err) => {
      console.log(err);
    });

    if(user.areas){
    if(user.areas != AREAS_GENERAL){
    axios
      .get(`${URL_CLIENTES}ActivosArea/${user.areas}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
   }else{
    axios
    .get(`${URL_CLIENTES}Activos`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClientes = response.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });
   }}

  if(selectedCliente){
    axios
      .get(`${URL_VENTAS_TIENDAS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        setSelectedArea(allCargos[0].areas[0]._id)
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldoInicial: a.saldo,
              saldo: a.saldo,
              comTDC:0,
              nota: a.prefijo + a.consecutivo,
              efectivo: 0,
              tdc: 0,
              terminal: "NA",
              transfer: 0,
              fechaTransfer: "NA",
              destino: "NA",
              cortesia: 0,
              totalAbono: 0,
              areas: a.areas[0]._id,
              clientes: a.clientes[0]._id,
              areasName: a.areas[0].name,
              movimiento: "Nota",
              // user: a.user[0].nombre + " " + a.user[0].apellido,
              clientesNombre: a.clientes[0].nombre_comercial,
              numero: a.prefijo + a.consecutivo,
              idPDFOC: a._id,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              descuento:a.descuento,
              descuentoNumero: (parseFloat(a.subTotal) * parseFloat(a.descuento))/100
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setCargos(dataFinal);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_NOTAS_CARGO}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        setSelectedArea(allCargos[0].clientes[0].areas[0]._id)
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldoInicial: a.saldo,
              saldo: a.saldo,
              comTDC:0,
              nota: a.idNotaCargo,
              efectivo: 0,
              tdc: 0,
              terminal: "NA",
              transfer: 0,
              fechaTransfer: "NA",
              destino: "NA",
              cortesia: 0,
              totalAbono: 0,
              areas: a.clientes[0].areas[0]._id,
              clientes: a.clientes[0]._id,
              areasName: a.clientes[0].areas[0].name,
              movimiento: "Nota de Cargo",
              comisionTDC: a.comisionTDC,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFieldsNC(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    setAreaUserName(user.areaName);

  }, [selectedCliente, user]);


  const handleChangeInputEfectivo = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.tdc) +
          parseFloat(i.transfer) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputComTDC = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i.comTDC = parseFloat(event.target.value)
          i.saldo = i.saldoInicial + parseFloat(event.target.value)
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputComTDCNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
          i.comTDC = parseFloat(event.target.value)
          i.saldo = i.saldoInicial + parseFloat(event.target.value)
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  const handleChangeInputTDC = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.transfer) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputTransfer = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.tdc) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCortesia = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.tdc) +
          parseFloat(i.transfer);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };
 
  // NC
  const handleChangeInputNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
  };

  const handleChangeInputEfectivoNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.tdc) +
          parseFloat(i.transfer) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  const handleChangeInputTDCNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.transfer) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  const handleChangeInputTransferNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.tdc) +
          parseFloat(i.cortesia);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  const handleChangeInputCortesiaNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        let abonoTemp =
          parseFloat(event.target.value) +
          parseFloat(i.efectivo) +
          parseFloat(i.tdc) +
          parseFloat(i.transfer);
        if (abonoTemp <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
          i.totalAbono = abonoTemp;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };
  // Termina NC

  function ActualizaTotales() {
    let TEfe = inputFields.map((c) => parseFloat(c.efectivo));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);
    let TEfeNC = inputFieldsNC.map((c) => parseFloat(c.efectivo));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);
    setEfectivo(TE + TENC);

    let TTDC = inputFields.map((c) => parseFloat(c.tdc));
    let TD = TTDC.reduce((t, total, index) => t + total, 0);
    let TTDCNC = inputFieldsNC.map((c) => parseFloat(c.tdc));
    let TDNC = TTDCNC.reduce((t, total, index) => t + total, 0);
    setTDC(TD + TDNC);

    let TTra = inputFields.map((c) => parseFloat(c.transfer));
    let TT = TTra.reduce((t, total, index) => t + total, 0);
    let TTraNC = inputFieldsNC.map((c) => parseFloat(c.transfer));
    let TTNC = TTraNC.reduce((t, total, index) => t + total, 0);
    setTransef(TTNC);

    let TCor = inputFields.map((c) => parseFloat(c.cortesia));
    let TC = TCor.reduce((t, total, index) => t + total, 0);
    let TCorNC = inputFieldsNC.map((c) => parseFloat(c.cortesia));
    let TCNC = TCorNC.reduce((t, total, index) => t + total, 0);
    setCortesia(TC + TCNC);

    setTotal(TE + TENC + TD + TDNC + TT + TTNC + TC + TCNC);
  }

  function autorizaCambio() {
    if (password == randomBonificacion) {
      setAutBonificacion(true);
      setConcepto("Bonificacion");
      toggleBonificacion();
      Swal.fire("Good job!", "Creado con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }



  async function PDFOC() {
   
      await axios
      .get(`${URL_ESTADO_CUENTA}Agrupado/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let movimientos = response.data;

        let movsInicial
        let movsFinal
        let TC = 0;
        if (movimientos.length > 10) {
          movsInicial = movimientos.slice(0, movimientos.length - 10);
          movsFinal = movimientos.slice(-10);
          TC = movsInicial.reduce((total, movimiento) => total + (movimiento.cargo - movimiento.abono), 0);

        } else {
          movsInicial = [];
          movsFinal = movimientos.slice();
        }
 

        let saldoParcial = TC;
        let totalCargosEdoCte = 0;
        let totalAbonosEdoCte = 0;
        let totalSaldoEdoCte = 0;
    
        let LA = (movimientos.length - movsInicial.length) * 1.5;
        let largoTotal = LA + 4 + 5;
    
        const data = movsFinal.map((a) => {
            totalCargosEdoCte = totalCargosEdoCte + a.cargo;
            totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
            totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdoCte;
            saldoParcial = saldoParcial + a.cargo - a.abono;
            const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
            const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
            var saldoParcialFormato =
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
            return [
              a.fecha,
              a.movimiento,
              a.numero,
              cargos,
              abonos,
              saldoParcialFormato,
            ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("p", "cm", [8, largoTotal], true);
        // var img = new Image();
        // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        // doc.addImage(img, "png", 150, 5, 33, 10);
        
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        
    
        doc.setFontSize(8).setFont(undefined, 'bold')
        doc.text(`Estado de Cuenta`, 0.3, 1);
        doc.text(`Cliente ${selectedClienteNombre}`, 0.3, 1.3);
        doc.text(`Se muestran los movimientos de los ultimos 30 dias`, 0.3, 1.6);
     
        const columnTitles = [
          [
            "Fecha", "Mov", "Num", "Cargo", "Abono", "Saldo"
          ],
        ];
    
        const additionalRow = [
          { content: `Saldo Anterior ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TC)}`, colSpan: 6, styles: { halign: "center" } },
        ];

        doc.autoTable({
          head: columnTitles,
          startY: 2,
          // headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0], fontSize: 12 },
                    font:"times",
          styles: {
            fontSize: 6,
            fontStyle:"bold"
          },
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
        });
    
        doc.autoTable({
          head: [additionalRow],
          startY: doc.autoTable.previous.finalY + .1,
          body: [],
          headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 7 },
                    font:"times",
          styles: {
            fontSize: 6,
            fontStyle:"bold"
          },
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
        });

        doc.autoTable({
          body: dataPDFLimpia,
          startY: doc.autoTable.previous.finalY + .1,
          font:"times",
          styles: {
            fontSize: 6,
            fontStyle:"bold"
          },
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
          foot: [
            [
              
              {
                content: "Saldo Anterior",
                colSpan: 4,
                styles: { halign: "left" },
              },
              { content:new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TC), colSpan: 2, styles: { halign: "left" } },,
            ],
            [
              
              {
                content: "Cargos del Periodo",
                colSpan: 4,
                styles: { halign: "left" },
              },
              { content:new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte), colSpan: 2, styles: { halign: "left" } },,
            ],
            [
              
              {
                content: "Abonos del Periodo",
                colSpan: 4,
                styles: { halign: "left" },
              },
              { content:new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte), colSpan: 2, styles: { halign: "left" } },,
            ],
            [
              
              { content: "Saldo Actual", colSpan: 4, styles: { halign: "left" } },
              { content:new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial), colSpan: 2, styles: { halign: "left" } },,
            ],
          ],
          showFoot: "lastPage",
        });
    
        doc.addImage(img2, "png", 5, doc.autoTable.previous.finalY + .5, 2, 2);
        // doc.save(`EdoCtaCliente-${clientesNombre}.pdf`);
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");



      // Wasap
      const docW = new jsPDF({compressPdf: true});
      var imgW = new Image();
      imgW.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      docW.addImage(imgW, "png", 150, 5, 33, 10);
      var img2W = new Image();
      img2W.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      docW.addImage(img2W, "png", 155, 15, 25, 25);
      docW.text(`Estado de Cuenta`, 15, 30);
      docW.text(`Cliente ${selectedClienteNombre}`, 15, 37);
      docW.setFontSize(12)
      docW.text(`Se muestran los movimientos de los ultimos 30 dias`, 15, 44);
   
      const columnTitlesW = [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ];
  
      const additionalRowW = [
        { content: `Saldo Anterior ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TC)}`, colSpan: 6, styles: { halign: "center" } },
      ];
  
      docW.autoTable({
        head: columnTitlesW,
        startY: 48,
        // headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0], fontSize: 12 },
      });
  
      docW.autoTable({
        head: [additionalRowW],
        body: [],
        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 14 },
      });
     
      docW.autoTable({
        body: dataPDFLimpia,
        foot: [
          [
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TC),
          ],
          [
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      
      var attW = docW.output("arraybuffer");
    
      let params = new URLSearchParams({
        number: selectedClienteTelefono,
        message:`Hola ${selectedClienteNombre}, Aquí te enviamos tu estado de cuenta actualizado`,
        fileName:`EstadoCuenta-${selectedClienteNombre}.pdf`,
        mimetype: "application/pdf",
      })
     axios
      .post(
        `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
        attW,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
            "Content-Type": "application/octet-stream",
          },
        }
      )


      })
      .catch((err) => {
        console.log(err);
      });
  }

  function savePago() {
    setValidaBoton(false);
    if(concepto == "Bonificacion" && autBonificacion == false){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes solicitar autorizacion para realizar una Bonificación!",
        showConfirmButton: false,
      })
      return
    }
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el pago",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = inputFields.length + inputFieldsNC.length;
        inputFields.map((a) => {
          if (a.efectivo > 0 || a.tdc > 0 || a.transfer > 0 || a.cortesia > 0) {
            axios
              .post(
                URL_ABONOS_TIENDAS,
                {
                  fecha,
                  total: a.totalAbono,
                  areas: selectedArea,
                  clientes: a.clientes,
                  ventasTiendas: a.id,
                  efectivo: a.efectivo,
                  tdc: a.tdc,
                  transfer: a.transfer,
                  fechaTransfer: a.fechaTransfer,
                  cortesia: a.cortesia,
                  nomina:0,
                  destino: a.destino,
                  terminal: a.terminal,
                  codigoAgrupado,
                  comisionTDC: a.comTDC,
                  concepto,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
               
                axios.patch(
                  `${URL_VENTAS_TIENDAS}/${a.id}`,
                  {
                    saldo: a.saldo - a.totalAbono,
                    comisionTDC: a.comisionTDC + a.comTDC,
                    total_general: a.total_general + a.comTDC
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  if(alertasCliente == "Si"){
                    PDFOC()
                    }
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalMails = totalMails - 1;
            if (totalMails == 0) {
              if(alertasCliente == "Si"){
                PDFOC()
                }
              Swal.fire("Good job!", "Creado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          }
        });

        // NC
        inputFieldsNC.map((a) => {
          if (a.efectivo > 0 || a.tdc > 0 || a.transfer > 0 || a.cortesia > 0) {
            axios
              .post(
                URL_ABONOS_TIENDAS,
                {
                  fecha,
                  total: a.totalAbono,
                  areas: a.areas,
                  clientes: a.clientes,
                  notasCargo: a.id,
                  efectivo: a.efectivo,
                  tdc: a.tdc,
                  transfer: a.transfer,
                  fechaTransfer: a.fechaTransfer,
                  cortesia: a.cortesia,
                  nomina:0, 
                  destino: a.destino,
                  terminal: a.terminal,
                  codigoAgrupado,
                  comisionTDC: a.comTDC,
                  concepto,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
                axios.patch(
                  `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                  {
                    saldo: a.saldo - a.totalAbono,
                    comisionTDC: a.comisionTDC + a.comTDC,
                    importe: a.total_general + a.comTDC
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  if(alertasCliente == "Si"){
                    PDFOC()
                    }
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalMails = totalMails - 1;
            if (totalMails == 0) {
              if(alertasCliente == "Si"){
                PDFOC()
                }
              Swal.fire("Good job!", "Creado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          }
        });
      }
    });
  }



  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
    .post(
      `${URL_WHATSAPP}SendMsg`,
      {
        number: process.env.REACT_APP_TELEFONO_SUPER_ADMIN,
        message:`Hola,
La tienda ${areaUserName} esta solicitando una bonificaicon.
Si lo autorizas proporciona la clave:
${randomBonificacion}`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    )

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Bonificaicon Tienda",
          email: process.env.REACT_APP_EMAIL_SUPER_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${areaUserName} esta solicitando una bonificacion, si lo autorizas proporciona la clave ${randomBonificacion}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )


      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setInputFields([])
                        setInputFieldsNC([])
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setAlertasCliente(selectedCliente.alertas);
                        setSelectedClienteNombre(
                          selectedCliente.nombre_comercial
                        );
                        setSelectedClienteTelefono(selectedCliente.telefono);
                        setAreaCliente(selectedCliente.areas[0]._id);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={1}>
                      <Label>Almacen</Label>
                      <Input
                        type="select"
                        value={areaCliente}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {areas
                          .map((a) => {
                            return (
                              <option
                                value={a._id}
                              >
                                {a.name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={2}>
                <Label>Bonificacion</Label>
                <br />
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => toggleBonificacion()}
                >
                  Solicitar
                </Button>
              </Col>
               <Col md={2}>
                {user.areas == AREAS_GENERAL?(
                  <>
                  <Label>Almacen</Label>
                <Input
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                    </>
                  ):undefined}
                </Col> 
                <Col md={2}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Movimiento</th>
                      <th>Nota</th>
                      <th>Importe</th>
                      <th>Abonos</th>
                      <th>Saldo</th>
                      {/* <th>Com TDC</th> */}
                      <th>Efectivo</th>
                      <th>TDC</th>
                      <th>Terminal</th>
                      <th>Transfer</th>
                      <th>Destino</th>
                      <th>Fecha Transfer</th>
                      {/* <th>Cortesia</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general - a.saldoInicial
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        {/* <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="comTDC"
                            value={a.comTDC}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputComTDC(a.id, e);
                            }}
                          />
                        </td> */}
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="efectivo"
                            value={a.efectivo}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputEfectivo(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="tdc"
                            value={a.tdc}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputTDC(a.id, e);
                            }}
                          />
                        </td>

                        <td>
                          {a.tdc > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="select"
                            name="terminal"
                            value={a.terminal}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                            >
                              <option value="NA">Selecciona</option>
                              <option value="BBVA">BBVA</option>
                            </Input>
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="terminal"
                              value={a.terminal}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td> 
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="transfer"
                            value={a.transfer}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputTransfer(a.id, e);
                            }}
                          />
                        </td>

                        <td>
                          {a.transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="select"
                            name="destino"
                            value={a.destino}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                            >
                              <option value="NA">Selecciona</option>
                              <option value="BBVA">BBVA</option>
                            </Input>
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="destino"
                              value={a.destino}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td>
                        <td>
                        {a.transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="date"
                            name="fechaTransfer"
                            value={a.fechaTransfer}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="fechaTransfer"
                              value={a.fechaTransfer}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td>

                      </tr>
                      // </div>
                    ))}

                    {/* NC */}

                    {inputFieldsNC.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general - a.saldoInicial
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        {/* <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="comTDC"
                            value={a.comTDC}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputComTDCNC(a.id, e);
                            }}
                          />
                        </td> */}
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="efectivo"
                            value={a.efectivo}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputEfectivoNC(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="tdc"
                            value={a.tdc}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputTDCNC(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          {a.tdc > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="select"
                            name="terminal"
                            value={a.terminal}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                            >
                            <option value="NA">Selecciona</option>
                            <option value="BBVA">BBVA</option> </Input>
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="terminal"
                              value={a.terminal}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="transfer"
                            value={a.transfer}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputTransferNC(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          {a.transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="select"
                            name="destino"
                            value={a.destino}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                            >
                            <option value="NA">Selecciona</option>
                            <option value="BBVA">BBVA</option>
                             </Input>
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="destino"
                              value={a.destino}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td>

                        <td>
                        {a.transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="date"
                            name="fechaTransfer"
                            value={a.fechaTransfer}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="fechaTransfer"
                              value={a.fechaTransfer}
                              key={a.id}
                              disabled
                            />
                          )}
                        </td>
                      </tr>
                      // </div>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(efectivo)}
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(tdc)}
                      </td>
                      <td></td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(transfer)}
                      </td>
                      {/* <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cortesia)}
                      </td> */}
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              {validaBoton ? (
              <Button className="btn btn-success" onClick={savePago}>
                Registrar
              </Button>
              ):(
              <Button disabled className="btn btn-success" >
              Registrar
            </Button>
                )}

              <Button
                href="/ListadoAbonosTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalBonificacion}
            toggle={toggleBonificacion}
          >
            <ModalHeader toggle={toggleBonificacion}>
              <h4>Autorizar Bonificacion</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosTiendasCreate;
