import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { encode } from "base64-arraybuffer";

function RegistrarEmpresas() {
  const { user } = useContext(AuthContext);
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_TIMBRAR = process.env.REACT_APP_URL_TIMBRAR;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [rfc, setRfc] = useState("");
  const [fileCertificado, setFileCertificado] = useState();
  const [filePrivateKey, setFilePrivateKey] = useState();
  const [password, setPassword] = useState();
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveEmpresas = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        `${URL_TIMBRAR}RegistrarEmpresa`,
        {
          rfc,
          certificado: fileCertificado,
          privateKey: filePrivateKey,
          privateKeyPassword: password,
          empresa: selectedEmpresa,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Registrar Empresa",
            detalle: rfc,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setValidaBoton(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  function BuscaEmpresa(event) {
    setSelectedEmpresa(event.target.value);

    empresas.map((a) => {
      if (a._id == event.target.value) {
        setRfc(a.rfc);
      }
    });
  }

  async function jalaFileCertificado(e) {
    const file = e;
  
    const reader = new FileReader();
  
    reader.onload = function (event) {
      const arrayBuffer = event.target.result;
  
      const base64File = encode(arrayBuffer);
  
      setFileCertificado(base64File);
    };

    reader.readAsArrayBuffer(file);
  }

  async function jalaFilePrivateKey(e) {
    const file = e;
  
    const reader = new FileReader();
  
    reader.onload = function (event) {
      const arrayBuffer = event.target.result;
  
      const base64File = encode(arrayBuffer);
  
      setFilePrivateKey(base64File);
    };

    reader.readAsArrayBuffer(file);
  }


  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_fiscal ? (
          <div className="row">
            {
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Registrar FIEL</h3>
                        <Row>
                          <Col md={2}>
                            <Label>Empresa</Label>
                            <Input
                              name="empresa"
                              type="select"
                              value={selectedEmpresa}
                              required
                              onChange={(event) => {
                                BuscaEmpresa(event);
                              }}
                            >
                              <option value="">Selecciona</option>
                              {empresas
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((a) => {
                                    return (
                                      <option value={a._id}>{a.name}</option>
                                    );
                                })}
                            </Input>
                          </Col>
                          <Col md={2}>
                            <Label className="mr-sm-2">RFC</Label>
                            <Input
                              type="text"
                              placeholder="RFC"
                              value={rfc}
                              required
                              disabled
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                        <Col md={2}>
                            <Label className="mr-sm-2">Password</Label>
                            <Input
                              type="text"
                              placeholder="Password"
                              value={password}
                              required
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                        <Col>
                          <Label className="mr-sm-2">Certificado</Label>
                          <Input
                            type="file"
                            accept=".cer"
                            onChange={(e) => {
                              jalaFileCertificado(e.target.files[0]);
                            }}
                          />
                          </Col>
                        </Row>
                        <Row>
                        <Col>
                          <Label className="mr-sm-2">Key</Label>
                          <Input
                            type="file"
                            accept=".key"
                            onChange={(e) => {
                              jalaFilePrivateKey(e.target.files[0]);
                            }}
                          />
                          </Col>
                        </Row>
                      <br />
                      <Row className="container">
                        {validaBoton ? (
                          <Button type="submit" className="btn btn-success" onClick={saveEmpresas}>
                            Guardar
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            className="btn btn-success"
                            disabled
                          >
                            Guardar
                          </Button>
                        )}
                        <div>
                          <SweetAlert
                            show={text}
                            title="Creado con Exito"
                            onConfirm={() => {
                              setText(false);
                            }}
                          />
                        </div>
                        <Button color="danger" href="/MenuFiscal">
                          Regresar
                        </Button>
                      </Row>
                  </div>
                </div>
              </div>
            }
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default RegistrarEmpresas;
