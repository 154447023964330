import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_ventas ?(
      <div className="container">
        <br />
        <br />
          <Row>
        <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/ListadoPedidos" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Pedidos
        </Button> }
          </Col>

          
          <Col md={4} align="center">
          {user.surtidos_create ?(
            <Button href="/ListadoPendienteSurtir" className="botonesMenu" color="success">
              <i class="fas fa-truck-moving fa-7x"></i>
              <br />
              <br />
              Surtir
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-truck-moving fa-7x"></i>
          <br />
          <br />
          Surtir
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_devoluciones ?(
            <Button href="/ListadoDevoluciones" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>

      
          </Row>
          <br />
          <Row>

          
          <Col md={4} align="center">
          {user.menu_surtidos ?(
            <Button href="/ListadoVentas" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Ventas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Ventas
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/AnalisisArticulosPedidos" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Analisis Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Analisis Pedidos
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/ListoSurtir" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Listo Surtir
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Listo Surtir
        </Button> }
          </Col>

          
          </Row>
          <br />
          <Row>
            
          <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/ListoSurtirCliente" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Listo Surtir Cliente
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Listo Surtir Cliente
        </Button> }
          </Col>

          
                      <Col md={4} align="center">
                      {user.menu_reportes ?(
                        <Button
                          href="/ReporteUltimoPrecio"
                          className="botonesMenu"
                          color="success"
                        >
                          <i class="fas fa-dollar-sign fa-7x"></i>
                          <br />
                          <br />
                          Ultimo Precio Cliente
                        </Button>
                      ): <Button
                      disabled
                      className="botonesMenu"
                      color="success"
                    >
                      <i class="fas fa-dollar-sign fa-7x"></i>
                      <br />
                      <br />
                      Ultimo Precio Cliente
                    </Button> }
                      </Col>
          
                      <Col md={4} align="center">
                      {user.menu_reportes ?(
                        <Button
                          href="/ReporteUltimoPrecioArticulo"
                          className="botonesMenu"
                          color="success"
                        >
                          <i class="fas fa-tshirt fa-7x"></i>
                          <br />
                          <br />
                          Ultimo Precio Articulo
                        </Button>
                      ): <Button
                      disabled
                      className="botonesMenu"
                      color="success"
                    >
                      <i class="fas fa-tshirt fa-7x"></i>
                      <br />
                      <br />
                      Ultimo Precio Articulo
                    </Button> }
                      </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
